import React, {useEffect, useState, useRef} from "react";
import Select from 'react-select';
import Card from '../../../common/Card';
import Plot from 'react-plotly.js';
import { backUrl } from "../../../App/constants";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';



const PlotCard = (props) => {
  var options = [];
  var defaultOption = {label: '', value: '' };
  var defaultX = [];
  var defaultY = [];

  const [autoRangeOption, setAutoRangeOption] = useState('auto');
  const [selectedOption, setSelectedOption] = useState({ value: '14149eb9-0127-458a-8f75-a6c5acd84b52', label: 'Argon Flow Rate (m³/hr)' });
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [yLabel, setYLabel] = useState('Argon Flow Rate (m³/hr)');
  const [data, setData] = useState([]);
  const [title, setTitle] = useState('Argon Flow Rate');
  const [chartType, setChartType] = useState({ value: 'line', label: 'Line'} );

  const plotRef = useRef(null); // Create a ref to store the Plot component instance

  const chartTypes = [
    { value: 'scatter', label: 'Scatter' },
    { value: 'bar', label: 'Bar' },
    { value: 'line', label: 'Line' },
    { value: 'area', label: 'Area' },
    { value: 'heatmap', label: 'Heatmap' },
    { value: 'pie', label: 'Pie' },
    { value: 'scattergeo', label: 'Scatter Geo' },
    { value: 'bubble', label: 'Bubble' },
    { value: 'box', label: 'Box' },
    { value: 'histogram', label: 'Histogram' },
    { value: 'scatter3d', label: '3D Scatter' },
    // Add more chart types as needed
  ];

  const handleChartChange = (selected) => {
    setChartType(selected);

    const newData = [...data]; // Create a copy of the state

    const updatedData = data.map((obj) => ({ ...obj, type: selected.value }));
    
    setData(updatedData); // Update state with the modified copy
  }


  const handleChange = (selected) => {
    setSelectedOption(selected);
    setYLabel('');


    if (selected.length<1) { 
      // Update state with empty data
      var empty = {
          x: [],
          y: [],
          type: chartType,
          /*mode: 'lines',*/
        }

      const newData=[empty]
      setData(newData);
      setTitle('');
    } else {
      const newData = selected.map((option, index) => ({
        x: seriesData[option.value].x,
        y: seriesData[option.value].y,
        type: chartType.value,
        /*mode: 'lines+markers',*/
	name: seriesData[option.value].name
      }));
      // Update state with the new data
      setData(newData);

      //Update y-axis label
      const label = selected.reduce((acc, option) => {
        const seriesUnits = seriesData[option.value].ylabel;
        return acc ? `${acc}, ${seriesUnits}` : seriesUnits;
      }, '');
      setYLabel(label);

      // Update chart title
      const title = selected.reduce((acc, option) => {
        const seriesName = seriesData[option.value].name;
	      console.log(seriesData[option.value].name);
        return acc ? `${acc}, ${seriesName}` : seriesName;
      }, '');
      setTitle(title);



    }

  };


  const saveChart = () => {


    // Access the latest layout from the Plot component using the ref
    const latestLayout = plotRef.current.props.layout;
    const latestData = plotRef.current.props.data;

    const serializedData = JSON.stringify(latestData);
    const serializedLayout = JSON.stringify(latestLayout);

    const currentDate = new Date();

    // Format the date and time as a string
    const formattedDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;

    const source = { type: 'renishaw', build_id: props.buildId, created_at: formattedDate  } 
    const serializedSource = JSON.stringify(source);

    // Combine data and layout into a single object to send to the backend
    const payload = {
      data: serializedData,
      layout: serializedLayout,
      source: serializedSource
    };

    // Assuming you have a backend endpoint to receive the data
    fetch(`${backUrl}charts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response from the backend if needed
        console.log('Save successful:', data);
        toast.success('Your chart has been saved to the Data Explorer');
      })
      .catch(error => {
        console.error('Error saving graph:', error);
        toast.error('Something went wrong');
      });

  }





/*
  const handleChange = (selected, what) => {
    let cur = selected;
    console.log("SELECTED");
    console.log(selected);
    console.log(cur);
    if (what=='data') { 
      setSelectedOption(selected);
    } else {
      setChartType(selected);
      cur = selectedOption;
    } 

    if (!Array.isArray(cur)) { cur = [cur]; } 


    if (cur.length<1) { 
      // Update state with empty data
      var empty = {
          x: [],
          y: [],
          type: chartType,
          mode: 'lines',
        }

      const newData=[empty]
      setData(newData);
      setTitle('');
    } else {
      console.log("CUR");
      console.log(cur);
      const newData = cur.map((option, index) => ({
        x: seriesData[option.value].x,
        y: seriesData[option.value].y,
        type: chartType,
        mode: 'lines+markers',
	name: seriesData[option.value].name
      }));
      // Update state with the new data
      setData(newData);

      // Update chart title
      const title = cur.reduce((acc, option) => {
        const seriesName = seriesData[option.value].name;
        return acc ? `${acc}, ${seriesName}` : seriesName;
      }, '');
      setTitle(title);
    }

    //old code for single select
    //console.log(option);
    //setX(seriesData[option.value].x);
    //setY(seriesData[option.value].y);
  };
  */

  const handleRangeOptionChange = (event) => {
    setAutoRangeOption(event.target.value);
  };

  function getAutoRange() { 
    if (autoRangeOption==='limit') { return false; }
    else { return true; } 
  }

  function setYRange(series, curdata) { 
    if ((curdata.lower_display_limit != null) && (curdata.upper_display_limit != null))  {
      series.ymin = parseFloat(curdata.lower_display_limit);
      series.ymax = parseFloat(curdata.upper_display_limit);
      series.autorange = false;
      return series;
    }

    if ((curdata.lower_operating_limit != null) && (curdata.upper_operating_limit != null))  {
      series.ymin = parseFloat(curdata.lower_operating_limit);
      series.ymax = parseFloat(curdata.upper_operating_limit);
      series.autorange = false;
      return series;
    }

      series.ymin = 0.0;
      series.ymax = 0.0;
      series.autorange = true;
      return series;
  }

  function normalizeDate(date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  }


  function convertDatesToMinutes(dates) {
    return dates.map((date, index) => {
      if (index === 0) {
        return 0;
      }
      const diffInMinutes = Math.round((date - dates[0]) / (1000 * 60));
      return diffInMinutes;
    });
  }

  // Function to format date as HH:MM
  function formatTime(date) {
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' });
  }




  var seriesData = [];

	/*
	 
	TODO: group options in the code below to follow this format 
const options = [
  {
    label: 'Group 1',
    options: [
      { value: 'option1', label: 'Option 1' },
      { value: 'option2', label: 'Option 2' },
    ],
  },
  {
    label: 'Group 2',
    options: [
      { value: 'option3', label: 'Option 3' },
      { value: 'option4', label: 'Option 4' },
    ],
  },
];
*/


  // iterate over series data 
  if (props && props.data) { 
    for (let i = 0; i < props.data.length; ++i) {
      // define dropdown options 
      let option = { value: props.data[i].id, label: (props.data[i].name + ' (' + props.data[i].units + ')') } 
      //let option = { value: props.data[i].id, label: props.data[i].unit_type }
      options.push(option);

      let curSeries = props.data[i].data;
   
      //map and reshape timeseries by option values
      let x = [];
      let y = [];

      let lowtime = new Date(Date.parse(curSeries[0][0]));

      for (let j = 0; j < curSeries.length; j++) {
        //x.push(new Date(Date.parse(curSeries[j][0])) - lowtime);
        //x.push(new Date(Date.parse(curSeries[j][0] - curSeries[0][0])));
        x.push(new Date(Date.parse(curSeries[j][0])));
        y.push(parseFloat(curSeries[j][1]));
      }
 
      const xInMinutes = convertDatesToMinutes(x);
      //const xInHours = xInMinutes.map(minutes => Math.floor(minutes / 60));
      const xInHours = xInMinutes.map(minutes => minutes / 60);
      const xNormalized = x.map(date => normalizeDate(date));
      const xInMilliseconds = x.map((date, index) => date.getTime() - xNormalized[index].getTime());


      var series = {name: props.data[i].name, x: xInHours, y: y} ; 
      //var series = {name: props.data[i].name, x: xInMinutes, y: y} ; 
      //var series = {name: props.data[i].name, x: x, y: y} ; 
      series.ylabel = (props.data[i].unit_type + ' (' + props.data[i].units + ')');
      series = setYRange(series, props.data[i]); 

      // add series to data array for dynamic switching on dropdown change
      seriesData[props.data[i].id] = series;

      // handle default option 
      if (props.data[i].name === 'Argon Flow Rate') { 
        defaultOption = option; 
        defaultX = series.x;
        defaultY = series.y;

      } 
    }
  }

  const defaultOptions = [
    {value: '14149eb9-0127-458a-8f75-a6c5acd84b52', label: 'Argon Flow Rate (m³/hr)' }
  ];

  return (
    // Assuming this is inside a functional component
    // No need for curly braces around the function call
    //setSelectedOption({ value: '14149eb9-0127-458a-8f75-a6c5acd84b52', label: 'Argon Flow Rate' })


    <div className="plotCard">
      <h2 className="card-title">Parameter Plot</h2>

      <div className="eventfilterContainer">
        <div className="eventsearchBox" style={{width: '50%', paddingRight: '0px', marginRight: '0px'}}>
      { /*  <div style={{width: '90%', margin: '15px'}}> */ } 
      <Select options={options} 
	      defaultValue={defaultOptions} 
              onChange={handleChange}
              isMulti
      />
	  </div>
	  { /* </div> */ }


        <div className="eventfilterBox">
          <input
            type="radio"
            id="autoOption"
            name="autoRangeOption"
            value="auto"
            checked={autoRangeOption === 'auto'}
            onChange={handleRangeOptionChange}
          />
          <label htmlFor="autoOption">Auto Scale</label>

          <input
            type="radio"
            id="limitOption"
            name="autoRangeOption"
            value="limit"
            checked={autoRangeOption === 'limit'}
            onChange={handleRangeOptionChange}
          />
          <label htmlFor="limitOption">Limit Scale</label>
        </div>
      </div>


      <Plot
        key={`${chartType}-${JSON.stringify(selectedOption)}`}
        ref={plotRef} // Attach the ref to the Plot component
        data={(data.length>0) ? data : 
        [
          {
            x: (x.length>0 ? x : defaultX),
            y: (y.length>0 ? y : defaultY),
            type: chartType,
            /* mode: 'lines', */
            marker: {color: '#226581'},
          }
        ]

	     }
	  /*
        data={[
          {
            x: (x.length>0 ? x : defaultX),
            y: (y.length>0 ? y : defaultY),
            type: 'line',
            mode: 'lines',
            marker: {color: '#226581'},
          }
        ]}
	*/
       layout={ {
         title: title,
         borderwidth: '2',
         bordercolor: 'red',
         barmode: 'stack',
         autosize: true,
         showLegend: true,
         //width: window.innerWidth == 1366 ? '650' : '900',
         width: 'auto',
         width: window.innerWidth * .44,
         height: 'auto',
         margin: '1',
         paper_bgcolor: 'transparent',
         pad: 15,

		       /*
         margin: {
           l: 50, // Left margin
           r: 50, // Right margin
           t: 50, // Top margin
           b: 50, // Bottom margin
         }, 
	 */
         //plot_bgcolor: '#C9BCA6',
         //plot_bgcolor: 'C9BCA6',
         line_color: 'black',
         font: {
           family: 'monospace',
           color: 'white',
           size: 12
         },
         xaxis: {
           tickformat: '%H:%M', // show in date/time 
           //tickformat: (value) => formatTime(new Date(0, 0, 0, 0, value)), // Show in minutes 
          //tickformat: value => Math.floor(value/60),
//	  tickformat: (value) => {
//                        const hours = Math.floor(value / 60); // Extract hours from minutes
//                        return hours.toString().padStart(2, '0'); // Format hours with leading 0 if needed
//                      },
//           tickformat: (value) => value.map(minutes => Math.floor(minutes / 60).toString().padStart(2, '0'), // Use tickformat to display HH with leading 0s
//      const xInHours = xInMinutes.map(minutes => Math.floor(minutes / 60));
           tickangle: '360',
           tickwidth: '0',
           title: {
	     text: 'Time (Hours)', // Add units or label for the x-axis
           },
           //automargin: true, // Add space around the x-axis
         },
         yaxis: {
           tickangle: '360',
           tickwidth: '0',
           title: {
             //text: selectedOption && selectedOption.value && seriesData && seriesData[selectedOption.value] && seriesData[selectedOption.value].ylabel,
             text: yLabel
           },
           range: [selectedOption && selectedOption.value && seriesData && seriesData[selectedOption.value] && seriesData[selectedOption.value].ymin, 
                   selectedOption && selectedOption.value && seriesData && seriesData[selectedOption.value] && seriesData[selectedOption.value].ymax 
		  ], // Set the y-axis range
           autorange: getAutoRange()
		 
           //automargin: true, // Add space around the x-axis
         },

         // legend: {
         //   bgcolor: '#C9BCA6',
         //   padding: 10,
         //   font: {
         //     family: 'monospace',
         //     color: 'white',
         //     size: 20
         //   },
         // },
         hoverlabel: {
           font: {
             family: 'monospace',
             color: 'white',
             size: 15
           },
         }
       } }

      />

      <div className="eventfilterContainer">
        <div className="eventsearchBox" style={{width: '40%', paddingRight: '0px', marginRight: '0px'}}>
          <Select 
            options={chartTypes}
            value={chartType}
            onChange={handleChartChange}
          />
        </div>
        <div className="eventsearchBox" style={{width: '40%', paddingRight: '0px', marginRight: '0px'}}>
          <button onClick={saveChart}>Save Chart</button>
        </div>
      </div>

      <ToastContainer 
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={'light'}
      />
  
    </div>

  );

}

export default PlotCard;




