/************* React imports *****************/
import React, { Component } from 'react';
import { Link, NavLink, useHistory } from "react-router-dom";

/************* Shared app imports *****************/
import { Auth, useAuth } from '../../common/auth';
import * as CONSTANTS from '../../App/constants.js';
import { homeUrl } from '../../App/constants.js';

/************* style imports *****************/
  
  const Nav = (props) => {

    const history = useHistory();
    const {loggedIn, checkExistingSession, username, logout, currentCompany, companies } = useAuth();

    //console.log("username:" + username)
    //console.log("currentCompamy:" + companies)

    let valid = checkExistingSession(); // Wait for checkExistingSession to complete

    if (!valid) { 
      logout();
      window.location = homeUrl; 
    } 

	 

    function handleLogout()  {
      logout();
    };

    return(
      <div className={'navigationWrapper'}>
        <div className={'navigationItem'}>
          <NavLink to="/projects/all" activeStyle={{color: '#C9BCA6'}}>Projects</NavLink>
        </div>
        <div className={'navigationItem'}>
          <NavLink to="/charts" activeStyle={{color: '#C9BCA6'}}>Data Explorer</NavLink>
        </div>
        <div className={'navigationItem'}>
          <NavLink to="/standards" activeStyle={{color: '#C9BCA6'}}>Documentation</NavLink>
        </div>
        <div className={'navigationItem'}>
          <NavLink to="/" activeStyle={{color: '#C9BCA6'}} onClick={() => handleLogout()} >Logout</NavLink>
          <br/><span style={{fontSize: 'x-small'}}>{ username }</span>
          <br/><span style={{fontSize: 'x-small'}}>{ currentCompany? currentCompany.name : '' }</span>
        </div>
      </div>
    )

}
export default Nav;
