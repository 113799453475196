import React, {useState} from "react";
import { backUrl, projectIdentifier} from "../../App/constants";
import MainHeader from "../../common/nav/MainHeader";
import { Link } from "react-router-dom";
import { RemoveIcon } from '../../common/Icons';
import BasicTable from '../../common/basic-elements/BasicTable';
import SearchResultCard from "./SearchResultCard";
import './styles/SearchPage.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import SearchInput from './SearchInput';
import history from '../../utils/history';
import SubNav from '../../common/nav/SubNav';

var iconStyle = {
  height: '1em',
  marginLeft: '.4em',
  marginRight: '.3em',
  cursor: 'pointer'
};

var selectedCell = {
	background: 'lightsteelblue'
}

const SortCell = props =>
        <td style={{width: 250}} onClick= { props.onClick }>
                <div style={{cursor: 'pointer'}}>
                {props.title}
          {props.sortStatus.type != props.type && <i  className = "fa fa-sort"
                      style={iconStyle}>
                  </i> }
          {props.sortStatus.type == [props.type] && props.sortStatus.sort =='a'  && <i className="fa fa-caret-up" style={iconStyle}i></i> }
          {props.sortStatus.type == [props.type] && props.sortStatus.sort =='d'  && <i className="fa fa-caret-down" style={iconStyle}i></i> }

          {false &&   <i  className = "fa fa-filter"
                      style={{height:'1em'}}>
                  </i> }
          </div>
        </td>



export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searched: false,
      results: [],
      loading: false,
      query: '',
      sentValue: null,
      pageLoading: true,
      readyToClear: false,
      searchWord: ''
    };
  }


  clear = () => {
    this.setState({readyToClear: true});
    this.setState({query: ''});
    this.setState({searched: false});
  }

  onChange = (value) => {
    this.setState({query: value});
  }

  doSearch = (sWord) => {
    this.setState({loading: true})
    let headers = new Headers();
    /* for direct to elastic
    let elasticUser = 'amidx'
    let elasticPass = '43dp_PPpp'
    let elasticUrl = 'https://search-traceam-55aldma33jonbjrnkxxgseogt4.us-east-2.es.amazonaws.com/_search?q=' + this.state.query;


    headers.set('Authorization', 'Basic ' + btoa(elasticUser + ":" + elasticPass));
    */
    let elasticUrl = backUrl + 'search/' + this.state.query;

    fetch(elasticUrl, {headers: headers})
      .then(res => res.json())
      .then(json => {
        this.setState({results: json});
        this.setState({searched: true});
        this.setState({loading: false});
        this.setState({searchWord: sWord})
      })


  }

  componentDidMount = () => {
    this.setState({searched: false})
    if (history && history.location && history.location.state && history.location.state.searchQuery) {
      this.setState({query: history.location.state.searchQuery})
      this.setState({sentValue: history.location.state.searchQuery})
      this.setState({pageLoading: false})
    } else {
      this.setState({sentValue: null})
      this.setState({pageLoading: false})
    }
  }

  sentValue = () => {
    if (history && history.location && history.location.state && history.location.state.searchQuery) {
      return history.location.state.searchQuery;
    }
  }

  render() {
    const header = 'Search'
    const crumb = ["Home"]

    const { searched, loading, pageLoading } = this.state;

    return pageLoading ? (
      <div>
        <div className="loading-for-search">
           <Loader
            type="Circles"
            color="#C9BCA6"
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </div>
      </div>
    ) : (

      <div>
        <div className="searchTopBarWrapper">
          <SubNav section='explorer' current='search'/>
          <div className="searchInputWrapper searchBox">
            <h2>Run Search</h2>
            <div className="innerSIWrapper">
              <SearchInput
                 type="search"
                 onSearch={this.doSearch}
                 onChange={this.onChange}
                 sentValue={this.sentValue()}
                 history={history}
                 toClear={this.state.readyToClear}
                 afterClear={() => this.setState({readyToClear: false})}
              />
              <button onClick={() => this.clear()}>Clear</button>
            </div>
          </div>
        </div>

        <div className="resultsDiv">

        {loading &&
         ( <div className="loading-for-search">
            <Loader
             type="Circles"
             color="#00BFFF"
             height={100}
             width={100}
             timeout={3000} //3 secs
           />
          </div> )}

        <div
         className="search-wrapper"
         style={{gridTemplateColumns: "repeat(auto-fill, minmax(450px, 1fr))",gridGap: "20px", alignItems: "flex-start"}}
        >
         { !loading && searched && this.state.results.map(result =>
           <SearchResultCard
             key={result._id}
             index={result._index}
             data={result}
             searchedWord={this.state.searchWord}
           />
         )}

         { !loading && !!searched && !this.state.results.length &&  (
             <h2><center>No results</center></h2>
           )


         }
       </div>
       </div>




      </div>
    )
  }
}
