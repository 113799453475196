import React from 'react';
import Button from '../basic-elements/Button';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { backUrl } from '../../App/constants';
import MainHeader from '../nav/MainHeader';
import Modal from 'react-modal';
import { RemoveIcon } from '../Icons';
import Modal2 from '../basic-elements/Modal';

// STYLING
var ListContainer = styled.div`
    display: flex;
    justify-content: flex-start
`

var TeamsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 20px;
`

export var BasicTable = styled.table`  
  background: #FFF;
  border-collapse: collapse;
  table-layout: auto;
  margin-right: 40px;
  min-width: 500px;
  box-shadow: 5px 0px 10px rgba(0,0,0,0.5);
  thead {
          background: rgb(0, 154, 212);
          color: #FFF;
          font-weight: bold;
  }
  tbody td { 
        border-top: 1px solid #DEE2E6;
        border-bottom: 1px solid #DEE2E6;
        padding-left: 12px;
  }
  @media (max-width: 1024px) {
    flex: 1;
  }
`

var ButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`

const DeleteModal = props => 
<div>
  <h4>Are you sure you want to delete this {props.type}?</h4>
  <button onClick={props.delete} >yes</button>
  <button onClick={props.close}>no</button>
</div>

export default class Teams extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      teams: [],
      showUserModal: false,
      userModal: null,
      showTeamModal: false,
      teamModal: null,
      showDeleteUserModal: false,
    }
  }

  componentDidMount() {
    fetch('http://3.17.141.219:8000/api/users', {
            headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage["appState"]).user.auth_token}`
    }})
    .then(a => a.json())
    .then(a => {console.log(a);this.setState({users: a})});

    fetch( backUrl + 'api/teams', {
            headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage["appState"]).user.auth_token}`
            }
    }).then(a => a.json())
          .then(a => {console.log('b', a);this.setState({teams: a})})
  }

  deleteTeam(id) {
    fetch('http://3.17.141.219:8000/api/team/delete/' + id, {
            method: 'POST',
            headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage["appState"]).user.auth_token}`
            }
    }).then( a => a.json())
      .then( a => {
        var { teams } = this.state;
        console.log(a);
        teams = teams.filter(team => team.id != a.id);
        console.log(teams);
        this.setState({teams});
      })
  }

  openDeleteUserModal(userId) {
    this.setState({ 
      showDeleteUserModal: true,
      userToDelete: userId
    });
  }

  closeDeleteUserModal() {
    this.setState({
      showDeleteUserModal: false,
      userToDelete: null
    });
  }

  handleDeleteUserClick(event, userId) {
    if ( userId == 1 ) {
      alert('cannot delete admin user');
    } else if ( event.shiftKey ) {
      this.setState({userToDelete: userId}, () => {
        this.deleteUser(); 
      });
    }
      else {
      this.openDeleteUserModal(userId);
    }
  }

  deleteUser() {
    fetch('http://3.17.141.219:8000/api/user/delete/' + this.state.userToDelete, {
            method: 'POST',
            headers: {
        'Authorization': `Bearer ${JSON.parse(localStorage["appState"]).user.auth_token}`
            }
    }).then(a => a.json())
      .then( a => {
        var { users } = this.state; 
        users = users.filter(u => u.id != a.id);
        this.setState({users, showDeleteUserModal: false});
    })
  }

  openUserModal(u) { this.setState({showUserModal: true, userModal: u}) }

  closeUserModal() {this.setState({showUserModal:false, userModal: null})}

  openTeamModal(t){this.setState({showTeamModal:true, teamModal:t}, ()=>{console.log(this.state.teamModal)})}
  closeTeamModal(){this.setState({showTeamModal:false, teamModal:null})}
      

  render() {
    return (
      <div>
      <Modal2
        isOpen={this.state.showDeleteUserModal}
        onRequestClose= {() => this.closeDeleteUserModal()}
      >
        <DeleteModal 
          delete={() => this.deleteUser()}
          close = {() => this.closeDeleteUserModal() }
        />
      </Modal2>
      <Modal
        isOpen={this.state.showTeamModal}
        onRequestClose={() => this.closeTeamModal()}
        style={{
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }}}
      >
        { this.state.teamModal && 
          <div>
            <p> Name: {this.state.teamModal.name } </p>
            <p> Created By: admin </p>
            <p> Created at: {this.state.teamModal.created_at} </p>
            <p> Members: </p> 
            {this.state.teamModal.user.map(u =>
              <p>{u.name} | {u.email} </p>
            )}
            <p> Projects: </p>
            { this.state.teamModal.project.map(p=>
              <p>{p.proc_title}</p>
            )}
          </div>
        }
      </Modal>
      <Modal
        isOpen={this.state.showUserModal}
        onRequestClose={() => this.closeUserModal()}
        style={{
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }}}
      >
      {this.state.userModal ? this.state.userModal.name : null}'s Teams:
      { this.state.userModal && 
        this.state.userModal.team.map(t => 
          <p>
          {t.name}
</p>
        )
      }
      
      </Modal>
      <MainHeader header="Teams" crumb={['Home', 'Teams']}/>
      <TeamsContainer>
        <ButtonContainer>
          <Link to='teams/new'><Button> Add New Team </Button></Link>
          <Link to='users/new'><Button> Add New User </Button></Link>
        </ButtonContainer>
        <ListContainer>
          <BasicTable>
            {this.state.teams.length == 0 && <tr> You Have No Teams</tr> }
            {this.state.teams.length >= 1 && 
                            <div>
                <thead>
                  <td>Team Name</td>
                  <td> Created By </td>
                  <td> Details </td>
                  <td> Remove </td>
                </thead>
              <tbody>
              { this.state.teams.map(team => 
                <tr>
                  <td style={{minWidth: '180px'}}>{team.name}</td>
                  <td style={{minWidth: '140px'}}>admin</td>
                  <td style={{textAlign: 'center'}}> <i onClick={() => this.openTeamModal(team)}className='fa fa-info-circle' /></td>
                  <td style={{textAlign: 'center'}}> <RemoveIcon onClick={() => this.deleteTeam(team.id)} /></td>
                </tr>

              )}
              </tbody>
                    </div>
            }
          </BasicTable>
          <BasicTable>
          { this.state.users.length == 0 && <tr> You Have No Users </tr> }
          { this.state.users.length >= 1 && 
              <div>
            <thead>
              <td>
                User Name
              </td>
              <td>
                Email
              </td>
              <td>
                Teams
              </td>
                  <td> Remove </td>

            </thead>
            <tbody>
              {this.state.users.map(user =>
                <tr>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td style={{color: 'rgb(0,154,212)', cursor: 'pointer', textAlign: 'center'}}onClick={() => this.openUserModal(user)}>{user.team ? user.team.length : ''} </td>
                  <td style={{textAlign: 'center'}}> <RemoveIcon onClick={(e) => this.handleDeleteUserClick(e, user.id)} /></td>

                </tr>
              )}
            </tbody>
              </div>
          }
          </BasicTable>
        </ListContainer>
      </TeamsContainer>
      </div>
    );
  }
}
