/*
 *   The purpose of the New Material View is to take the input from a user to define the 
 *   properties of a Material {Batch}/{Blend}, send an API call to the backend 
 * 
 *
 */
import React from "react";
import MainHeader from "../../common/nav/MainHeader";
import Input from '../../common/basic-elements/Input';
import Select from '../../common/basic-elements/Select';
import { backUrl } from '../../App/constants';
import { Material } from '../../App/api';

/*
 *  Static information used to model the properties of a material {batch}/{blend} 
 *  ( where is the definition of how this is formatted? )
 */
const test = [
	{
		field: 'name',
		label: 'Name',
	},
	{
    label: 'Date Created',
    field: 'date_created',
		type: 'date',
	},
	{
		type: 'dropdown',
		label: 'Material Type',
    field: 'material_type',
		options: [
			'316 Stainless Steel',
			'17-4 Stainless Steel',
			'Cobalt Chrome',
			'AlSi10Mg',
			'Titanium 6-4',
			'Titanium 6-4 ELI',
			'Inconel 625',
			'Inconel 718',
			'PA-12',
			'PA-11',
			'TPU'
		]
	},
	{
    field: 'lot_numbers',
		label: 'Lot Numbers',
	},
	{
    field: 'blend_ratio',
		label: 'Blend Ratio',
	},
	{
    field: 'chemical_composition',
		label: 'Chemical Composition',
	},
	{
		type: 'group',
		label: 'Particle Size:',
		questions: [
     {label: 'D10', field: 'particle_size_d10'},
     {label: 'D50', field: 'particle_size_d50'},
     {label: 'D90', field: 'particle_size_d90'},
		]
	},
	{
		type: 'groups',
    label: '',
    questions: [
      {label: 'Aspect Ratio D10', field: 'aspect_ratio_d10'},
      {label: 'Circularity D10', field: 'circularity_d10'},
      {label: 'Convexity D10', field: 'convexity_d10'}
    ]
	},
	{
    field: 'hausner_ratio',
		label: 'Hausner Ratio',
	},
	{
    field: 'angle_of_repose',
		label: 'Angle of Repose',
	},
	{
    field: 'rheology',
		label: 'Rheology',
	},
	{
    field: 'total_amount',
		label: 'Total amount',
	},
];

export default class NewMaterial extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			name: null,
      total_amount: null,
      rheology: null,
      angle_of_repose: null,
      hausner_ratio: null,
      circularity: null,
      convexity: null,
      aspect_ratio: null,
      particle_size_d10: null,
      particle_size_d50: null,
      particle_size_d90: null,
      chemical_composition: null,
      blend_ratio: null,
      lot_numbers: null,
      material_type: null,
      date_created: null,
      name: null
		};
  }

	updateMaterial(e, propertyName) {
    console.log(e.target.value);
    console.log(propertyName);
    
		var newValue = e.target.value;
		this.setState({
			[propertyName]: newValue
		});
	}

	async saveMaterial() {
		var material = {
			name: this.state.name,
      total_amount: this.state.total_amount,
      rheology: this.state.rheology,
      angle_of_repose: this.state.angle_of_repose,
      hausner_ratio: this.state.hausner_ratio,
      circularity_d10: this.state.circularity_d10,
      aspect_ratio_d10: this.state.aspect_ratio_d10,
      particle_size_d10: this.state.particle_size_d10,
      particle_size_d50: this.state.particle_size_d50,
      particle_size_d90: this.state.particle_size_d90,
      chemical_composition: this.state.chemical_composition,
      blend_ratio: this.state.blend_ratio,
      lot_numbers: this.state.lot_numbers,
      material_type: this.state.material_type,
      date_created: this.state.date_created,
      convexity_d10: this.state.convexity_d10
		}

    var material = await Material.create(material);
    // error handling here.
    this.props.history.push(`/materials`);

	}

  cool(e, c) {
    var propertyName  = test[6].questions[c].field;

		var newValue = e.target.value;
		this.setState({
			[propertyName]: newValue
		});
    console.log(this.state);
  
  }

  sweet(e, c) {
    var propertyName  = test[7].questions[c].field;

		var newValue = e.target.value;
		this.setState({
			[propertyName]: newValue
		});
  
  }

	render() {
		return(
			<div>
			<MainHeader crumb={["Home", "Materials Tracking"]} header="Create New"/>
			{
				test.map(t => 
					<div>
					{ (!t.type || t.type == 'date') && <Input onChange={(e) => this.updateMaterial(e, t.field)} 
								 type={t.type} 
								 label={t.label} 
					/> }
					{ t.type == 'dropdown' && <Select onChange={(e) => this.updateMaterial(e, t.field)} options={t.options} label={t.label}/> }
					{ t.type == 'group' && <GroupA t={t} cool={(e, i) => this.cool(e, i)}/> }
					{ t.type == 'groups' && <GroupA t={t} cool={(e, i) => this.sweet(e, i)}/> }
					</div>
				)
			}
			<button onClick={() => this.saveMaterial()}>Save</button>
			</div>
		);
	}
}

const GroupA = props => {
	console.log(props);
	return(
	<div style={{marginBottom: 20, marginLeft: '30px', display: 'flex', flexDirection: 'column'}}>
				<label>{props.t.label}</label>
		<div style={{display: 'flex'}}>
		{props.t.questions.map((q,i) => 
			<div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginRight: '23px'}}>
			<label>{ q.label }</label>
			<input style={{width: 200}}onChange={(e) => props.cool(e, i)}/>
			</div>
		)}
		</div>
	</div>
)}

