import { backUrl } from "../../../App/constants";
import { useAuth } from '../../auth';

/**
 * Custom hook for accessing the backend API.
 *
 * @returns {Object} API functions and additional states.
 */
const useTraceAPI = () => {
  const { loggedIn, token } = useAuth(); // Get authentication data from useAuth hook

/*
  const callPostAPI = async (endpoint, method = 'POST') => {
    var project = this.state.project
    project['template_id'] = this.state.templateId;
    const url = backUrl + 'projects';
    fetch(url, {
      method: "POST",
      headers:  {
      "Content-Type": "application/json",
      "Accept": "application/json"
    },
      body: JSON.stringify({project: project})
    })
      .then(res => res.json())
      .then(json => {
          this.props.history.push(`/builds`);
      });
  }
  */


  /**
   * Functions to make API calls.
   *
   * @param {string} endpoint - The API endpoint.
   * @param {string} method - The HTTP method (default is 'GET').
   * @returns {Object} API response.
   */
  const callPostAPI = async (endpoint, method = 'POST') => {
    return await callAPI(endpoint, method);
  };

  const callGetAPI = async (endpoint, method = 'GET') => {
   return await callAPI(endpoint, method);
  };

  const callAPI = async (endpoint, method) => {
	  //console.log("ENDPOINT:" + endpoint);
    // Check if user is logged in
    if (!loggedIn) {
      return {
        success: false,
        message: "User not logged in",
      };
    }

    try {
      // Make a fetch request to the API
      // todo check for FQDN in URL and switch logic
      const response = await fetch(backUrl + endpoint, {
        method,
        mode: "cors", 
        headers: {
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + token,
          "Accept": "application/json"
        }
      });

      // Check the response status code
      if (response.status === 200) {
        // The request was successful, parse the response data
        const data = await response.json();
        return {
          success: true,
          data,
        };
      } else {
        // The request failed, return an error object
        return {
          success: false,
          message: await response.text(),
        };
      }
    } catch (error) {
      // An error occurred, return an error object
      console.error('Error:', error);
      return {
        success: false,
        message: error.message,
      };
    }
  };

  /**
   * Function to get projects using the callGetAPI function.
   *
   * @returns {Object} API response containing projects.
   */
  const getProject = async (id) => callGetAPI('/projects/' + id);
  const getTemplate = async (id) => callGetAPI('/templates/' + id);
  const getProjects = async () => callGetAPI('/projects');
  const getBuilds = async () => callGetAPI('/builds');
  const getParts = async () => callGetAPI('/parts');
  const getTemplates = async () => callGetAPI('/templates');

  // Return API functions and additional states
  return {
    getBuilds,
    getParts,
    getProject,
    getTemplate,
    getProjects,
    getTemplates
  };
};

export default useTraceAPI;




