import React, {useState, useEffect} from "react";

const SearchInput = (props) => {
  const [value, setValue] = useState('');




  function doSearch(){
    props.onSearch();
  }

  function handleKeyUp(e){
    if (e.key === 'Enter') {
      if (props.type === 'search'){
        props.onSearch(value);
      } else {
        props.history.push({
          pathname: '/search',
          state: { searchQuery: e.target.value }
        })
        window.location.reload();
      }
    }
  }

  function searchUpdated(e){
    setValue(props.sentValue)
    if (props.type === 'search'){
      setValue(e.target.value)
      props.onChange(e.target.value);
    }
  }

  useEffect(() => {
    if (props.sentValue != null && props.type == 'search'){
      setValue(props.sentValue)
      doSearch();
    }
  }, [props.sentValue])


    useEffect(() => {
      if (props.toClear == true){
        setValue('');
        props.afterClear();
      }
    }, [props.toClear])



    return(
      <div className="search-input">
          <input
            value={value}
            onKeyUp={handleKeyUp}
            onChange={(e) => {searchUpdated(e)}}
            placeholder='Search...'
          />
      </div>
    )



}

export default SearchInput;
