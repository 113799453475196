import React, {useEffect} from "react";
import InputAdapter from "../../common/basic-elements/InputAdapter";
import { Link } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import SubNav from '../../common/nav/SubNav';
import '../../assets/css/Main.css';
import SearchBox from '../search/SearchBox';
import '../search/styles/SearchPage.css';

const SectionPage = props => {
  var route = "/projects/new/";
  if (props.type == "active") {
    route = `/project/${ props.project.id }/`;
  }



  return(
    <div>
      <div className="searchTopBarWrapper">
        <SubNav section='projects' current='new-project'/>
        <SearchBox />
      </div>
      <div className="main-div-wrapper">
        <div className="maintop">
          <h1>New Project</h1>
        </div>
        <div className="fieldsWrapper">
        <ProgressBar
           id = { props.project ? props.project.id : null }
           titles = { [props.title] }
           index  = { props.index }
           type   = { props.type }
           noLink = {true} />

        <div className='build-fields'>
        { props.data.map( data =>
              <InputAdapter users    = { props.users }
  													teams    = { props.teams }
                            changeTemplate = {props.changeTemplate}
                            templates = {props.templates }
                            openTeamModal = { props.openTeamModal}
                            makePublic = { props.makePublic }
                            makePrivate = { props.makePrivate }
  	      									project  = { props.project }
                            set      = { data }
                            teamsToAdd = { props.teamsToAdd}
                            onChange = { props.onChange }
                            onUpload = { props.onUpload }
                            removeFile = { props.removeFile }/>
        )}

          <div className='bottom-nav-footer'>
            <BottomNav
                save     = { props.save }
                index    = { props.index }
                numPages = { props.numPages }
                route    = { route } />
            </div>
        </div>
        </div>

      </div>
    </div>
  );
}

export default SectionPage;

const BottomNav = props =>(
      <div className="bottomnav" style={{display: "flex", marginLeft: "20px"}}>
          <button onClick={props.save }>Save Project</button>
      </div>

);
