import React from "react";
import Card from './Card';
import { Link } from "react-router-dom";


export function SearchResultGenericCard(props: any) {

function truncate(str) {
    return str.length > 150 ? str.substring(0, 150) + "..." : str;
}

  return(
		<Link to = {'/project/' + props.index + '/0'} >
      <Card title='Search Result'>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h3>Found item</h3>
        </div>
        <div> 
					<div> 
						<span style={{overflow: 'hidden', textOverflow: 'ellipsis', color: 'black', width: '25%'}}></span>
					</div>
        </div>
      </Card>
		</Link>
  )



  }


export default SearchResultGenericCard;


