import React from "react";
import './styles/Card.css';
import history from '../../utils/history';

function pickForTitle(title, type) {
  if (title === 'File'){
    if (type === 'icon'){
      return "fa-file"
    } else {
      return "#fcba03"
    }
  } else if (title === 'Build'){
    if (type === 'icon'){
      return "fa-folder-open"
    } else {
      return "#EE6123"
    }
  } else if (title === 'Part'){
    if (type === 'icon'){
      return "fa-clipboard"
    } else {
      return "#66101F"
    }
  } else if (title === 'Material'){
    if (type === 'icon'){
      return "fa-sticky-note"
    } else {
      return "#424874"
    }
  } else if (title === 'Project'){
    if (type === 'icon'){
      return "fa-clone"
    } else {
      return "#662E9B"
    }
  } else if (title === 'Tests'){
    if (type === 'icon'){
      return "fa-flask"
    } else {
      return "#009BD4"
    }
  } else {
    if (type === 'icon'){
      return "fa-bookmark"
    } else {
      return "#6ecf3e"
    }
  }
}


const Card = props => {

  return (
    <div className="card card-wrapper">
      <div className="title-wrap">
        <div className="left-side-of-title">
          <div className="spec-card-icon">
            <i className={`far ${pickForTitle(props.title, 'icon')}`}></i>
          </div>
          <h2 className="card-title spec-card-title">
            {props.title}
          </h2>
        </div>
        <div className="right-side-of-title">
          <button><a href={props.buildLink}>View Build</a></button>
          { props.fileLink &&
            <button><a href={props.fileLink}>View File</a></button>
          }
        </div>
      </div>
      <div className="content-wrapper">
    		<p className="card-content" style={{display: 'none'}}>{props.content}</p>
    		{ props.children }
      </div>
  	</div>
  )

}

export default Card;
