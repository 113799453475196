import React from "react";
import MainHeader from "../../common/nav/MainHeader";
//import MaterialsJSON from "../asset/materials.json";
import upload from "../../assets/images/upload.png";
import { docUrl } from '../../App/constants';
import SubNav from '../../common/nav/SubNav';

const crumb = ["Home", "Maintenance"]
//const materials = MaterialsJSON.materials;

// Materials should be stateful
// Materials array. [m1, m2] server
// iterate over materials array and display on screen.
// ( Materials List component )

class Maintenance extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			show: false,
			maintenance:
				{
					maintenance_date:null,
					machine:null,
					file:null
				},
				data:[],
				token: JSON.parse(localStorage["appState"]).user.auth_token,
		};
	}
	changeState(){
		this.setState({show:true});
	}

	handleChange(e){
		var maintenance = this.state.maintenance;
		var {id,value} = e.target;
		maintenance[id] = value;
		// set the state of the material object.
		this.setState({maintenance});
	}

	handleFile(e){
		var fileIn = e.target.files[0];
		var maintenance = this.state.maintenance
		maintenance["file"] = fileIn
		this.setState({maintenance});
	}

	handleSubmit(e){
		e.preventDefault();
		var formData = new FormData();

		var currentMaintenance = this.state.maintenance;

		for (let lessFunny in currentMaintenance ) {
			formData.append(lessFunny, currentMaintenance[lessFunny]);
		}



		const url = docUrl + 'maintenances';
		fetch(url, {
			method: "POST",
			body: formData,
			headers: new Headers({
				'Authorization': `Bearer ${this.state.token}`
			})
		})
			.then(res => res.json())
			.then(json => this.updateData())
			.then(() => window.location.reload())
	}

	updateData(){
		var newData = this.state.data;
		var newMaintenance = this.state.maintenance;
		newData.push(newMaintenance);
		this.setState({data:newData})
	}

	componentWillMount(){
		this.getMaintenance();
	}

	delete(id){
		var url = docUrl + 'maintenances/' + id;
		var formData = new FormData();
		formData.append("id", id);
		fetch(url, {
			method: "DELETE",
			body: formData,
			headers: new Headers({ "Authorization": `Bearer ${this.state.token}`})
		})
		.then(() => window.location.reload())
	}

	getMaintenance(){
		const url = docUrl + 'maintenances';
		fetch(url, {
			method: "GET",
			headers: new Headers({
				'Authorization': `Bearer ${this.state.token}`
			})
		})
			.then(res => res.json())
			.then(json => {
				this.setState({
					data: json
				},
			);

			})
	}

	render(){
		return(
			<div>
				<SubNav section='documentation' current='maintenance'/>


				<MaintenanceList data = {this.state.data}
					delete = { (id) => this.delete(id) }/>
			</div>
		);
	}
}

const New = props =>
			<div className = "new-doc">
				<br/>
				<form onSubmit = { props.handleSubmit}>
					<label>Date<input onChange={ props.handleChange } id="maintenance_date"/></label>
					<label>Machine<input onChange={ props.handleChange } id="machine"/></label>
					<br/><br/>
					<div className ="upload-submit-doc">
						<label className = "upload">
							Upload File...
							<input type = "file" className = "inputfile" accept=".pdf" onChange={ props.handleFile } id="file"/>
						</label>
						<input type = "submit" className ="submit-doc" />
					</div>
				</form>
		</div>

const MaintenanceList = props =>
			<div>
				<table className="table-basic">
					<thead>
            <tr>
						<td>Date</td>
						<td>Machine</td>
						<td>Documentation</td>
						<td></td>
            </tr>
					</thead>
					<tbody>
						{props.data.map(info =>
							<tr>
								<td>
									{info.maintenance_date}
								</td>
								<td>
									{info.machine}
								</td>
								<td>
									<a href = {info.file_url} target="_blank">View</a>
								</td>
								<td onClick={(id) => props.delete(info.id)}>Delete</td>
							</tr>)}
					</tbody>
				</table>
			</div>

export default Maintenance

// <button className="btn" onClick={ () => this.changeState() }>Add New</button>
// { this.state.show && (< New handleSubmit = { (e) => this.handleSubmit(e) }
// 													 handleChange = { (e) => this.handleChange(e) }
// 													 handleFile = { (e) => this.handleFile(e) }/>) }
