import React from 'react';
import Modal from 'react-modal';


const AppModal = props => {
  var centerStyle = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflowY: 'scroll',
        backgroundColor: '#C9BCA6',
        border: '2px solid #333745',
        borderRadius: '0',
        width: props.browse ? '80%' : '50%',
        maxHeight: '60%'
      }
    };
  return (
    <Modal
      isOpen         = { props.isOpen }
      onRequestClose = { props.onClose }
      style          = { centerStyle}

    >
      { props.children }
    </Modal>
  )

}


export default AppModal;
