/**
 * Login page header 
 * 
 * Banner header with TRACEAM
 *
 * Usage:
 * <Header/>
 *
 * @returns header div
 */



/************* React imports *****************/
import { Link } from "react-router-dom";


function Header() {
  return (
  	  <div className='top-header-wrapper'>
        <div className={'top-header'}>
          <Link to="/">
            <h1>TRACE<i>AM</i></h1>
          </Link>
        </div>
      </div>
  );
}
export default Header;
