import React from 'react';
import Card from '../../common/Card';

var imgStyle = {
        height: '100px',
	marginLeft: '20px'
}

class Builddata extends React.Component { 
	
	
	constructor(props) {
		super(props);
//     const iframe = '<'; 
	}

	componentDidMount() {
	}

  asdf() {
    //var bid = this.props.match.params.build;
    let html = '<iframe frameborder=0 src="https://afrlrw.traceam.com/data.html?build=';
    //html += bid
    html += '" width="100%" height="100000" scrolling="no"></iframe>';
    return {__html: '<iframe frameborder=0 src="https://afrlrw.traceam.com/data.html" width="100%" height="200000" scrolling="no"></iframe>'};
    //return {__html: html};

  }



  render() {
    return(
      <Card title='Build Data'>
            <div style={{display: 'flex' }}>
              <div style={{border: 'none'}, {width: '100%'}} dangerouslySetInnerHTML={ this.asdf() } />
            </div>
      </Card>
    )
  }
}



export default Builddata;
