import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './utils/registerServiceWorker';
import "font-awesome/css/font-awesome.min.css";
import { useNavigate } from 'react-router';

import { createRoot } from 'react-dom/client';

import * as Sentry from "@sentry/react"
import { sentryDSN } from './App/constants';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory()

Sentry.init({
    dsn: sentryDSN,
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);


registerServiceWorker();


