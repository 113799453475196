import React from "react";
import Card from './Card';
import { Link } from "react-router-dom";
import './styles/Card.css';


export function SearchResultBuildCard(props: any) {
  var buildlink = '/build/' + props.data.id + '/0';
  return(

      <Card title='Build' buildLink={buildlink}>
        <div className="contentTitle">
          <p className="contentLabel">Build:</p>
          <h3>
		        <a href={buildlink} target='_blank'>
              {props.data._source.build_id}
            </a>
          </h3>
          <h4>
          </h4>
        </div>

        <div className="inner-content-wrapper">
          <p className="contentLabel">Process Type:</p>
          <h4>{props.data._source.process_type}</h4>
          <p className="contentLabel">Manufacturer:</p>
          <h4>{props.data._source.process_machine_manufacturer}</h4>
          <p className="contentLabel">Machine:</p>
          <h4>  {props.data._source.process_machine_model}</h4>
          <p className="contentLabel">Feedstock:</p>
          <h4>{props.data._source.feedstock_trade_name}</h4>
        </div>

      </Card>
  )


  }


export default SearchResultBuildCard;
