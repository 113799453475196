import React from "react";
import styled from 'styled-components';

var Header = styled.div`
	display: flex;
	background-color: rgb(0, 154, 212);
	margin-bottom: 6px;
`
var HeaderText = styled.h4`
	color: #FFF;
	margin: 0;
	margin-left: 12px;
	margin-bottom: 4px;
`
var TableContainer = styled.div`
	border-bottom: 1px solid lightgrey;
	padding-left: 15px;
	padding-right: 15px;
	display: flex;
	align-items: center;
	justify-content:space-between;
`

var TableText = styled.h5`
	font-weight: normal;
`

const LCard = props => {
  return(
    <div className="lcard" >
      <Header>
        <HeaderText>{props.title}</HeaderText>
      </Header>
      { props.data && props.data.length > 0 &&
	      <div style={{display: 'flex', flexDirection: 'column'}}>
			    { props.data.map( datum =>
		<TableContainer>
	     	        <TableText>{ datum.type }</TableText>
		            <TableText>{ datum.average }</TableText>
		</TableContainer>
          )}
	       </div>
	     }
    </div>
  );
}

export default LCard;
