/**
 * Main App Component
 * 
 * This is the main application which routes user to login or main application
 *
 * Usage:
 * const container = document.getElementById('root');
 * const root = createRoot(container);
 * root.render(<App />);
 *
 */


/******* react imports **********/
import React, { createContext, useContext, Component, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';


/******* shared application components **********/
import Home from "../modules/home";
import TopBar from "../common/nav/TopBar";
import { Auth, useAuth } from '../common/auth';

/******* application modules/pages **********/
import Login from '../modules/login';
import Builddata from "../modules/builddata/Builddata";
import RecentBuilds from "../modules/dashboard/RecentBuilds";
import Alive from "../modules/Alive";

/******* styles **********/
import './App.css';
import styled from 'styled-components';


class App extends React.Component {



  render() {
    return (
      <Router>
        <Auth> {/* Wrap the entire component tree with Auth context */}
          <AppContent />
       </Auth>
      </Router>
    )
  }
}





const AppContent = () => {
  // Use the context hook to access the loggedIn state
  const { loggedIn, checkExistingSession } = useAuth();

  useEffect(() => {
    const checkLogin = async () => {
      await checkExistingSession(); // Wait for checkExistingSession to complete
      console.log("here: " + loggedIn); // loggedIn will be updated after checkExistingSession
    };

    checkLogin();
  }, []); // Empty dependency array ensures this effect runs only once on initial component mount


  return (
    <div>
     <Switch>
      {/* Define routes and render components based on loggedIn */}
      <Route exact path="/alive" render={() => <Alive />}/>
      <Route exact path="/">
        {loggedIn ? <Redirect to="/home" /> : <Login />}
      </Route>
      <Route path="/">
        {loggedIn ? <Home /> : <Redirect to="/" />}
      </Route>
      </Switch>
    </div>
  );
};



export default App;
