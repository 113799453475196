import React, {useEffect, useState} from "react";
import ActiveProjectsCard from "./ActiveProjectsCard";
import MainHeader from "../../common/nav/MainHeader";
import LCard from "./LCard";
import LCard2 from './LCard2';
import { backUrl } from '../../App/constants';
import RecentBuilds from './RecentBuilds';
import ProjectStats from '../../common/ProjectStats';
import StatsCard from './StatsCard';
import SearchInput from '../search/SearchInput';
import history from '../../utils/history';
import PlotTable from './PlotTable';
import './dashboard.css';
import SearchBox from '../search/SearchBox';
import Card from '../../common/Card';
import Plot from 'react-plotly.js';
import {getBuildTypes, count_duplicate} from './PlotData';

const crumb = ["Home"]
const ultimateColors = [
['rgb(37, 48, 34)', 'rgb(65, 84, 59)', 'rgb(91, 117, 83)', 'rgb(122, 153, 112)', 'rgb(155, 179, 148)'],
['rgb(22, 63, 80)', 'rgb(26, 75, 97)', 'rgb(34, 101, 129)', 'rgb(47, 138, 177)', 'rgb(94, 177, 212)'],
['rgb(34, 101, 129)', 'rgb(47, 138, 177)', 'rgb(94, 177, 212)'],
];

const Dashboard = (props) => {

	const [allProjects, setAllProjects] = useState(null);
	const [data, setData] = useState({});
	const [token, setToken] = useState('');
	const [materials, setMaterials] = useState([]);
	const [processLabels, setProcessLabels] = useState([]);
	const [processValues, setProcessValues] = useState([]);

	useEffect(() => {
		getMaterials();
	}, []);

	useEffect(() => {
		var all_process_types = getBuildTypes(props.builds)
		var processCounts = count_duplicate(all_process_types)
		setProcessLabels(Object.keys(processCounts))
		setProcessValues(Object.values(processCounts))
  }, [props.builds]);

	function getMaterials() {
    fetch(backUrl + 'materials')
      .then((a) => a.json())
      .then(b => {
				setMaterials(b)
      });
  }

        // non-numeric data in x will be excluded.
  function averageXByY( projects, x, y ) {
          var filteredProjects = projects.map(project => project[y])
                             .filter(project => project && project != 'null');
          var uniqueProjects = [... new Set(filteredProjects)];

          var averages =  uniqueProjects.map( project => {
              projects.filter(p => p[y] == project);

              var obj = {};
              obj.type = project;
              obj.average = getAverage(projects, x, y, project);

              return obj;
          })

          return averages;
  }

  function getAverage(projects, x, y, cool) {

        var num = projects.filter(p => p && p[y] == cool)
                .map(b => Number(b[x]) ? Number(b[x]) : null )
                .reduce((a, b) => a + b, 0)

        var denom = projects.filter(p => p && p[y] == cool).length

        return (num / denom);

  }




		return(

		<div>
		<div className='dashboard-container' >
				<div className='left-side'>
					<ActiveProjectsCard
						activeProjects={props.activeProjects}
						parts={props.parts}
						builds={props.builds}
					/>
					<PlotTable projects={props.builds} materials={props.materials} colors={ultimateColors}/>
				</div>
				<div className='right-side'>
					<SearchBox />
					<div className="multidivs">
						<StatsCard title="Parts Built" stat={props.parts.length}/>
						<StatsCard title="Number of Builds" stat={props.builds.length}/>
					</div>

					<Card title="Technology Used" noMargin={true} full={true}>
						<Plot
							data={[
								{
									labels: processLabels,
									values: processValues,
									name: 'total builds',
									type: 'pie',
									hole: '.4',
									hoverinfo: 'label+percent+name',
									marker: {colors: ultimateColors[0]},
								},
							]}
							layout={ {
								width: window.innerWidth == 1366 ? '600' : '850',
								height: 'auto',
								margin: '0 auto',
								paper_bgcolor: 'transparent',
								padding: 0,
								plot_pgcolor: '#C9BCA6',
								font: {
									family: 'monospace',
									color: 'white',
									size: 15
								},
								legend: {
									// bgcolor: '#C9BCA6',
									// padding: 10,
									font: {
										family: 'monospace',
										color: 'white',
										size: 15
									},
								},
								hoverlabel: {
									font: {
										family: 'monospace',
										color: 'white',
										size: 17
									},
								}
							} }
						/>
					</Card>
				</div>
		</div>

		</div>);
}

export default Dashboard
