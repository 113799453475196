import React, {useEffect, useState} from "react";
import { backUrl } from "../../App/constants";
import Card from '../../common/Card';
import Plot from 'react-plotly.js';
import SubNav from '../../common/nav/SubNav'
import SearchInput from '../search/SearchInput';
import MyChartCard from './MyChartCard.jsx';
import { ToastContainer, toast } from 'react-toastify';

//import styles from './styles.css';

const Charts = () => {
  const [charts, setCharts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not available');
        }

        const response = await fetch(`${backUrl}charts`, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();

        // Parse the JSON strings in data and layout properties
        const chartsWithParsedData = data.map((chart) => ({
          ...chart,
          data: JSON.parse(chart.data),
          layout: JSON.parse(chart.layout),
          source: JSON.parse(chart.source),
          id: chart.id
        }));

        setCharts(chartsWithParsedData);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      }
    };

    fetchData();
  }, []);


const duplicateChart = async (chartId) => {
  try {
    const chartToDuplicate = charts.find((chart) => chart.id === chartId);

    const serializedData = JSON.stringify(chartToDuplicate.data);
    const serializedLayout = JSON.stringify(chartToDuplicate.layout);
    const serializedSource = JSON.stringify(chartToDuplicate.source);

    const payload = {
      data: serializedData,
      layout: serializedLayout,
      source: serializedSource,
    };

    const response = await fetch(`${backUrl}charts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Failed to duplicate chart');
    }

    const newChart = await response.json();

    const newChartParsed = ({
          data: JSON.parse(newChart.data),
          layout: JSON.parse(newChart.layout),
          source: JSON.parse(newChart.source),
          id: newChart.id
        });

    // Update the state by creating a new array with the existing charts and the duplicated chart
    setCharts((prevCharts) => [...prevCharts, newChartParsed]);

    // Handle the response from the backend if needed
    console.log('Duplicated chart:', newChart);
    toast.success('Your chart has been duplicated');

  } catch (error) {
    console.error('Error duplicating chart:', error);
    toast.error('Something went wrong');
  }
};


const deleteChart = async (chartId) => {
  try {
    const token = localStorage.getItem('token');

    const response = await fetch(`${backUrl}/charts/${chartId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete chart');
    }

    // Filter out the deleted chart from the existing array
    const updatedCharts = charts.filter((chart) => chart.id !== chartId);

    // Update the state with the new array without the deleted chart
    setCharts(updatedCharts);

    console.log('Chart deleted successfully');
    toast.success('Chart successfully deleted');

  } catch (error) {
    console.error('Error deleting chart:', error.message);
    toast.error('Something went wrong');
    // Handle error as needed
  }
};







  return (
    <div>
      <div className="searchTopBarWrapper">
        <SubNav section='explorer' current='mycharts'/>

          <div className="searchInputWrapper searchBox">
            <h2>Run Search</h2>
            <div className="innerSIWrapper">
              <SearchInput
                 type="search"
              />
              <button onClick={() => this.clear()}>Clear</button>
            </div>
          </div>
        </div>

        <div className="main-div-wrapper">
          <div className="maintop">
            <h1>My Charts</h1>
          </div>

          <div className="viz-page">

            {error ? (
              <p>Error: {error}</p>
            ) : (
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {charts.map((chart) => (
                  <MyChartCard id={chart.id} data={chart.data} layout={chart.layout} deleteChart={deleteChart} duplicateChart={duplicateChart}/>
                ))}
              </div>
            )}

          </div>
        </div>

        <ToastContainer 
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={'light'}
        />

      </div>
  );
};

export default Charts;

