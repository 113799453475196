import React from "react";
import { formatDateForInput } from '../../utils/date';

const Input = props => {
	var { style,
		    isLast } = props;
	var styleType;
	var multipart = {
		borderBottom: "none"
	};

	style == "multipart" && isLast ? null : styleType = multipart

  const value = props.type === 'date'
    ? formatDateForInput(props.value)
    : props.value

	return(
		<div>
		<div className="form-row" style= {styleType}>
			<label className="form-label">
				{props.label}
			</label>
			<div className="form-field" >
				<input id={props.id} name={props.name} value={value} onChange={props.onChange} type={props.type}/>
				<small className="hide">tip/subhead</small>	
			</div>
		</div>
		</div>
	);
}

export default Input;
