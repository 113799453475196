import React from "react";
import MainHeader from "../../common/nav/MainHeader";
import { Link } from "react-router-dom";


const ProjectsMain = () => (
<div>
<br/>
<Link to="/projects/new/0"><button class="btn">Create a new Project</button></Link>
<Link to="/template/edit/0"><button class="btn">Edit your Project Template</button></Link>

<div style={{display: "flex"}}>
<div>
</div>
<div>
</div>
</div>
</div>
)

export default ProjectsMain;
// <MainHeader header="Projects" crumb={["Home", "Projects"]} />
