import React from 'react';
import styled from 'styled-components';

var BasicTable = styled.table`
  background: #5B7553;
  border-collapse: collapse;
  border-spacing: 0;
  /* table-layout: auto; */
  margin: 20px 5px;

  min-width: 500px;
  border: 2px solid #333745;
  thead {
    background: #5B7553;
    color: #FFF;
    font-weight: normal;
  }

  tbody{
    border-collapse: collapse;
  }
  tbody tr:nth-child(even){
    background: #7F9C76;
  }
  tbody tr:nth-child(odd){
    background: #3D4F37;
  }
  tbody tr:nth-child(odd) td a, tbody tr:nth-child(odd) span, tbody tr:nth-child(odd) span a, tbody tr:nth-child(odd) td{
    color: #fff;
  }
  tbody tr:nth-child(odd) td a:hover, tbody tr:nth-child(odd) span:hover, tbody tr:nth-child(odd) span a:hover, tbody tr:nth-child(odd) td:hover{
    color: #C9BCA6;
  }
  tbody td {
        /* border-top: 1px solid #DEE2E6; */
        /* border-bottom: 1px solid #DEE2E6; */
        padding: 10px 10px 10px 12px;
        font-family: 'JetBrains Mono';
  }
  @media ( max-width: 1024px ) {
    flex: 1;
  }
`

export default BasicTable;
