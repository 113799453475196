import React from "react";

const RadioGroup = props => {
	var {radioButtons, 
	     onChange, 
	     title, 
	     name, value } = props;

	return(
		<div>
			<fieldset style={{paddingLeft: "3vw", borderRight: "none", borderLeft: "none", borderTop: "1px solid rgba(120, 130, 140, 0.13)", borderBottom: "1px solid rgba(120, 130, 140, 0.13)"}}>
			<legend> {title} </legend>
			<radiogroup onChange = {onChange}>
				{
					radioButtons.map( button => <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", width: "20vw"}}>
						                              <label htmlFor={button.name}>
						                                  {button.title}
						                              </label>
						                              <input type="radio" checked={value === button.name} value={button.name} name={name} label={button.name} style={{width: "10vw"}}/>
						                          </div>)
				}
			</radiogroup>
			</fieldset>
		</div>
	);
}

export default RadioGroup;
