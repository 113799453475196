import React from 'react';
import {sortAs} from './Utilities';
import './Pivot.css';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import SubNav from '../../common/nav/SubNav'
import '../../assets/css/builds.css'
import { backUrl } from "../../App/constants";

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);


export default class Pivot extends React.Component {

  constructor(props) {
    super(props);
    this.state = {pivotState: props, initData: [], unAlteredData: [], filtered: true};
  }


  componentWillReceiveProps(nextProps) {
    this.setState({pivotState: nextProps});
  }

  filterTheData(data){
    var newData = [];
    data.forEach(field => {
      var newBuild = {}
      for (const [key, value] of Object.entries(field)) {
        if (value != null) {
          newBuild[key] = value
        }
      }
      newData.push(newBuild);
    })

    return newData;
  }
  //
  filterData(type){
    if (type == 'all'){
      // this.setState({pivotState: {data: this.state.unAlteredData}});
      // this.setState({filtered: false})
    } else if (type == 'filtered'){
      var filtered = this.filterTheData(this.state.unAlteredData);
      // this.setState({pivotState: {data: filtered}});
      // this.setState({filtered: true})
    }
  }



  componentDidMount(){
    fetch(backUrl + 'pivot', {
      method: 'GET',
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('token')
      }
    })
    .then(res => res.json())
    .then(data => {
      this.setState({unAlteredData: data});
      var filtered = this.filterTheData(data);
        this.setState({
            pivotState: {
                //data: filtered,
                data: data,
                rendererName: 'Grouped Column Chart',
                plotlyOptions: {
                  width:  window.innerWidth == 1024 ? '700' : '1000', 
                  height: 500
                },
                plotlyConfig: {},
                tableOptions: {
                    clickCallback: function(e, value, filters, pivotData) {
                        var names = [];
                        pivotData.forEachMatchingRecord(filters, function(
                            record
                        ) {
                            names.push(record.Meal);
                        });
                        alert(names.join('\n'));
                    },
                },
            },
        },);
    })
  }

  onChange(s) {
    this.setState({pivotState: s});
  }




  render() {

    const data = this.state.initData;

    return (
          <div className="vizpagewrapper">
            <SubNav section='explorer' current='viz'/>
            <div className="main-div-wrapper">
              <div className="maintop">
                <h1>Visualize</h1>
              </div>
              <div className="filter-bar">
                { /* this.state.filtered == true  ?
                  <button onClick={this.filterData('all')}>Show All Data Fields</button> :
                  <button onClick={this.filterData('filtered')}>Show Populated Data Fields</button>
                  */
                }

              </div>
              <div className="viz-page">

                  <div className="row">
                      <h2 className="text-center">{this.state.filename}</h2>
                      <br />

                <PivotTableUI
                   style={{display: 'block'}}
                   data={data}
                   onChange={(s) => this.onChange(s)}
                   renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                   {...this.state.pivotState}
                   unusedOrientationCutoff={Infinity}
                />

               </div>
             </div>
             </div>
          </div>


    );
  }
}
