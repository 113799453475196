import React from "react";
import Modal from 'react-modal';
import BasicTable from '../../common/basic-elements/BasicTable';
import { backUrl, docUrl, apiUrl, schema} from "../../App/constants.js";
import SectionPage from "../question_set/SectionPage";
import { Route } from "react-router-dom"

import { loadProject } from '../../common/traceapi/TraceAPI.jsx';

class ShowProject extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      project: {},
      schema: null,
      teams: [],
      showTeamModal: false,
      teamsToAdd: [],
      projectAttributes: [],
      loaded: false,
    };
  }

/*
  loadProjectInfo(){
    var projectId = this.props.match.params.id;
    console.log("in component did mount")
    
    fetch(`${backUrl}projects/${projectId}`, {
      method: "GET",
    })
      .then(res => res.json())
      .then(json => {
        console.log('in show project', json);
        //Need to fetch template info to show project
        fetch(`${backUrl}templates/${json.project.template_id}`, {
          method: "GET",
        }).then(res => res.json())
        .then(templateJson => {
          console.log("Template Schema", JSON.parse(templateJson.schema))
          this.setState({project: json, schema: JSON.parse(templateJson.schema), projectAttributes: JSON.parse(templateJson.schema).pages[0].elements.map((e) => e.name)});
        })
        

    });
  }
*/

  removeFinalReport(projectId) {
    fetch(`${backUrl}projects/${projectId}/file`, {
      method: 'DELETE',
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(x => window.location.reload())
      .catch(e => alert(e))
  }

  removeFile(event) {
    if (!window.confirm('Are you sure you want to remove this attachment?') ) { return; }
    if (event === 'final_report') {
      this.removeFinalReport(this.state.project.project.id);
      return;
    }
    const buildId = this.state.project.id;
    const field = event
    const url = backUrl + 'builds/' + buildId + '/file';

    fetch(url, {method: "DELETE",
      body: JSON.stringify({"file_type": field}),
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Accept": "application/json"
    }}
    )
      .then(a => {
        window.location.reload();
      })
      .catch(e => alert('error deleting'));
  }


        onUpload(event) {

          if (event.target.name === 'final_report') {
            this.addFinalReport(event);
            return;
          }
          const buildId = this.state.project.id;
                var value = event.target.value;
                var file = event.target.files[0];
                var data = new FormData();

                data.append('file', file);
                data.append('file_type', event.target.name);

                fetch(backUrl + 'builds/' + buildId + '/file', {
                        method: "PUT",
                        headers: {
                          "Authorization": 'Bearer ' + localStorage.getItem('token')
                        },
                        body: data
                })
                .then(a => a.json())
                .catch(e => console.log(e))
                .then(a => {
                                var cool = this.state.project;
                  cool[a.field_name] = { url: a.file_url,
                    fileName: a.file_name,
                    id: a.id};
                                this.setState({project: cool}, () => console.log('a'));
                })
                .catch(e => console.error(e));
        }


  handleProjectAttributeChange(event) {
    const target = event.target
    const name = target.name;
    const value = target.value;
    const newProject = {
      ...this.state.project.project,
      [name]: value
    }
    this.setState({
      project: {
        ...this.state.project,
        project: newProject
      }
    });
  }

  onChange(event) {
    const target = event.target
    const name = target.name;

    if (this.state.projectAttributes.includes(name)) {
      this.handleProjectAttributeChange(event);
      return;
    }

    if (name === 'non_destructive') {
      this.handleNonDestructive(event.target.value);
      return;
    }
    if (name === 'destructive') {
      this.handleDestructive(event.target.value);
      return;
    }


    var value = target.value;
    if (target.type == "checkbox") { value = target.checked }

    let project = this.state.project;
    project[name] = value;


    this.setState({
      project: project
    }, () => { console.log('e', this.state)});

  }

  handleNonDestructive(value) {
    const buildId = this.state.project.id;
    fetch(`${backUrl}/builds/${buildId}/tests`, {
      method: 'POST',
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify({
        test_type: value
      }),
      headers: {
  "Content-Type": "application/json",
  "Accept": "application/json"
      }
    }).then(x => x.json())
      .then(j => {
        const newTest = {
          id: j.id,
          attachment_url: null,
          test_type: value
        };

        const newProject = {
          ...this.state.project,
          tests: [...this.state.project.tests, newTest]
        };

        this.setState({project: newProject});
      })
      .catch(e => console.error(e));
  }

  handleDestructive(value) {
    const buildId = this.state.project.id;
    fetch(`${backUrl}/builds/${buildId}/parts`, {
      method: 'POST',
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('token')
      }, 
      body: JSON.stringify({
        test_type: value
      }),
      headers: {
  "Content-Type": "application/json",
  "Authorization": 'Bearer ' + localStorage.getItem('token'),
  "Accept": "application/json"
      }
    })
  }

  saveNestedProject(projecta) {

    const projectId = projecta.project.id;
    
    const { project } = projecta;
    const ok = JSON.stringify({
      name: project.name,
      lead_investigator: project.lead_investigator,
      lead_investigator_organization: project.lead_investigator_organization,
      completion_date: project.completion_date,
      itar_restriction: project.itar_restriction,
      pcd_revision: project.pcd_revision,
      cognizant_engineering_organization: project.cognizant_engineering_organization,
      project_lead: project.project_lead,
      material_producer: project.material_producer,
      parts_producer: project.parts_producer,
      heat_treater: project.heat_treater,
      support_removal_provider: project.support_removal_provider,
      testing_provider: project.testing_provider,
      partners: project.partners
    })

    fetch(`${backUrl}/projects/${projectId}`, {
      method: 'PUT',
      body: ok,
      headers: {
  "Content-Type": "application/json",
  "Authorization": 'Bearer ' + localStorage.getItem('token'),
  "Accept": "application/json"
      }
    })
  }

  // updateProject
  async saveProject(sectionNumber) {
    if (sectionNumber === 0) {
      this.saveNestedProject(this.state.project);
      return;
    }

    var project = this.state.project
    var formData = new FormData();

    // extract this
                {
                        for (let key in this.state.project) {
                                key ? formData.append(key, this.state.project[key]) : null
                        }
                }

    var build = this.state.project.id;
    var url = `${backUrl}/project/${build}`
    fetch(url, {
      method: "PUT",
      body: formData,
      headers: new Headers({
        'Authorization': `Bearer ${this.state.token}`
      })
    })
      .then(res => {
        return res.json();
      })
      .then( res => {
      })
  }

  removeReport(e) {
    alert(e);
  }

  addReport(event) {
    const fileId = event.target.name
                var file = event.target.files[0];
                var data = new FormData();

                data.append('file', file);

                fetch(backUrl + 'tests/' + fileId + '/file', {
                        method: "PUT",
                        headers: {
                          "Authorization": 'Bearer ' + localStorage.getItem('token')
                        },
                        body: data
                })
                .then(a => a.json())
                .then(v => window.location.reload())
                .catch(e => alert(e));
  }

  addFinalReport(event) {
    const projectId = this.state.project.project.id;
    const file = event.target.files[0];
    let data = new FormData();
    data.append('file', file);
    fetch(backUrl + 'projects/' + projectId + '/file', {
      method: 'PUT',
      headers:{
        "Authorization": 'Bearer ' + localStorage.getItem('token')
      },
      body: data
    })
      .then(x => window.location.reload())
      .catch(e => alert(e));
  }

  render() {
    const { project, schema, projectAttributes, projectError } = this.props;

    if(projectError === false && this.state.loaded === false && schema.length != 0){
      
      this.setState({project: project, schema: schema, loaded: true, projectAttributes: projectAttributes})
      return <div>Loading Projects</div>
    }

    var names = [];
    var elements = [];


    //Safeguard to prevent loading blank projects
    if(this.state.schema == null) {
      console.log("Schema did not load")
      return <div></div>
    } 

    //todo: must be a cleaner way to wait until fetches are loaded
    if (this.state.schema && this.state.schema.pages) { 
      var pages = [
        {
          name: "Project Info",
          elements: this.state.schema.pages[0].elements
        },
        {
          name: "Active Builds",
          elements: [],
          emptyType: 'active'
        },
        {
          name: "Complete Builds",
          elements: [],
          emptyType: 'complete'
        },
        {
          name: "Export Project",
          elements: [],
          emptyType: 'export'
        }
      ];
      names = pages.map(page => page.name);
      elements = this.state.schema.pages.map(page => page.elements);
    }

    return(
      <div>

      { this.state.loading && <h1> LOADING... </h1> }
      { pages &&  pages.map( (e, index) =>
          <Route path = {`/project/:id/${index}`}
                  key={e.name}
                 render = { () =>
                     <SectionPage title={e.name}
                                  isEdit = {true }
                                  type="project-page"
                                  project = {this.state.project}
                                  data={e.elements}
                                  onChange={(event) => this.onChange(event)}
                                  onUpload={(event) => this.onUpload(event)}
                                  save={() => this.saveProject(index)}
                                  titles = { names }
                                  index = {index}
                                  numPages = {pages.length}
                                  pages={pages}
                                  addReport={(e) => this.addReport(e)}
                                  removeReport={(e) => this.removeReport(e)}
                                  removeFile={event => this.removeFile(event)}
                                  emptyType={e.emptyType}
                      />
                 }
          />
      )  }
      </div>
    );
  }
}

export default loadProject(ShowProject); 
