import React from "react";
import { backUrl, projectIdentifier} from "../../App/constants";
import MainHeader from "../../common/nav/MainHeader";
import { Link } from "react-router-dom";
import { RemoveIcon } from '../../common/Icons';
import BasicTable from '../../common/basic-elements/BasicTable';
import SubNav from '../../common/nav/SubNav.jsx'
import SearchBox from '../search/SearchBox';
import '../search/styles/SearchPage.css';
import { fetchProjects } from '../../common/traceapi/TraceAPI.jsx'

var iconStyle = {
        height: '1em',
        marginLeft: '.4em',
        marginRight: '.3em',
        cursor: 'pointer'
};

var selectedCell = {
	background: 'lightsteelblue'
};

const SortCell = props =>
        <td style={ props.sortStatus.type == props.type ? selectedCell : null} onClick= { props.onClick }>
                <div style={{cursor: 'pointer'}}>
                {props.title}

{props.sortStatus.type != props.type && <i  className = "fa fa-sort"
            style={iconStyle}>
        </i> }
{props.sortStatus.type == [props.type] && props.sortStatus.sort =='a'  && <i className="fa fa-caret-up" style={iconStyle}i></i> }
{props.sortStatus.type == [props.type] && props.sortStatus.sort =='d'  && <i className="fa fa-caret-down" style={iconStyle}i></i> }

{false &&   <i  className = "fa fa-filter"
            style={{height:'1em'}}>
        </i> }
</div>
        </td>


const FilterButton = props =>
        <button style={{width: '80px', marginBottom: '1em'}}onClick={ props.onClick }>
                add filter
        </button>

const FilterTypeSelect = props =>
  <div style={{border: '1px solid red', position: 'absolute', zIndex: 400, height: '200px', width: '600px', background: 'white'}}>
                Property to Filter:
                { props.filters.map(filter =>
                <div>
                        {filter}
                </div>)}
  </div>

const FilterOptionsSelect = props =>
        <div>
                <div id="filter-options">
                </div>
                <div>
                Apply
                </div>
                </div>

const FilterPopUp = props =>
  <div style={{border: '1px solid red', position: 'absolute', zIndex: 400, height: '200px', width: '600px', background: 'white'}}>
                Filterpopup
                {props.show == 'type' && <FilterTypeSelect filters={props.filters}/>}
                {props.show == 'option' && <FilterOptionsSelect options={props.options} /> }

  </div>

class ShowProjects extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			data: [],
      active: [],
      //token: JSON.parse(localStorage["appState"]).user.auth_token,
      sortStatus: {type: null, sort: null},
      filterShown: false,
      projects: [],
      loaded: false
		}
	}

  exportXLSX = () => {
    var data = {
      "Project Title": 'proc_title',
      "Feedstock": 'feedstock',
      'Batch Number': 'batch_num',
      'Machine ID': 'mach_id',
      'Machine Model': 'model' };
    var headers = Object.keys(data);
    var rows = this.state.data.map( project => {
      let values = Object.values(data);
      return values.map( value => project[value]);
    });

    var sheet = [
      headers,
      ...rows
    ];
    var fileName = `trace_${this.props.type}_projects`;

    this.props.xlsx(sheet, fileName);
  }

  launchFilterSelect() {
    this.setState({ filterShown: 'type'});
  }

  toggleSort(z) {
     // check the sortStatus state var && see what is up.
     // else
     var {sortStatus} = this.state;
     if ( sortStatus.type == z ) {
             if ( sortStatus.sort == 'a' ) {
     var sortStatus = {
             type: z,
             sort: 'd'
     };

          var cool = projects.sort((a,b) => {
          var ok;
                  var okok;
                  a[z] ? ok = '' + a[z] : ok = ''
                  b[z] ? okok = ''+ b[z] : okok = ''
          return okok.localeCompare(ok)
          });
          this.setState({projects: cool, sortStatus: sortStatus});

             } else {
     var sortStatus = {
             type: z,
             sort: 'a'
     };

          var cool = this.state.projects.sort((a,b) => {
          var ok;
                  var okok;
                  a[z] ? ok = ''+a[z] : ok = ''
                  b[z] ? okok = ''+b[z] : okok = ''
          return ok.localeCompare(okok)
          });
          this.setState({projects: cool, sortStatus: sortStatus});

             }
     } else {
     var sortStatus = {
             type: z,
             sort: 'a'
     };

          var cool = this.state.projects.sort((a,b) => {
          var ok;
                  var okok;
                  a[z] ? ok = ''+a[z] : ok = ''
                  b[z] ? okok = ''+b[z] : okok = ''
          return ok.localeCompare(okok)
          });
          this.setState({projects: cool, sortStatus: sortStatus});
     }
  }

	reallyDelete(id) {
    if (window.confirm('Are you sure you want to delete this project?')) {
      fetch(backUrl + 'projects/' + id, {
        method: 'DELETE',
        headers: {
          "Authorization": 'Bearer ' + localStorage.getItem('token')
        }
      })
        .then(r => {
          const newProjects = this.state.projects.filter(p =>
            p.id !== id
          );
          this.setState({projects: newProjects});
        })
        .catch(e => console.error(e));
    } else {
      return;
    }
	}


	render() {
    const {projects, projectsError} = this.props;
    if(projectsError === false && this.state.loaded === false){
      
      this.setState({projects: projects, loaded: true})
      return <div>Loading Projects</div>
    }

		let data = this.state.data;
    var show = this.props.type === 'active' ? this.state.active : this.state.data
		let header = "Complete Builds";
		var link = "/complete_build/";
		if (this.props.type === "active") {
			header = "Active Builds";
			link = "/build/";
		}
		let crumb = ["Home", "Builds", header];
			return(

				<div style={{display: 'flex', flexDirection: 'column', padding: 10}}>
          <div className="searchTopBarWrapper">
            <SubNav section='projects' current='all-projects'/>
            <SearchBox />
          </div>
          { false && <FilterButton onClick={() => this.launchFilterSelect()}/> }
          { this.state.filterShown && <FilterPopUp show={this.state.filterShown} filters={['Feedstock', 'Machine Model']} options={[1,2,3]}/> }
						<BasicTable>
						<thead>
          <SortCell title="ID" type={'id'} sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('id')}></SortCell>
          { false && <td>Feedstock</td> }
          <SortCell title="Project Name" type="project_name" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('name')}></SortCell>
          <SortCell title="Designated Project Lead" type="project_lead" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('lead_investigator')}></SortCell>
          <SortCell title="Engineering Organization" type="cognizant_engineering_organization" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('lead_investigator_organization')}></SortCell>
          <SortCell title="Material Producer" type="material_producer" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('partners')}></SortCell>
          <SortCell title="Testing Provider" type="testing_provider" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('completion_date')}></SortCell>
					<td></td>
				</thead>
				<tbody>


					{ this.state.loaded && this.state.projects && 
           this.state.projects.map((datum, pos) =>
						<tr>
							<td>
								<Link to={`/project/${datum.id}/0`} >{datum.id}</Link>
							</td>

							<td>
								<Link to={`/project/${datum.id}/0`} >{datum && datum.name}</Link>
							</td>

							<td>
								<Link to={`/project/${datum.id}/0`} >{datum && datum.project_lead}</Link>
							</td>

							<td>
								<Link to={`/project/${datum.id}/0`} >{datum && datum.cognizant_engineering_organization}</Link>
							</td>

							<td>
								<Link to={`/project/${datum.id}/0`} >{datum && datum.material_producer}</Link>
							</td>

							<td>
								<Link to={`/project/${datum.id}/0`} >{datum && datum.testing_provider}</Link>
							</td>

              <td style={{paddingRight: '10px'}}>
                <RemoveIcon onClick={ id => this.reallyDelete(datum.id)}/>
              </td>
						</tr>
          )}

				</tbody>
					</BasicTable>
				</div>
		);
	}
}

export default fetchProjects(ShowProjects);
