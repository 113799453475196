export function formatDateForInput(dateString) {
  if (!dateString) { return ''; }
  const dateX = new Date(dateString);
  const date = new Date(dateX.getTime() + 60000000)

  let month   = '' + (date.getMonth() + 1);
  let day     = '' + date.getDate();
  const year  = date.getFullYear();

  if (month.length < 2) {
      month = '0' + month;
  }
  if (day.length < 2) {
      day = '0' + day;
  }

  return [year, month, day].join('-');
}
