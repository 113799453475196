/**
 * Login page card 
 * 
 *  Contains login box with email, password, button, forgot password link
 *
 * Usage:
 * <LoginCard/>
 *
 * @returns header div
 */



/************* React imports *****************/
import React, { useRef, useState } from 'react';
import { Link, useHistory, Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';


/************* Shared app imports *****************/
import { Auth, useAuth } from '../../../common/auth';

/************* javascript imports *****************/
import {assembleEmailAddress} from '../../../utils/emailgen';

/************* style imports *****************/
import 'react-toastify/dist/ReactToastify.css';

function LoginCard() {

  const { login } = useAuth();

  const history = useHistory();

  // track state of user/pass inputs
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState(false);

  // track 'forgot password' modal
  const [showResetModal, setShowResetModal] = useState(false);

  // toast message for errors
  const [showToast, setShowToast] = useState(false);

  var toastMessage = '';

  // lots of potential error message cases - centralizing 
  // in this method will make maintenance easier through DRY
  // so styling, placement, fade time, etc. will be consistent
  // TODO: Fix to use common component src/common/ErrorMessage/

  const handleLogin = async () => {

    const error = (message) => {
      toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
      width: "max-content"
      });
    }


    // Access the login function of the Auth component using the ref
    if (useAuth) {
      try {
        const response = await login(username, password);
        if (response.success) {
          //console.log("Successful login!");
        } else {
          error(response.message);
          //console.log("Failed login");
        }
      } catch (err) {
        error(err);
        //console.error('Error:', error);
      }
    } else {
      error("Something went wrong here");
    }
  };


  const openResetModal = () => {
    setShowResetModal(true);
  };

  const closeResetModal = () => {
    setShowResetModal(false);
  };


  return (
    <div className="login-box" data-cy="login-card">
      <div className="login-box-form">
        <h2 className='login-header'>Login to TRACE<i>AM</i></h2>

        <input id="user-email" type="text" value={username} 
               onChange={(e) => setUsername(e.target.value)} 
               placeholder="Email" className="login-input"
               data-cy="username" />
        <input id="user-password" type="password" value={password}
               onChange={(e) => setPassword(e.target.value)}
               placeholder="Password" className="login-input"
               data-cy="password" />

        <div className="input-actions">
            <button id="qsLoginBtn" className="login-submit" 
            onClick={handleLogin} data-cy="login-button">Login</button>
            <a className="forgot-password" onClick={openResetModal} data-cy="forgot-password-link">Forgot Password?</a>
        </div>
      </div>

      <ToastContainer />


    {/* show modal or not */} 
    {showResetModal && (
      <div className="resetmodal">
        <div className="resetmodal-card">
          <span className="closeresetmodal">&times;</span>
          <h2>Password Reset</h2>
          <p>For a password reset, please contact {assembleEmailAddress()}</p>
          <button onClick={closeResetModal} className="closeResetModalButton">Close</button>
        </div>
      </div>
    )}


      </div>
  );
}
export default LoginCard;
