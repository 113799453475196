import React from 'react';
import styled from 'styled-components';

var RemoveIcon = styled.i.attrs({
  className: 'fa fa-minus-circle'
})`
  color: lightgrey;
  &:hover {
    color: red;
    cursor: pointer;
  }
`

const RemoveIconComponent = props => 
  <RemoveIcon
    id = { props.id }
    onClick = { props.onClick }
  />

export default RemoveIconComponent;
