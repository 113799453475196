import React, { useState, useEffect } from 'react';
import Card from '../../../common/Card';

import styles from './styles.css';

const EventsCard = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [filterOption, setFilterOption] = useState('contains');
  const [data, setData] = useState([]);
  const [sortedBy, setSortedBy] = useState(null);

  useEffect(() => {
    // Load data from props when available
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleFilterOptionChange = (event) => {
    setFilterOption(event.target.value);
  };

  const sortData = (sortBy) => {
    if (sortBy === sortedBy) {
      // Reverse the order if already sorted by this column
      setData(data.slice().reverse());
    } else {
      setSortedBy(sortBy);
      setData(
        data.slice().sort((a, b) => {
          if (a[sortBy] < b[sortBy]) {
            return -1;
          } else if (a[sortBy] > b[sortBy]) {
            return 1;
          } else {
            return 0;
          }
        })
      );
    }
  };

  const preprocessData = () => {
    return data.map((item) => {
      const otherInfo = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          if (
            key !== 'created' &&
            key !== 'name' &&
            key !== 'type' &&
            key !== 'id' &&
            key !== 'inserted' &&
            key !== 'machineId' &&
            key !== 'subType'
          ) {
            otherInfo[key] = item[key];
          }
        }
      }
      return { ...item, otherInfo };
    });
  };

  const filteredData = () => {
    if (!props.data.length || props.data.length < 1) {
      return data;
    }

    const lowercasedSearchValue = searchValue.toLowerCase();

    return preprocessData().filter((item) => {
      // Check against all properties in the item
      var inRecord = false;
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          const propertyValue =
            (item[key] && item[key].toString().toLowerCase()) || '';
          const includesSearchValue =
            propertyValue && propertyValue.includes(lowercasedSearchValue);

          if (filterOption === 'contains' && includesSearchValue) {
            return true;
          }

          if (includesSearchValue) {
            inRecord = true;
          }
        }
      }
      if (filterOption === 'doesNotContain' && !inRecord) {
        return true;
      } else {
        return false;
      }
    });
  };

  var events = [];
  if (props && props.data && props.data.length > 0) {
    events = props.data;
  }

  if (!props.data || props.data.length < 1) {
    // Display loading indicator or message
    return (
      <div className="eventsCard">
        <h2 className="card-title">Build Events List</h2>
        <p />
        <div>
          <p>Loading data...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="eventsCard">
      <h2 className="card-title">Build Events List</h2>
      <p />

      <div className="eventfilterContainer">
        <div className="eventfilterBox">
          <input
            type="radio"
            id="containsOption"
            name="filterOption"
            value="contains"
            checked={filterOption === 'contains'}
            onChange={handleFilterOptionChange}
          />
          <label htmlFor="containsOption">Contains</label>

          <input
            type="radio"
            id="doesNotContainOption"
            name="filterOption"
            value="doesNotContain"
            checked={filterOption === 'doesNotContain'}
            onChange={handleFilterOptionChange}
          />
          <label htmlFor="doesNotContainOption">Does Not Contain</label>
        </div>

        <div className="eventsearchBox">
          <label htmlFor="searchInput">Filter:</label>
          <input
            type="text"
            id="searchInput"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <table className="activeProjectsTable">
        <tbody>
          <tr className="aptHeader">
            <td>
              <span onClick={() => sortData('created')}>
                Date and Time
                {sortedBy === 'created' && ' ⬆️'}
              </span>
            </td>
            <td>
              <span onClick={() => sortData('name')}>
                Name
                {sortedBy === 'name' && ' ⬆️'}
              </span>
            </td>
            <td>
              <span onClick={() => sortData('type')}>
                Type
                {sortedBy === 'type' && ' ⬆️'}
              </span>
            </td>
            <td>
              <span onClick={() => sortData('subType')}>
                SubType
                {sortedBy === 'subType' && ' ⬆️'}
              </span>
            </td>
            <td>Other Info</td>
          </tr>

          {filteredData().map((event) => (
            <tr className="aptProjects" key={event.id}>
              <td> {event.created || 'none'} </td>
              <td> {event.name || 'none'} </td>
              <td> {event.type || 'none'} </td>
              <td> {event.subType || 'none'} </td>
              <td>
                {Object.entries(event.otherInfo).map(([name, value]) => (
                  <div key={name}>
                    <strong>{name}:</strong> {value}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventsCard;

