import React from "react";

const TemplateInput = props => {
	return(
		<div className="form-row">
			<label className="form-label">
				Template to Use:
			</label>
			<div className="form-field">
				<select multiple={props.multiple} name={props.name} onChange={props.changeTemplate} value={props.value}>
					{/* <option value="" selected>Default</option> */}
					{ props.templates ? props.templates.map(a => <option value={a.id}>{a.name}</option>) : null}
				</select>
				<small className="hide">tip/subhead</small>	
			</div>
		</div>
	);
}

export default TemplateInput;
