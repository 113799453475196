import React from "react";
import MainHeader from "../../common/nav/MainHeader";
//import MaterialsJSON from "../asset/materials.json";
import upload from "../../assets/images/upload.png";
import { url } from "../../App/constants";
import {docUrl} from '../../App/constants';
import SubNav from '../../common/nav/SubNav';

const crumb = ["Home", "Materials"]
//const materials = MaterialsJSON.materials;

// Materials should be stateful
// Materials array. [m1, m2] server
// iterate over materials array and display on screen.
// ( Materials List component )

class Materials extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			show: false,
			material:
				{
					material_type:null,
					vendor:null,
					process:null,
					file:null,
					file_url:"empty"
				},
				data:[],
				token: JSON.parse(localStorage["appState"]).user.auth_token,
		};
	}
	changeState(){
		this.setState({show:true});
	}

	handleChange(e){
		var material = this.state.material;
		var {id,value} = e.target;
		material[id] = value;
		// set the state of the material object.
		this.setState({material});
	}

	handleFile(e){
		var fileIn = e.target.files[0];
		var material = this.state.material
		material["file"] = fileIn
		this.setState({material});
	}

	handleSubmit(e){
		e.preventDefault();
		var formData = new FormData();

		var currentMaterial = this.state.material;

		for (let lessFunny in currentMaterial ) {
			formData.append(lessFunny, currentMaterial[lessFunny]);
		}

		const url = docUrl + 'materials';
		fetch(url, {
			method: "POST",
			body: formData,
			headers: new Headers({
				'Authorization': `Bearer ${this.state.token}`
			})
		})
			.then(res => res.json())
			.then(json => this.updateData())
			.then(() => window.location.reload())
                        .then(() => console.log('hi there'))
                        .then(console.log('watch me go'))
	}

	updateData(){
		var newData = this.state.data;
		var newMaterial = this.state.material;
		newData.push(newMaterial);
		this.setState({data:newData})
	}

	componentWillMount(){
		this.getMaterials();
	}

	delete(id){
		var url = docUrl + 'materials/' + id;
		var formData = new FormData();
		formData.append("id", id);
		fetch(url, {
			method: "DELETE",
			body: formData,
			headers: new Headers({ "Authorization": `Bearer ${this.state.token}`})
		})
		.then(() => window.location.reload())
	}

	getMaterials(){
		const url = docUrl + 'materials';
		fetch(url, {
			method: "GET",
			headers: new Headers({
				'Authorization': `Bearer ${this.state.token}`
			})
		})
			.then(res => res.json())
			.then(json => {
				this.setState({
					data: json
				},
			);

			})
	}

	render(){
		return(
			<div>
				<SubNav section='documentation' current='materials'/>
				<button className="btn" onClick={ () => this.changeState() }>Add New</button>
				{ this.state.show && (< New handleSubmit = { (e) => this.handleSubmit(e) }
				   												 handleChange = { (e) => this.handleChange(e) }
																	 handleFile = { (e) => this.handleFile(e)}/>) }
				<br/><br/><br/>
				<MaterialsList data = {this.state.data}
					delete = { (id) => this.delete(id) }/>
			</div>
		);
	}
}

const New = props =>
			<div className = "new-doc">
				<br/>
				<form onSubmit = { props.handleSubmit}>
					<label>Type<input onChange={ props.handleChange } id="material_type"/></label>
					<label>Vendor<input onChange={ props.handleChange } id="vendor"/></label>
					<label>Process<input onChange={ props.handleChange } id="process"/></label>
					<br/><br/>
					<div className ="upload-submit-doc">
						<label className = "upload">
							Upload File...
							<input type = "file" className = "inputfile" accept=".pdf" onChange={ props.handleFile } id="file"/>
						</label>
						<input type = "submit" className ="submit-doc" />
					</div>
				</form>
		</div>

const MaterialsList = props =>
			<div>
				<table className="table-basic">
					<thead>
						<td>Type</td>
						<td>Vendor</td>
						<td>Process</td>
						<td>Documentation</td>
						<td></td>
					</thead>
					<tbody>
						{props.data.map(info =>
							<tr>
								<td>
									{info.material_type}
								</td>
								<td>
									{info.vendor}
								</td>
								<td>
									{info.process}
								</td>
								<td>
									<a href = {info.file_url} target="_blank">View</a>
								</td>
								<td onClick={(id) => props.delete(info.id)}>Delete</td>
							</tr>)}
					</tbody>
				</table>
			</div>

export default Materials


// OLD CODE IS OLD
/*
save() {
	take m3 inputs, format them, sendM3.
	sendM3 save to database. (m3 in database)
	.then( () => updateDOM(sendM3))
}
*/
{/*
const Materials = props => (
 <div>
	<div>
		<MainHeader header="Materials" crumb={ crumb } />
		<button>New</button>
		{ this.state.newVisible && < New saveMaterial = { () => this.saveMaterial()}/> }
		<Knew/>
		<Mat List/>
		      ---- m1
			------ m2
			------- m3 (IS saved on the server, but not coming from server.. coming from sendM3)
		{
			materials.map(type =>
				<div>
				<h2>{type.type}</h2>
				<div>
				{				type.materials.map(material =>
					<p>{material}</p>
				)
				}
				</div>
				</div>
			)
		}

	</div>
	</div>
)*/}
//END OLD CODE
