/**
 * Login landing page
 * 
 * The main page users hit when not logged in
 *
 * Usage:
 * <Login auth={this.authComponent.current} onLogin={() => this.checkLoginStatus()} />
 *
 * @returns login page 
 */



/************* React imports *****************/
import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";


/************* Component-specific imports *****************/
import Header from './header';
import Footer from './footer';
import InfoCard from './infocard';
import LoginCard from './logincard';

/************* style imports *****************/
import './login.css'

function Login() {
  return (
    <div className="home-wrap">
 
      <Header />

	    <div className="jumbo" >
        <InfoCard />
        <LoginCard />
      </div> 

      <Footer />

  </div>
  );
}


export default Login;




