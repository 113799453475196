import React, {useState} from 'react';
import BasicTable from '../../common/basic-elements/BasicTable';

const BrowseModal = props => {

  const [showBuilds, setShowBuilds] = useState(true);
  const [currentBuild, setCurrentBuild] = useState(null);

  function handleBuildClick(id) {
    var build = props.builds.find(b => b.id == id);
    setCurrentBuild(build);
    setShowBuilds(false);
  }

  return (
    <div className="browse-modal">
      <div class="bm-topbar">
        <h2><span>Project Name: </span> {props.project.name}</h2>
        {!showBuilds &&
          <h4 style={{textDecoration: 'underline'}}><a href={`/build/${currentBuild.id}/1`}><span>Build Id: </span>{currentBuild.build_id}</a></h4>
        }
      </div>


      { showBuilds ?
        <BasicTable>
          <thead>
            <td>Build ID</td>
            <td>Base Material</td>
            <td>Process Type</td>
            <td>Machine Manufacturer</td>
          </thead>
          <tbody>
            {
              props.builds.map(b =>
                <tr>
                  <td style={{textDecoration: 'underline'}}> <a href={`/build/${b.id}/1`}> {b.build_id ? b.build_id : 'None'} </a></td>
                  <td>{b.feedstock_base_material ? b.feedstock_base_material : 'None'}</td>
                  <td>{b.process_type ? b.process_type : 'None' }</td>
                  <td>{b.process_machine_manufacturer ? b.process_machine_manufacturer : 'None'}</td>
                  <td style={{textDecoration: 'underline'}}>
                    <span style={{cursor: 'pointer', paddingRight: 10}} onClick={() => handleBuildClick(b.id)} >
                      show parts
                    </span>
                  </td>
                </tr>
              )
            }
          </tbody>
        </BasicTable> :
        <BasicTable>
          <thead>
            <td>PartID</td>
            <td>X Location</td>
            <td>Y Location</td>
            <td>Z Location</td>
            <td>Test</td>
          </thead>
          <tbody>
            { currentBuild.parts.map(p =>
            <tr>
              <td style={{textDecoration: 'underline'}}><a href={`/build/${currentBuild.id}/5`}> {p.part_id}</a></td>
              <td>{p.x_location}</td>
              <td>{p.y_location}</td>
              <td>{p.z_location}</td>
              <td style={{paddingRight: 10}}>{p.test && p.test.test_type}</td>
            </tr>
          ) }
          </tbody>
        </BasicTable>
      }
    </div>
  )
}

export default BrowseModal;

// { this.state.showBuilds.includes(b.id) &&
//     <BasicTable>
//       <thead>
//         <td>PartID</td>
//         <td>X Location</td>
//         <td>Y Location</td>
//         <td>Z Location</td>
//         <td>Test</td>
//       </thead>
//       <tbody>
//         { b.parts.map(p =>
//         <tr>
//           <td><a href={`/build/${b.id}/5`}> {p.part_id}</a></td>
//           <td>{p.x_location}</td>
//           <td>{p.y_location}</td>
//           <td>{p.z_location}</td>
//           <td>{p.test && p.test.test_type}</td>
//         </tr>
//       ) }
//       </tbody>
//     </BasicTable>
// }
