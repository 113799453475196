import React from "react";
import MainHeader from "../../common/nav/MainHeader";
import { Link } from "react-router-dom";
import { backUrl } from '../../App/constants';
import { RemoveIcon } from '../../common/Icons';
import Modal from '../../common/basic-elements/Modal';


class MaterialsIndex extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      show: false,
      showDeleteModal: false,
      materialToDelete: null,
      typeData: props.typeData,
        data:[],
        token: JSON.parse(localStorage["appState"]).user.auth_token
    };
  }
  componentDidMount() {
    //console.log(this.props.match.params.id);
  }

  changeState(){
    this.setState({show:true});
  }

  handleChange(e){
    var cool = this.state.typeData
    var {id,value} = e.target;
    cool[id] = value;
    // set the state of the material object.
    this.setState({typeData: cool});
  }

  handleFile(e){
    var fileIn = e.target.files[0];
    var typeData = this.state.typeData
    typeData["file"] = fileIn
    this.setState({typeData});
  }

  handleSubmit(e){
    e.preventDefault();
    var formData = new FormData();

    var current = this.state.typeData;

    for (let lessFunny in current ) {
      formData.append(lessFunny, current[lessFunny]);
    }



    const url = backUrl + this.props.type;
    fetch(url, {
      method: "POST",
      body: formData,
      headers: new Headers({
        'Authorization': `Bearer ${this.state.token}`
      })
    })
      .then(res => res.json())
      .then( json => this.updateData())
                        .then(() => window.location.reload())
      //.then(this.componentWillMount())
  }

  updateData(){
    var newData = this.state.data;
    var newEquipment = this.state.typeData;
    newData.push(newEquipment);
    this.setState({data:newData})
  }

  componentWillMount(){
    this.getEquipment();
  }


  launchDeleteModal(id) {
    console.log(id);
    this.setState({showDeleteModal: true, materialToDelete: id});
  }

  deleteMaterial(){
    var id = this.state.materialToDelete;
    var url = `${backUrl}api/materials/${id}`;
    fetch(url, {
      method: "DELETE",
      headers: new Headers({ "Authorization": `Bearer ${this.state.token}`})
    })
    .then(a => a.json())
    .then(a => this.removeMaterialFromList(a.id));
  }

  removeMaterialFromList(id) {
    var newMaterials = this.state.data.filter(datum => datum.id != id);
    this.setState({data: newMaterials, showDeleteModal: false});
  }

  getEquipment(){
    const url = backUrl + 'api/materials'
    fetch(url, {
      method: "GET",
      headers: new Headers({
        'Authorization': `Bearer ${this.state.token}`
      })
    })
      .then(res => res.json())
      .then(json => {
        console.log(json);
        this.setState({
          data: json
        },
      );

      })
  }

  render(){
    return(
      <div>
        <Modal isOpen={this.state.showDeleteModal}
               onClose={() => this.setState({showDeleteModal: false})}>
          <div>
          <p>Are you sure you want to delete this material?</p>
          <button onClick={() => this.deleteMaterial()}class='btn'>Yes</button>
          <button onClick={() => this.setState({showDeleteModal: false})}class='btn'>No</button>
          </div>
        </Modal>
        <MainHeader header='All Materials' crumb={ ['Home', 'Materials Tracking'] }>
          <div>
        <button className="btn">
            <Link style={{color: 'white'}}to="/materials/new">
                New Blend
            </Link>
				</button>
          </div>
        </MainHeader>
        { this.state.show && (< New handleSubmit = { (e) => this.handleSubmit(e) }
                                   handleChange = { (e) => this.handleChange(e) }
				typeData = {this.props.typeData}
                                   handleFile = { (e) => this.handleFile(e)}
                                   />) }
        <EquipmentList data = {this.state.data}
          launchDeleteModal = {(id) => this.launchDeleteModal(id)} />
      </div>
    );
  }
}

// use a map -- how to do styling??
const New = props =>
      <div className = "new-doc">
        <br/>
        <form onSubmit = { props.handleSubmit}>
		{	 false && Object.keys(props.typeData).map( label => 
		 <label>
		   {label}
		   <input onChange= {props.handleChange}/>
		 </label>
	 ) }
          <br/><br/>
          <div className ="upload-submit-doc">
            <label className = "upload">
              Upload File...
              <input type = "file" className = "inputfile" accept=".pdf" onChange={ props.handleFile } id="file"/>
            </label>
            <input type = "submit" className ="submit-doc" />
          </div>
        </form>
    </div>

function catchNulls(str) {
  return str != 'null' ? str : '';
}

const EquipmentList = props =>
      <div>
        <table className="table-basic">
          <thead>
            <td>Name</td>
            <td>Date Created</td>
            <td>Material Type</td>
            <td>Amount</td>
            <td></td>
          </thead>
          <tbody>
            {props.data.map(info =>
              <tr>
                <td>
                  <Link to={'/materials/' + info.id}>{catchNulls(info.name)}</Link>
                </td>
                <td>
                  <Link to={'/materials/' + info.id}>{catchNulls(info.date_created)}</Link>
                </td>
                <td>
                  <Link to={'/materials/' + info.id}>{catchNulls(info.material_type)}</Link>
                </td>
                <td>
                  <Link to={'/materials/' + info.id}>{catchNulls(info.total_amount)}</Link>
                </td>
                <td>
                  <RemoveIcon onClick={(id) => props.launchDeleteModal(info.id)}/>  
                </td>
              </tr>)}
          </tbody>
        </table>
      </div>

export default MaterialsIndex;
