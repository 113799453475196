import React from "react";
import Card from './Card';
import { Link } from "react-router-dom";


export function SearchResultTestCard(props: any) {
  var buildlink = '/build/' + props.data.id + '/0'
  return(

      <Card title='Tests' buildLink={buildlink}>
        <div className="contentTitle">
          <p className="contentLabel">Test:</p>
          <h3>
		        <a href={buildlink} target='_blank'>
              {props.data._source.test_type}
            </a>
          </h3>
          <h4>
          </h4>
        </div>

        <div className="inner-content-wrapper">
          <p className="contentLabel">Tesnile Elongation:</p>
          <h4>{props.data._source.tensile_elongation}</h4>
          <p className="contentLabel">Yield Strength:</p>
          <h4>{props.data._source.tensile_yield_strength}</h4>
          <p className="contentLabel">Logged At:</p>
          <h4>{props.data._source.created_at}</h4>
        </div>

      </Card>

  )}


export default SearchResultTestCard;
