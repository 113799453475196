import React from "react";
import Card from './Card';
import { Link } from "react-router-dom";


export function SearchResultMaterialCard(props: any) {
  var buildlink = '/build/' + props.data.id + '/0'
  return(

      <Card title='Material' buildLink={buildlink}>
        <div style={{ display: 'block' }}>
          <h3>
		        <a href={buildlink} target='_blank'>
              {props.data._source.material_type}
            </a>
          </h3>
        </div>

        <div className="inner-content-wrapper">
          <p className="contentLabel">Process:</p>
          <h4>{props.data._source.process}</h4>
          <p className="contentLabel">Vendor:</p>
          <h4>{props.data._source.vendor}</h4>
          <p className="contentLabel">Partners:</p>
          <h4>{props.data._source.partners}</h4>
          <p className="contentLabel">Updated At:</p>
          <h4>{props.data._source.updated_at}</h4>
        </div>

      </Card>

  )}


export default SearchResultMaterialCard;
