import {backUrl} from './constants';

var jwtAuthToken = '';
if ( localStorage['appState']) {
  jwtAuthToken = JSON.parse(localStorage["appState"]).user.auth_token;
}

var back = backUrl + 'api/';

var Material = {
	create: async function createMaterial(material) {
		return await create('materials', material);
	},
  delete: async function deleteMaterial(id) {
    console.log('we dlll');
    return await deleteFunction('materials', id);
  }
}

async function create (route, object) {
	var response = await fetch(back + route, {
		method: 'POST',
		headers: {
			'Authorization': 'Bearer ' + jwtAuthToken
		},
		body: JSON.stringify(object)
	}); 
  return response.json();
}

async function deleteFunction (route, id) {
	var response = await fetch(`${back}${route}/${id}`, {
		method: 'DELETE',
		headers: {
			'Authorization': 'Bearer ' + jwtAuthToken
		}
	}); 
  return response.json();
}

export { Material };
