import React from "react";
import InputAdapter from "../../common/basic-elements/InputAdapter";
import { Link, useHistory } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import MainHeader from "../../common/nav/MainHeader";
import styled from 'styled-components';
import { RemoveIcon } from '../../common/Icons';
import SubNav from '../../common/nav/SubNav';
import '../../assets/css/Main.css';
import SearchBox from '../search/SearchBox';
import '../search/styles/SearchPage.css';
import Show from "../projects/Show";
import {backUrl} from '../../App/constants';
import {saveAs} from 'file-saver';
import { useEffect } from "react";
import { Auth, useAuth } from '../../common/auth';

import RenishawBuild from './RenishawBuild';

var style = {
  display: 'flex',
  borderBottom: '1px solid lightgrey',
  paddingBottom: '10px',
  paddingTop: '10px'
};

const EditRow = props =>
<div className="template-input-wrapper">
      <div style={{display: 'flex', alignItems: 'center', width: '20px'}}>
        <RemoveIcon id={props.index} onClick={props.removeQuestion}/>
      </div>
        <div className="form-field">
            <input style={{width: '300px'}}id={props.index} name="title" onChange={props.onChange} value= { props.title }/>
        </div>
        <div className="form-field">
            <input style={{width: '300px'}}id={props.index} name="name" onChange={props.onChange} value= { props.name }/>
        </div>
        <div className="dropdown-field">
          <select id={props.index} value={props.type} onChange={props.changeType}>
          <option value='text'>text</option>
          <option value='comment'>paragraph</option>
          <option value='date'>date</option>
          <option value='dropdown'>multiple choice</option>
          <option value='checkbox'>checkbox</option>
          <option value='file'>file</option>
          <option value='parts'>parts questions</option>
        </select>
        </div>
        <div style={{marginLeft: '20px'}} id={props.index} onClick={(props.type == 'dropdown' || props.type == 'checkbox') ? props.openOptionsModal : null}>
          {(props.type == 'dropdown' || props.type == 'checkbox' ) ? <button id={props.index} className="edit-button"><i id={props.index}className='fa fa-pencil' />Edit Options</button> : null}
        </div>
</div>

const SectionPage = props => {
  var route;

  const history = useHistory();

  if (props.type == "active") {
    var build = props.project.build
    route = build ? `/build/${build.id}` : `/build/${props.project.id}`;
  } else if (props.type == "project-page") {
    var proj = props.project.project
    route = proj ? `/project/${proj.id}` : `/project/${props.project.id}`
  } else if (props.type == "template"){
    route = `/template/edit/${props.id}`
  } else {
    route = "/projects/new";
  }

  function markComplete(complete){

    var formData = new FormData()
    formData.append("is_complete", complete)
    props.project.is_complete = complete;
    
    fetch(`${backUrl}builds/${props.project.id}`, {method: "PUT", body: formData})
    .then(()=>props.reloadSection())
  }
  //split out parts table to another [] so that we can put them in another

  var inputs = [];
  var partsFields = [];


  if (props.data){
    props.data.forEach(field => {
      if (field.type == 'DEST' || field.type == 'DEST2' || field.type == 'PART_PARAMS' || field.type === 'parts'){
        partsFields.push(field)
      } else {
        inputs.push(field)
      }
    })
  }


  function getExportLink() {
    fetch(backUrl + 'projects/' + props.project.project.id + '/export')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the response as JSON
      })
      .then((data) => {
        // Extract the URL from the response JSON
        const downloadUrl = data.url;

        // Use the download URL to fetch the file data
        return fetch(downloadUrl);
      })
      .then((fileResponse) => {
        if (!fileResponse.ok) {
          throw new Error('File response was not ok');
        }
        return fileResponse.blob(); // Parse the file response as a Blob
      })
      .then((blob) => {
        // Create a Blob and download it using saveAs
        const fileName = `${props.project.project.name}_data.zip`;
        const file = new Blob([blob], { type: 'application/zip' });
        saveAs(file, fileName);
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
      });
  }


  const addons = JSON.parse(localStorage.getItem('addons'));

  const url = window.location.toString();
  var buildId = 0;
  const match = url.match(/\/build\/(\d+)\//);
  if (match) {
    buildId = match[1];
  } else {
    console.error('Warning: No BuildID found');
  }



  return(


    <div>
      <div className="searchTopBarWrapper">
        <SubNav section='projects' current={props.type === 'template' ? 'templates' : 'new-build'}/>
        <SearchBox />
      </div>
      <div className="main-div-wrapper">
      <div className="maintop">
        { props.type === 'template' &&  <h1>Edit Template</h1> }
        { props.type === 'project-page' &&  <h1>Project Page</h1> }
        { (props.type != 'project-page' && props.type != 'template' && props.title == 'Post-Process') &&  
          <div className="bottomnav" style={{display: "flex", marginLeft: "20px", marginRight:"20px", width:"100%", justifyContent:'space-between'}}>
            <h1 style={{justifySelf:"left", width:"max-content", display:"inline-block", verticalAlign:"baseline", margin:"12px",padding: "8px 20px", float:"left"}}>Edit Build</h1>



        {addons && addons.length>0 && addons.map(item => item.shortname).includes('ht') && (
          <button onClick={() => alert('This feature is not fully implemented yet.')}>Publish to HyperThought</button>
        )}

            <Link to={`/project/${props.project.project_id}/1`}>Back to Project Page</Link>
          </div>
        }
        { (props.type != 'project-page' && props.type != 'template' && props.title != 'Post-Process') &&  
          <div className="bottomnav" style={{display: "flex", marginLeft: "20px", marginRight:"20px", width:"100%", justifyContent:'space-between'}}>
            <h1 style={{justifySelf:"left", width:"max-content", display:"inline-block", verticalAlign:"baseline", margin:"12px",padding: "8px 20px", float:"left"}}>Edit Build</h1>

            <Link to={`/project/${props.project.project_id}/1`}>Back to Project Page</Link>
          </div>
        }


        {
          props.type === 'template' &&
          <div style={{display: 'flex', alignItems: 'center',  marginRight: 50, width: '50%'}}>
            <label style={{marginRight: '20px', width: 'fit-content'}}> Template Name </label>
            <input value={props.templateName} style={{width: '70%'}} onChange={props.changeTemplateName} />
            </div>
        }

      </div>
      <div className="fieldsWrapper">

          <ProgressBar
             id = { props.project ? props.project.id : null }
    	  	   noTitle  = { props.project ? null : true }
             titles = { props.titles }
             index  = { props.index  }
             type   = { props.type }
	     route  = { route}
             noLink = {false}/>

        { !props.project &&
              <div className="build-fields template-fields-wrapper">

                <div className="">
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                  { !props.project && <button className="edit-button add-q" onClick={props.addQuestion}>Add Question <i className='fa fa-plus'></i></button> }
                   </div>
                  <div style={{marginLeft: '20px', display: 'flex', color: 'black'}}>
                    <h4>Question Name</h4>
                    <h4 style={{marginLeft: '200px'}}>Question Field Name</h4>
                  </div>

                </div>
                {props.data && props.data.map((data,i) =>
                        data.type == 'template' ? null : <EditRow openOptionsModal={props.openOptionsModal} removeQuestion = {props.removeQuestion} index={i} onChange= {props.onChange} changeType={props.changeType}title = {data.title} type={data.type} name={data.name}/>

                )}

                <div className='bottom-nav-footer'>
                  <BottomNav
                      save     = { props.save }
                      index    = { props.index }
                      numPages = { props.titles.length }
                      route    = { route }
		      type     = { props.type }
		/>
                </div>
              </div>
        }
          { props.project &&
          <div className='build-fields'>
            <div className='bf-wrapper'>
            <div className="bf-leftside">
              {inputs.map( data =>{

                if (data.type == 'dropdown' && data.choices.length < 4) {
                  data.type = 'checkbox'
                }
                if (data.type == 'text' && data.name == 'part_ids'){
                  return false
                }
                return(
                data.type != 'template' &&
                  <InputAdapter
                    project  = { props.index === 0 ? props.project.project : props.project }
                    teamsToAdd = { props.teamsToAdd}
                    isEdit = { props.isEdit }
                    teams    = { props.teams }
                    set      = { data }
                    onChange = { props.onChange }
                    addReport = { props.addReport }
                    removeReport = { props.removeReport }
                    onUpload = { props.onUpload }
                    removeFile = { props.removeFile }/>
                );})}
            </div>

            <div className="bf-rightside">
              {partsFields.map( data =>{
                if (data.type == 'dropdown' && data.choices.length < 4) {
                  data.type = 'checkbox'
                }
                return(
                data.type != 'template' &&
                  <InputAdapter
                    project  = { props.index === 0 ? props.project.project : props.project }
                    teamsToAdd = { props.teamsToAdd}
                    isEdit = { props.isEdit }
                    teams    = { props.teams }
                    set      = { data }
                    onChange = { props.onChange }
                    addReport = { props.addReport }
                    removeReport = { props.removeReport }
                    onUpload = { props.onUpload }
                    removeFile = { props.removeFile }/>
                );})}

                { /* NEW CODE FOR RENISHAW BUILD IF PLUGIN PRESENT */ }
                { (props.type != 'project-page' && props.type != 'template' && props.title == 'Post-Process' &&
                   addons && addons.length>0 && addons.map(item => item.shortname).includes('rc')) && 
                   
                   <RenishawBuild buildId={buildId}/>

		}

            </div>
            </div>
            <div>

              {
                (props.data.length === 0 && props.emptyType === 'active') &&
                <Show type="active" type2="inner" project={props.project.project} />
              }

              {
                (props.data.length === 0 && props.emptyType === 'complete') &&
                <Show type="complete" type2="inner" project={props.project.project} />
              }

              {
                (props.data.length === 0 && props.emptyType === 'export') &&
                <div className="export-section">
                  <h2>Export Your Project Data</h2>
                  {
                    props.project.project &&
                    <button onClick={getExportLink}>Export Data</button>
                  }

                </div>
              }

            </div>

            {
              props.data.length != 0 &&
              <div className='bottom-nav-footer'>
                <BottomNav
                    save     = { props.save }
                    index    = { props.index }
                    numPages = { props.numPages }
                    route    = { route }
                    type     = { props.type }
                    markComplete = {(complete) => markComplete(complete)}
                    completed = {props.project.is_complete}
                />
              </div>

            }

            </div>
          }

        </div>

      </div>

    </div>
  );
}

export default SectionPage;


const BottomNav = props => {
  var saveName = "Build";
  if (props.type === 'project-page'){
    saveName = "Project";
  } else if (props.type === 'template'){
    saveName = "Template"
  }

  return(
    <div className="bottomnav" style={{display: "flex", marginLeft: "20px"}}>
      { ( props.index > 0 && props.type != 'project-page') &&
          <Link onClick={() => window.scroll(0,0)}to = { `${props.route}/${props.index - 1}` }>
              Go Back A Section
          </Link>
      }
        <button onClick={props.save }>Save {saveName}</button>

      {(  props.index + 1  < props.numPages && props.type != 'project-page' ) &&
          <Link onClick={() => window.scroll(0,0)}to = {`${props.route}/${props.index + 1}`}>
              Next Section
          </Link>
      }
      { (props.type != 'project-page' && props.type != 'template' && !props.completed) && 
        <button onClick={()=>props.markComplete(true) }>Mark Build Completed</button>
      }
      { (props.type != 'project-page' && props.type != 'template' && props.completed) && 
        <button onClick={()=>props.markComplete(false) }>Mark Build Uncomplete</button>
      }
    </div>
  );
}




// <MainHeader header={ props.project ? props.project.proc_title : props.templateName ? props.templateName : "Create Template" } crumb={["Home", 'Projects', 'Active']} />
