import React from "react";
import { Link } from "react-router-dom";

const ProgressBar = props => {

  var titles = props.titles;
  var index =  props.index;
  return(
    <div className="tabbed-sections" >
      <div className="tabbed-section-item">
        {titles.map( title =>
            titles.indexOf(title) == index || props.noLink ?
            <BarItem id={props.id} type={props.type} class="itemActive" noLink={props.noLink} title={title} index = {titles.indexOf(title)} route={props.route}/> :
            <BarItem id={props.id} type={props.type} title={title} index = {titles.indexOf(title)} route={props.route} />
          )}
      </div>
    </div>
  );
}

export default ProgressBar

const BarItem = props =>{

 // var route = props.type === 'template' ? `/template/edit/` : `/build/${props.id}/`;
  return(
    <div className={`innerItem ${props.class}`}>
    { props.noLink == true ?
      props.title :
      <Link to = {`${props.route}/${props.index}`}>
        { props.title }
      </Link>
    }
    </div>
  );
};

/* TODO: suggest this as a feature
const ProgressMarker = props =>(
  <p>Hi</p>
);

*/
