export function count_duplicate(a){
 let counts = {}

 for(let i =0; i < a.length; i++){
     if (counts[a[i]]){
     counts[a[i]] += 1
     } else {
     counts[a[i]] = 1
     }
    }
  return counts
};



export function getMaterialsCount(builds){
  var apt = [];

  builds.forEach((item) => {
    var string = item.process_type
    if (!apt.includes(string) && string != null){
      apt.push(string)
    }
  });
  var types = [];
  apt.forEach((type) => {
    var obj = {};
    var materials = [];
    obj = {name: type, builds: 0, material_name: '', materials: 0};
    builds.forEach((item) => {
      if (item.process_type == type) {
        var realMatName = '';
        if (obj.material_name == '') { 
          if (item.material_name) { 
            obj.material_name = item.material_name; 
            realMatName = item.material_name;
          } else { 
            obj.material_name = item.feedstock_trade_name; 
            //realMatName = item.feedstock_trade_name;
            realMatName = item.feedstock_base_material;
          }

	}

        materials.push(item.material_name);

        obj.builds += 1

      }
      obj.materials = [...new Set(materials)].length;
    })
    types.push(obj)
  })


  return types;

}

export function getBuildTypes(builds){
  var apt = [];
  builds.forEach((item) => {
    var string = item.process_type
    apt.push(string)
  })

  var apt2 = apt.filter(b => {
    if (b != null && b != 'null'){
      return b
    }
  })
  return apt2
}
