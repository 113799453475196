import React, { useState, useEffect } from 'react';
import { backUrl } from '../../App/constants';
import { lastcommit } from '../../App/constants';

const Alive = () => {
  const [status, setStatus] = useState('loading');

  useEffect(() => {
    fetch(backUrl + 'alive')
      .then(response => {
        if (!response.ok) {
          setStatus('failed');
          return;
        }
        setStatus('success: ' + lastcommit);
      })
      .catch(() => setStatus('failed'));
  }, []);

  return (
    <div>
      {status === 'loading' ? 'Checking...' : status}
    </div>
  );
};

export default Alive;

