import React, {useState, useEffect} from "react";
import Modal from 'react-modal';
import BasicTable from '../../common/basic-elements/BasicTable';
import { backUrl, schema, docUrl, apiUrl} from "../../App/constants";
import SectionPage from "../question_set/SectionPage";
import { Route } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const Active = (props) => {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState({});
  const [importedSchema, setImportedSchema] = useState(null);
  const [teams, setTeams] = useState([]);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [teamsToAdd, setTeamsToAdd] = useState([]);
  const [build, setBuild] = useState({})
  const [projectAttributes, setProjectAttributes] = useState(null)

  useEffect(() => {
    getBuild();
    // let names = importedSchema.pages.map(page => page.name);
    // var elements = importedSchema.pages.map(page => page.elements);
  }, []);

  function getBuild(){
    var buildId = props.match.params.id;
    fetch(`${backUrl}builds/` + buildId, {
      method: "GET",
      headers: {
       "Authorization": 'Bearer ' + localStorage.getItem('token')
      }

    })
      .then(res => res.json())
      .then(json => {
    	    var buildE = json;
          setProject(json.project)
          setBuild(json)
  	      if (buildE && buildE.project &&  buildE.project.template_id ) {
            fetch(`${backUrl}templates/${buildE.project.template_id}`, {
              headers: {
                "Authorization": 'Bearer ' + localStorage.getItem('token')
              }
            })
             .then(a => a.json())
             .then(a => {
              setImportedSchema(JSON.parse(a.schema));
              setProjectAttributes(JSON.parse(a.schema).pages[0].elements.map((e) => e.name))
             });
          }
    });
  }

  function removeFinalReport(projectId) {
    fetch(`${backUrl}projects/${projectId}/file`, {
      method: 'DELETE',
      headers: {
       "Authorization": 'Bearer ' + localStorage.getItem('token')
      }
    })
      .then(x => window.location.reload())
      .catch(e => alert(e))
  }

  function removeFile(event) {
    if (!window.confirm('Are you sure you want to remove this attachment?') ) { return; }
    if (event === 'final_report') {
      removeFinalReport(project.id);
      return;
    }
    const field = event
    const url = backUrl + 'builds/' + build.id + '/file';

    fetch(url, {method: "DELETE",
      body: JSON.stringify({"file_type": field}),
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Accept": "application/json"
    }}
    )
      .then(a => {
        //window.location.reload();
        getBuild()
      })
      .catch(e => alert('error deleting'));
  }


  function onUpload(event) {
    if (event.target.name === 'final_report') {
      addFinalReport(event);
      return;
    }
    var value = event.target.value;
    var file = event.target.files[0];
    var data = new FormData();
    data.append('file', file);
    data.append('file_type', event.target.name);

    fetch(backUrl + 'builds/' + build.id + '/file', {
            method: "PUT",
            headers:{
              "Authorization": 'Bearer ' + localStorage.getItem('token')
            },
            body: data
    })
    .then(a => a.json())
    .catch(e => console.log(e))
    .then(a => {
      var copiedBuild = Object.assign({}, build);
      copiedBuild[a.field_name] = {
        url: a.file_url,
        fileName: a.file_name,
        id: a.id
      };
      setBuild(copiedBuild)
    })
    .catch(e => console.error(e));
  }


  function handleProjectAttributeChange(event) {
    const target = event.target
    const name = target.name;
    const value = target.value;
    const newProject = {
      ...project,
      [name]: value
    }
    var copiedBuild = Object.assign({}, build)
    copiedBuild['project'] = newProject
    setBuild(copiedBuild)
    setProject(newProject)
  }

  function onChange(event) {
    const target = event.target
    const name = target.name;

    if (projectAttributes.includes(name)) {
      handleProjectAttributeChange(event);
      return;
    }

    if (name === 'non_destructive') {
      handleNonDestructive(event.target.value);
      return;
    }
    if (name === 'destructive') {
      handleDestructive(event.target.value);
      return;
    }


    var value = target.value;
    if (target.type == "checkbox") { value = target.checked }

    var copiedBuild = Object.assign({}, build)
    copiedBuild[name] = value;
    setBuild(copiedBuild)

  }

  function handleNonDestructive(value) {
    fetch(`${backUrl}/builds/${build.id}/tests`, {
      method: 'POST',
      body: JSON.stringify({
        test_type: value
      }),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).then(x => x.json())
      .then(j => {
        const newTest = {
          id: j.id,
          attachment_url: null,
          test_type: value
        };

        var newBuild = {
          ...build,
          tests: [...build.tests, newTest]
        }

        setBuild(newBuild)

      })
      .catch(e => console.error(e));
  }

  function handleDestructive(value) {
    fetch(`${backUrl}/builds/${build.id}/parts`, {
      method: 'POST',
      body: JSON.stringify({
        test_type: value
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Accept": "application/json"
      }
    })
  }

  function saveNestedProject() {
    const projectId = project.id;
    // const updatedProject = JSON.stringify({
    //     completion_date: project.completion_date,
    //     itar_restriction: project.itar_restriction,
    //     lead_investigator: project.lead_investigator,
    //     lead_investigator_organization: project.lead_investigator_organization,
    //     name: project.name,
    //     partners: project.partners
    // })

    const updatedProject = JSON.stringify({
      name: project.name,
      lead_investigator: project.lead_investigator,
      lead_investigator_organization: project.lead_investigator_organization,
      completion_date: project.completion_date,
      itar_restriction: project.itar_restriction,
      pcd_revision: project.pcd_revision,
      cognizant_engineering_organization: project.cognizant_engineering_organization,
      project_lead: project.project_lead,
      material_producer: project.material_producer,
      parts_producer: project.parts_producer,
      heat_treater: project.heat_treater,
      support_removal_provider: project.support_removal_provider,
      testing_provider: project.testing_provider,
      partners: project.partners
    })

    fetch(`${backUrl}/projects/${projectId}`, {
      method: 'PUT',
      body: updatedProject,
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    })
  }

  // updateProject
  async function saveProject(sectionNumber) {
    if (sectionNumber === 0) {
      saveNestedProject();
      return;
    }
    var formData = new FormData();

    // extract this
    {
      for (let key in build) {
              key ? formData.append(key, build[key]) : null
      }
    }

    var url = `${backUrl}/builds/${build.id}`
    fetch(url, {
      method: "PUT",
      body: formData,
      headers: new Headers({
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      })
    })
      .then(res => {
        toast(`Build for project "${project.name}" has been saved`)

        return res.json();
      })

  }

  function removeReport(e) {
    alert(e);
  }

  function addReport(event) {
    const fileId = event.target.name
    var file = event.target.files[0];
    var data = new FormData();

    data.append('file', file);

    fetch(backUrl + 'tests/' + fileId + '/file', {
            method: "PUT",
            body: data
    })
    .then(a => a.json())
    .then(v => window.location.reload())
    .catch(e => alert(e));
  }

  function addFinalReport(event) {
    const projectId = project.id;
    const file = event.target.files[0];
    let data = new FormData();
    data.append('file', file);
    fetch(backUrl + 'projects/' + projectId + '/file', {
      method: 'PUT',
      body: data
    })
    .then(x => window.location.reload())
    .catch(e => alert(e));
  }

    //console.log("Loaded Imported Schema")
    //console.log(importedSchema)
    //console.log("Project attrs", projectAttributes)

    if(projectAttributes == null || importedSchema == null){
      //console.log("reset")
      getBuild()
      return <div></div>
    }

    return(
      <div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      { loading && <h1> WE LOADING! </h1> }
      { importedSchema.pages.map( e =>
          <Route path = {`/build/:id/${importedSchema.pages.map(page => page.elements).indexOf(e.elements)}`}
                  key={e.name}
                 render = { () =>
                     <SectionPage title={e.name}
                                  isEdit = {true }
                                  type="active"
                                  project = {build}
                                  data={e.elements}
                                  onChange={(event) => onChange(event)}
                                  onUpload={(event) => onUpload(event)}
                                  save={() => saveProject(importedSchema.pages.map(page => page.elements).indexOf(e.elements))}
                                  titles = { importedSchema.pages.map(page => page.name) }
                                  index = {importedSchema.pages.map(page => page.elements).indexOf(e.elements)}
                                  numPages = {importedSchema.pages.length}
                                  addReport={(e) => addReport(e)}
                                  removeReport={(e) => removeReport(e)}
                                  removeFile={event => removeFile(event)}
                                  reloadSection={()=>getBuild()}
                      />
                 }
          />
      )}
      </div>
    );
}
export default Active;
