import React from "react";
import { schema, backUrl } from "../../App/constants";
import MainHeader from "../../common/nav/MainHeader";
import { Link } from "react-router-dom";
import { projectIdentifier } from '../../App/constants';

export default class CompleteProject extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      project: {}
    }
  }

  componentWillMount() {
    fetch(`${backUrl}projects/${this.props.match.params.id}`)
      .then(s => s.json())
      .then(s =>
        this.setState({project: {...s.project, ...s.build}})
      )
  }

  render() {
    const { props } = this;
    var projectId = props.match.params.id;
    var currentProject;

    var pages = schema.pages;

    var style = { border: 'none', borderRadius: '2px', height: '100%', marginRight: '10px', cursor: 'pointer'};

  // TODO: add correct project name
    return(
      <div >
      <MainHeader header={`${ this.state.project.name}`} crumb={["Home", "Projects"]} >
      <div style={{display: 'flex'}}>
      <button onClick={props.fullPDF} style={style}><i class="fa fa-file-pdf-o" style={{marginRight: '6px'}}aria-hidden="true"></i>Full Report</button>
      </div>
      </MainHeader>
      <div style={{background: 'white'}}>
        { pages && pages.map(page => <Dropdown  page={page} project={this.state.project}/>)}
      </div>
      </div>
    );
  }
}

class Dropdown extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			dropdown: "inactive"
		}
	}

	toggleSection() {
		this.state.dropdown == "inactive" ? this.setState({dropdown: "active"}) : this.setState({dropdown: "inactive"})
	}

	render() {
		var elements = this.props.page.elements;
		var sectionName = this.props.page.name;
		var currentProject = this.props.project;
	return(
		<div className={`complete-section form-header fade ${this.state.dropdown}`}>
		<h3 style={{cursor: 'pointer'}}onClick={()=> this.toggleSection()}>{sectionName}</h3>
		<table className = { `${this.state.dropdown} table-basic` }>
		<tbody>
		{ elements.map( section =>{
  if (section.type !== 'checkbox' && section.type !== 'multipletext') {
return(
			<tr>
			  	<td style={{width: "400px", marginLeft: "80px", color: "black"}}>{currentProject && section.title}:</td>
					<td style={{color: "black"}}>{currentProject && <Display project={currentProject} name={section.name} type={section.type}/>}</td>
			</tr>
)}
  else if (section.type === 'checkbox'){
return(
   section.choices.map( c =>
			<tr>
			  	<td style={{width: "400px", marginLeft: "80px", color: "black"}}>{currentProject && c.text}:</td>
					<td style={{color: "black"}}>{currentProject && <Display project={currentProject} name={section.name} type={'c'}/>}</td>
			</tr>
)
)
}
else {
return(
   section.items.map((s, i) =>
    (
    <tr>
			  	<td style={{width: "400px", marginLeft: "80px", color: "black"}}>{s.title}:</td>
					<td style={{color: "black"}}>{currentProject && <Display project={currentProject} name={section.name} type={'m'}/>}</td>
</tr>
  ))
)

}
}
		)}
		</tbody>
		</table>
		</div>
	);
	}
}

const Display = props =>
<div>
  { props.type == 'file' && <span>file</span> }
  { props.type != 'file' && props.type != 'c' && <span>{props.project[props.name] != 'null' && props.project[props.name]}</span> }
  { props.type == 'c' && <span>User 1</span> }

  </div>
