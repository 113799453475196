/**
 * We need to wrap the hook in an HOC (Higher-Order Component) because 
 * the majority of our components are class components and we can't use 
 * the custom hook directly in class components. 
 * 
 * An HOC is a function that takes a component as an input and returns a new component. 
 * The new component will have the same props and render function as the original component, 
 * but it will also have the functionality of the hook.
 *
 */

/**
 *  But the plot thickens. If we put multiple fetches in the HOC, all will fire 
 *  even for pages which only use one of them. Therefore, we will define an HOC 
 *  per endpoint, and use composition to allow for pages which need multiple fetches.
 * 
 */

import React from 'react';
import { compose } from 'react-recompose';
//import { compose } from 'recompose';

/*******************************************************************
//endpoints returning lists or collections of objects
********************************************************************/
import fetchProjects from './fetches/fetchProjects';
import fetchParts from './fetches/fetchParts';
import fetchBuilds from './fetches/fetchBuilds';
import fetchTemplates from './fetches/fetchTemplates';

const fetchPartsAndProjects = compose(fetchParts, fetchProjects);
const fetchBuildsAndProjects = compose(fetchBuilds, fetchProjects);
const fetchProjectsAndTemplates = compose(fetchProjects, fetchTemplates);
const fetchBuildsAndParts = compose(fetchBuilds, fetchParts);
const fetchPartsAndTemplates = compose(fetchParts, fetchTemplates);
const fetchBuildsAndTemplates = compose(fetchBuilds, fetchTemplates);

const fetchBuildsPartsProjectsAndTemplates = compose(
  fetchBuilds,
  fetchParts,
  fetchProjects,
  fetchTemplates
);


/*******************************************************************
//endpoints returning individual objects 
********************************************************************/
import fetchProject from './fetches/fetchProject';
import fetchTemplate from './fetches/fetchTemplate';

const fetchProjectAndTemplate = compose(fetchProject, fetchTemplate);


/*******************************************************************
//special endpoints 
********************************************************************/
import loadProject from './fetches/loadProject';


/*******************************************************************
//API library 
********************************************************************/
export {
  fetchBuilds,                             /* collection object fetches */ 
  fetchBuildsAndProjects,
  fetchBuildsAndParts,
  fetchBuildsAndTemplates,
  fetchBuildsPartsProjectsAndTemplates,
  fetchParts,
  fetchPartsAndTemplates,
  fetchPartsAndProjects,
  fetchProject,
  fetchProjects,
  fetchProjectsAndTemplates,
  fetchTemplate,
  fetchTemplates,                        /* individual object fetches */ 
  fetchProjectAndTemplate,
  loadProject
};


