import React from 'react';
import Modal from './Modal';

// Define the DeleteModal functional component
const DeleteModal = ({ isOpen, onClose, onDelete, type }) => (
  // Use the Modal component and pass in the isOpen and onClose props
  <Modal isOpen={isOpen} onClose={onClose}>
    {/* Container div for the DeleteModal */}
    <div className="deleteModal">
      {/* Display the confirmation message */}
      <p>Are you sure you want to delete this {type}?</p>
      
      {/* "Yes" button to confirm deletion */}
      <button onClick={onDelete} className="btn">Yes</button>
      
      {/* "No" button to close the modal */}
      <button onClick={onClose} className="btn">No</button>
    </div>
  </Modal>
);

// Export the DeleteModal component
export default DeleteModal;
