import React from 'react';
import useTraceAPI from '../hooks/useTraceAPI';

/**
 * Higher-Order Component (HOC) that provides the loaded project to the wrapped component.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped.
 * @returns {React.Component} - The enhanced component with project as a prop.
 */
const loadProject = (WrappedComponent) => {

//  const MyComponent = props => {

  return props => {
    const projectId = props.match.params.id 

    // Get API functions from the useTraceAPI hook
    const { getProject, getTemplate } = useTraceAPI();


    // State to hold the loaded actions
    const [project, setProject] = React.useState([]);
    const [schema, setSchema] = React.useState([]);
    const [projectAttributes, setProjectAttributes] = React.useState([]);

    // State to hold error flag & message if load fails
    const [projectError, setProjectError] = React.useState([]);
    const [projectErrorMessage, setProjectErrorMessage] = React.useState([]);

    React.useEffect(() => {
      getProject(projectId).then(fetchedProject => {
        if (fetchedProject.success) { 
          setProject(fetchedProject.data);
          setProjectError(false);
          setProjectErrorMessage('');
        } else {
          setProjectError(true);
          setProjectErrorMessage(fetchedProject.message);
          setProject([]); // return empty array so downstream components don't choke and die
        }
      });
    }, []); // The empty dependency array ensures this effect runs only once


    // Fetch project once after the component mounts
    React.useEffect(() => {
      getProject(projectId).then(loadedProject => {
        if (loadedProject.success) { 
          // clear error states	     
          setProject(loadedProject.data);
          setProjectError(false);
          setProjectErrorMessage('');

          //if we got the project, dig out template and fetch that 
          getTemplate(loadedProject.data.project.template_id).then(loadedTemplate => { 
            if (loadedTemplate.success) { 
	      // clear error states	     
              setProjectError(false);
              setProjectErrorMessage('');

	      //set schema/attrib states
              setSchema(JSON.parse(loadedTemplate.data.schema));
              setProjectAttributes(JSON.parse(loadedTemplate.data.schema).pages[0].elements.map((e) => e.name));
            } else {
              setProjectError(true);
              setProjectErrorMessage(fetchedProject.message);
              setProject({}); // return empty object so downstream components don't choke and die
              setSchema({});
              setProjectAttributes({});
            }
          });
        } else {
          setProjectError(true);
          setProjectErrorMessage(fetchedProject.message);
          setProject({}); // return empty object so downstream components don't choke and die
          setSchema({});
          setProjectAttributes({});
        }
      });
    }, []); // The empty dependency array ensures this effect runs only once

    // Render the wrapped component with loaded project as a prop
    return <WrappedComponent {...props} project={project} schema={schema} projectAttributes={projectAttributes} 
	                                projectError={projectError} projectErrorMessage={projectErrorMessage} />;
  };
};

export default loadProject;


