import React, {useEffect, useState, useRef} from "react";
import plotly from 'plotly.js/dist/plotly';
import PlotlyEditor from 'react-chart-editor';
import 'react-chart-editor/lib/react-chart-editor.css';
import SubNav from '../../../common/nav/SubNav';
import { backUrl } from '../../../App/constants';
import SearchInput from '../../search/SearchInput';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const config = { editable: true };

const ChartBuilder = () => {
  const [state, setState] = useState({ data: [], layout: {}, frames: [] });
  const [dataSource, setDataSource] = useState({});
  const [dataSourceOptions, setDataSourceOptions] = useState([]);

  const plotRef = useRef(null); // Create a ref to store the Plot component instance

  const saveChart = () => {
    // Access the latest layout from the Plot component using the ref
    const latestLayout = plotRef.current.props.layout;
    const latestData = plotRef.current.props.data;

    const serializedData = JSON.stringify(latestData);
    const serializedLayout = JSON.stringify(latestLayout);

    const source = { type: 'builder' } 
    const serializedSource = JSON.stringify(source);

    // Combine data and layout into a single object to send to the backend
    const payload = {
      data: serializedData,
      layout: serializedLayout,
      source: serializedSource
    };

    // Assuming you have a backend endpoint to receive the data
    fetch(`${backUrl}charts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        // Handle the response from the backend if needed
        console.log('Save successful:', data);
        toast.success('Your chart has been saved to the Data Explorer');
      })
      .catch(error => {
        console.error('Error saving graph:', error);
        toast.error('Something went wrong');
      });
  }

  useEffect(() => {
    const chartId = new URLSearchParams(location.search).get('chart_id');

    if (!chartId) { return; }
    console.log(chartId);
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) { throw new Error('Token not available'); }
        var fetchUrl = `${backUrl}charts/${chartId}`;

        const response = await fetch(fetchUrl, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const res = await response.json();

        setState((prevState) => ({ ...prevState, data: JSON.parse(res.data), layout: JSON.parse(res.layout) }));

      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Something went wrong');
      }
    };


    fetchData();

  }, [location.search]); // Dependency on location.search to re-run the effect when it changes

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          // Handle case where the token is not available
          console.error('Token not available');
          return;
        }

        const response = await fetch(`${backUrl}/chart_builder`, {
          method: 'GET',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch builder data');
        }
        const data = await response.json();
        setDataSource(data || {});
        setDataSourceOptions(Object.keys(data || []).map((name) => ({ value: name, label: name })));
      } catch (error) {
        console.error('Error fetching builder data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to run the effect only once on mount

  const updateDataState = (newData) => {
    setState((prevState) => ({ ...prevState, data: newData }));
  };

  return (
    <div>
      <div className="searchTopBarWrapper">
        <SubNav section="explorer" current="builder" />

        <div className="searchInputWrapper searchBox">
          <h2>Run Search</h2>
          <div className="innerSIWrapper">
            <SearchInput
              type="search"
            />
            <button onClick={() => setReadyToClear(true)}>Clear</button>
          </div>
        </div>
      </div>

      <div className="main-div-wrapper">
        <div className="maintop">
          <h1>Chart Builder</h1>
          <button onClick={saveChart}>Save Chart</button>
        </div>

        <div className="viz-page">
          <div style={{ height: '70vh' }}>
            <PlotlyEditor
              ref={plotRef} // Attach the ref to the Plot component
              data={state.data}
              layout={state.layout}
              config={config}
              frames={state.frames}
              dataSources={dataSource}
              dataSourceOptions={dataSourceOptions}
              plotly={plotly}
              onUpdate={(data, layout, frames) => setState({ data, layout, frames })}
              useResizeHandler
              debug
              advancedTraceTypeSelector
            />
          </div>
        </div>
      </div>

      <ToastContainer 
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={'light'}
      />

    </div>
  );
};

export default ChartBuilder;

