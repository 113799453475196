import React from 'react';
import useTraceAPI from '../hooks/useTraceAPI';

/**
 * Higher-Order Component (HOC) that provides the fetched parts to the wrapped component.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped.
 * @returns {React.Component} - The enhanced component with parts as a prop.
 */
const fetchParts = WrappedComponent => {

  return props => {
    // Get API functions from the useTraceAPI hook
    const { getParts } = useTraceAPI();

    // State to hold the fetched parts
    const [parts, setParts] = React.useState([]);

    // State to hold error flag & message if fetch fails
    const [partsError, setPartsError] = React.useState([]);
    const [partsErrorMessage, setPartsErrorMessage] = React.useState([]);

    // Fetch parts once after the component mounts
    React.useEffect(() => {
      getParts().then(fetchedParts => {
        if (fetchedParts.success) {
          setParts(fetchedParts.data);
          setPartsError(false);
          setPartsErrorMessage('');
        } else {
          setPartsError(true);
          setPartsErrorMessage(fetchedParts.message);
          setParts([]);
        }
      });
    }, []); // The empty dependency array ensures this effect runs only once

    // Render the wrapped component with fetched parts as a prop
    return <WrappedComponent {...props} parts={parts} partsError={partsError} partsErrorMessage={partsErrorMessage} />;
  };
};

export default fetchParts;

