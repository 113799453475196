import React from "react";

const Select = props => {
	return(
		<div className="form-row">
			<label className="form-label">
				{props.label}
			</label>
			<div className="form-field">
				<select multiple={props.multiple} name={props.name} onChange={props.onChange} value={props.value}>
					<option value="" disabled selected>Select your option</option>
					{ props.options ? props.options.map(a => <option value={a}>{a}</option>) : null}
				</select>
				<small className="hide">tip/subhead</small>	
			</div>
		</div>
	);
}

export default Select;
