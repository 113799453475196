import React from "react";
import styled from 'styled-components';
import BasicTable from '../../common/basic-elements/BasicTable';
import { RemoveIcon } from '../../common/Icons';
import Modal from '../../common/basic-elements/Modal';
import MainHeader from "../../common/nav/MainHeader";
import { backUrl, docUrl } from '../../App/constants';
import DeleteModal from '../../common/basic-elements/DeleteModal';
import SubNav from '../../common/nav/SubNav';
import '../search/styles/SearchPage.css';
import '../../assets/css/Main.css';
import './modals.css'

class Documentation extends React.Component{
  constructor(props){
    super(props);

    var types = {};
    props.typeData.forEach(datum => {
      types[datum.name] = null;
    });

    this.state = {
      show: false,
      showDeleteModal: false,
      typeData: types,
      labels: props.typeData.map(t => t.label),
      data: [],
      //token: JSON.parse(localStorage["appState"]).user.auth_token
      token: localStorage.getItem('token')
    };
  }
  changeState(){
    this.setState({show:true});
  }

  singularize = str => str.replace(/s$/, '').replace(/_/g, ' ');

  handleChange(e){
    var cool = this.state.typeData
    var {id,value} = e.target;
    cool[id] = value;
    // set the state of the material object.
    this.setState({typeData: cool});
  }

  handleFile(e){
    var fileIn = e.target.files[0];
    var typeData = this.state.typeData
    typeData["file"] = fileIn
    this.setState({typeData});
  }

  handleSubmit(e){
    e.preventDefault();
    var formData = new FormData();

    var current = this.state.typeData;
    if (!this.state.typeData.file) {
      alert('you must choose a file');
      return null;
    }

    for (let lessFunny in current ) {
      formData.append(lessFunny, current[lessFunny]);
    }

    const url = backUrl + this.props.type;
    fetch(url, {
      method: "POST",
      body: formData,
      headers: new Headers({
        'Authorization': `Bearer ${this.state.token}`
      })
    })
      .then(res => res.json())
      .then( json => {
        this.getEquipment();
        this.setState({show: false});
      })
  }

  componentWillMount(){
    this.getEquipment();
  }

  delete(id){
    var id = this.state.documentationToDelete;
    var url = `${backUrl}${this.props.type}/${id}`;
    var formData = new FormData();
    formData.append("id", id);
    fetch(url, {
      method: "DELETE",
    })
    .then( a => {
      var newData = this.state.data.filter(d => d.id != id);
      this.setState({data: newData, showDeleteModal: false});
    })
  }

  formatHeader() {
    return this.props.type.replace('_', ' ');
  }

  async launchDeleteModal(id) {
    await this.setState({documentationToDelete: id, showDeleteModal: true});
  }

  getEquipment(){
    const url = backUrl + this.props.type;
    fetch(url, {
      method: "GET",
      headers: new Headers({
        'Authorization': `Bearer ${this.state.token}`
      })
    })
      .then(res => {
        return res.json()
      })
      .then(json => {
        this.setState({
          data: json
        }, () => console.log('aaaaaaaaaaaaaa', this.state)
      );

      })
  }

  render(){
    return(
      <div style={{display: 'flex', flexDirection: 'column', padding: 10}}>
      <DeleteModal
        type= {this.singularize(this.props.type)}
        isOpen = {this.state.showDeleteModal}
        onClose = {() => this.setState({showDeleteModal: false})}
        onDelete = {() => this.delete()}
      />
        <Modal
          isOpen = { this.state.show}
          onClose = { () => this.setState({show: false })}
        >
          <New handleSubmit = { (e) => this.handleSubmit(e) }
               handleChange = { (e) => this.handleChange(e) }
               typeData = {this.state.typeData}
               labels = {this.state.labels}
               handleFile = { (e) => this.handleFile(e)}
               data = {this.state.data}
               p = {this.props}
          />
        </Modal>
        <div className="searchTopBarWrapper">
          <SubNav section='documentation' current={this.props.type}/>
          <div className="action-wrapper">
            <button className="new-button" onClick={ () => this.changeState() }>Add New {this.props.type} Document</button>
          </div>
        </div>

        { ("status" in this.state.data ?
        <div className="noDocRows">
          <h2>No {this.props.type} Documentation</h2>
        </div>
          :
          <EquipmentList
            labels = {this.state.labels}
            typeData = { Object.keys(this.state.typeData) }
            data = {this.state.data}
            type = {this.props.type}
            launchDeleteModal = { (id) => this.launchDeleteModal(id) }/>
        )}
      </div>
    );
  }
}

var NewContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  width: 500px;
`

// use a map -- how to do styling??
const New = props =>
      <NewContainer className="newModal">
        <h3>Upload New Document </h3>
        <form onSubmit = { props.handleSubmit}>
        		{	 Object.keys(props.typeData).filter(a => a != 'file').map( (label, i) =>
             <div key={i} style={{width: '500px', display: 'flex', borderBottom: '1px solid lightgrey', paddingBottom: '10px', marginTop: '10px' }}>
        		 <label style={{minWidth: '100px'}}>
        		   { props.labels[i] }
        		 </label>
        		 <input type={props.p.typeData[i].type ? props.p.typeData[i].type : null} id={label} style={{width: '300px'}}onChange= {props.handleChange}/>
             </div>
        	 ) }
          <div style={{paddingTop: '10px'}}>
            { props.typeData.file && <input className="fileName" disabled='true' value={props.typeData.file.name}/> }
            <div className ="upload-submit-doc">
              <div className="file-and-button">

              <label style={{marginRight: 0}} className = "uploadFileButton">
                Select File...
                <input type = "file" className = "inputfile" accept=".pdf" onChange={ props.handleFile } id="file"/>
              </label>
              </div>
              <button className="submitDocButton" onClick={props.handleSubmit}>Submit</button>
             </div>
          </div>
        </form>
    </NewContainer>

const EquipmentList = props =>
        <BasicTable>
          <thead>
            <tr>
              { props.labels.map( datum =>
                <td style={{textTransform: 'capitalize'}}> { datum } </td>
              )}
              <td>Documentation</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {props.data && props.data.length > 0 && props.data.map((info, i) =>
              <tr key={i}>
                {props.typeData.filter(a => a != 'file').map(type =>
                  <td onClick={() => console.log(props.typeData)}>
                    { info[type] }
                  </td>
                )}
                <td>
                  <a href = {info.file_url} target="_blank">View</a>
                </td>
                <td>
                  <RemoveIcon onClick={id => props.launchDeleteModal(info.id)} />
                </td>
              </tr>)}
          </tbody>
        </BasicTable>

export default Documentation

// <MainHeader header={this.formatHeader()} crumb={ ['Home', 'Documentation'] } >
//
// </MainHeader>
