// Obfuscated email address variables
var i = String.fromCharCode(105);
var n = String.fromCharCode(110);
var f = String.fromCharCode(102);
var o = String.fromCharCode(111);
var at = String.fromCharCode(64);
var d = String.fromCharCode(51, 100, 101, 103, 114, 101, 101, 115, 99, 111, 109, 112, 97, 110, 121, 46, 99, 111, 109);

// Function to assemble the email address
export function assembleEmailAddress() {
  return i + n + f + o + at + d;
}

// Usage example
//var emailAddress = assembleEmailAddress();
//console.log(emailAddress); // Outputs: "info@3degreescompany.com"

