import React from "react";
import styled from 'styled-components';

var CurrentPage = styled.h3`
	font-size: 1.4em;
  text-transform: capitalize;
`

var Container = styled.div`
  padding-left: 20px;
  padding-top: 13px;
  padding-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFF;
  box-shadow: 5px 0px 10px -4px rgba(0,0,0,0.5); 
`

const MainHeader = props => {

  return(
    <Container>
      <div>
        <ol className="breadcrumb">
		{ props.crumb.map(a => <li key={props.crumb.indexOf(a)} className="breadcrumb-item">{a}  ></li>) }
        	<CurrentPage>{props.header}</CurrentPage>
        </ol>
      </div>
	{props.children}
    </Container>
  );
}

export default MainHeader;
