import React from "react";
import "./CheckboxGroup.css"

const CheckboxGroup = props => {
	return(
		<div className="checkboxGroup">
		<h4>{props.question}</h4>
		<table style={{marginLeft: 28}}>
				{ props.options.map(option =>
					<Checkbox option={option} />
				) }
		</table>
		</div>
	);
}
export default CheckboxGroup;

class Checkbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			checked: false,
		}
	}

		render() {
			var cool = true;
			console.log(this.props)
			if (this.props.option.checked === "false") {cool = false}
			if (this.props.option.checked === null ) {cool = false}
			if (this.props.option.checked === "null") {cool = false}
			if (this.props.option.checked === undefined ) {cool = false}
			return(
				<tr className="checkbox">
					<td>
						<label> {this.props.option.label} </label>
					</td>
					<td>
					{ cool &&
						<input name={this.props.option.name} value={this.props.option.name} defaultChecked={true} style={{width: 20, marginLeft: 60}}onChange={this.props.option.onChange} type="checkbox"/>
					}
				  { !cool &&
						<input name={this.props.option.name} value={this.props.option.name} defaultChecked={false} style={{width: 20, marginLeft: 60}}onChange={this.props.option.onChange} type="checkbox"/>
					}
					</td>
				</tr>
			);
		}

}
