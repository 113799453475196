import React from 'react';
import { backUrl } from "../../App/constants";
import BasicTable from '../../common/basic-elements/BasicTable';
import SubNav from '../../common/nav/SubNav';
import '../../assets/css/builds.css';
import SearchBox from '../search/SearchBox';
import '../search/styles/SearchPage.css';
import Modal from '../../common/basic-elements/Modal';
import BrowseModal from './BrowseModal';

export default class Browse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      showProjects: [],
      showBuilds: [],
      currentProject: {}
    }
  }

  componentDidMount() {

    fetch(`${backUrl}browse`)
      .then(x => x.json())
      .then(z => {
        z.pop();
        this.setState({projects: z})
      })
      .catch(e => alert(e));
  }

  openModal(proj) {
    this.setState({
      showBuilds: proj.builds,
      currentProject: proj,
      show: true
    })

  }

  // closeModal(){
  //   this.setState({show: false})
  // }

  // handleBuildClick(id) {
  //   const newState = this.state.showBuilds.includes(id)
  //     ? this.state.showBuilds.filter(t => t !== id)
  //     : [...this.state.showBuilds, id]
  //
  //   this.setState({
  //     showBuilds: newState
  //   })
  // }


  render() {
    return(
      <div className="browsewrapper">
      <div className="searchTopBarWrapper">
        <SubNav section='explorer' current='browse'/>
        <SearchBox />
      </div>
      <Modal
        isOpen = {this.state.show}
        onClose = {() => this.setState({show: false})}
        className="browse-modal-wrapper"
        browse={true}
      >
        <BrowseModal
           builds = {this.state.showBuilds}
           project = {this.state.currentProject}
        />
      </Modal>
      <BasicTable>
        <thead>
          <td>Project</td>
          <td>Lead Investigator</td>
          <td>Organization</td>
          <td>Partners</td>
        </thead>
        <tbody>
          {
            this.state.projects.map(x =>
              <tr>
                <td>
                  {x.name}
                </td>
                <td>{x.lead_investigator}</td>
                <td>{x.lead_investigator_organization}</td>
                <td>{x.partners}</td>
                <td>
                  <span style={{cursor: 'pointer', textDecoration: 'underline'}} onClick={() => this.openModal(x)} >show builds</span>
                </td>
              </tr>
            )
          }
        </tbody>

      </BasicTable>
      </div>
    );
  }
}
