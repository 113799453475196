import React from "react";
import Card from '../../common/Card';
import { Link } from "react-router-dom";
import { projectIdentifier } from '../../App/constants';

function getPercentComplete(project) {
  var keyNumber = Object.keys(project).length;
  var filledValuesNumber = Object.values(project)
    .filter(a => a && a != 'null')
    .length

  return filledValuesNumber / keyNumber;
}



const ActiveProjectsCard = props => {
  const n = 5;
  //const firstFive = props.activeProjects;
  const firstFive = props.activeProjects.slice(0, n)
  firstFive.forEach(proj => {
    var buildIds = [];
    var b = props.builds.filter(b => b.project_id == proj.id);
    b.forEach(bi => buildIds.push(bi.id));
    var partsCount = 0;
    props.parts.forEach(p => {
      if (buildIds.find(b => b == p.build_id)){
        partsCount += 1;
      }
    })
    proj.parts = partsCount;
  })

  return(
      <Card title='Active Projects' noMargin={true}>
      <table className='activeProjectsTable'>
      <tbody>
      <tr className='aptHeader'>
        <td id="pro">Project</td>
        <td id="nop">No. of Parts.</td>
      </tr>
			{ firstFive.map(project =>
				<tr className="aptProjects" key={project.id}>
            <td>
  						<Link to={`/project/${project.id}/0`} >
                  {project.name}
  						</Link>
            </td>
            <td>
              {project.parts}
            </td>
				</tr>
			) }
      </tbody>
      </table>
    </Card>
  )
}

export default ActiveProjectsCard;
