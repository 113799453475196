import React, {useState, useEffect} from "react";
import history from '../../utils/history';
import './styles/SearchPage.css';
import SearchInput from './SearchInput';

const SearchBox = (props) => {
  // const [value, setValue] = useState('');


    return(
      <div className="searchInputWrapper searchBox">
        <h2>Search Trace</h2>
        <div className="innerSIWrapper">
          <SearchInput
            type="dashboard"
            history={history}
          />
        </div>
      </div>
    )

}

export default SearchBox;
