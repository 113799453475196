/**
 * InfoCard  
 * 
 * Card with TraceAM information
 *
 * Usage:
 * <InfoCard/>
 *
 * @returns card div
 */



/************* React imports *****************/
import { Link } from "react-router-dom";


function InfoCard() {
  return (
		  <div className="login-text">
			  <h2>TRACE<i>AM</i></h2>
			  <h4>Streamline project management and documentation processes at all stages of your 3D Printing workflows <br />
			    <Link to="www.3degreescompany.com">More information</Link>
			  </h4>
		  </div>
  );
}
export default InfoCard;
