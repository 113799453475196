import React from "react";
import Card from './Card';
import SearchResultProjectCard from './SearchResultProjectCard';
import SearchResultBuildCard from './SearchResultBuildCard';
import SearchResultFileCard from './SearchResultFileCard';
import SearchResultTestCard from './SearchResultTestCard';
import SearchResultMaterialCard from './SearchResultMaterialCard';
import SearchResultGenericCard from './SearchResultGenericCard';
import SearchResultPartCard from './SearchResultPartCard';
import { Link } from "react-router-dom";


export function SearchResultCard(props: any) {
  if (props.index=='file') {
    return <SearchResultFileCard
            data={props.data}
            searchWord={props.searchedWord}
           />
  } else if (props.index=='projects') {
    return <SearchResultProjectCard
            data={props.data}
            searchWord={props.searchedWord}
           />
  } else if (props.index=='tests') {
    return <SearchResultTestCard
            data={props.data}
            searchWord={props.searchedWord}
           />
  } else if (props.index=='materials') {
    return <SearchResultMaterialCard
            data={props.data}
            searchWord={props.searchedWord}
           />
  } else if (props.index=='builds') {
    return <SearchResultBuildCard
            data={props.data}
            searchWord={props.searchedWord}
           />
  } else if (props.index=='parts') {
    return <SearchResultPartCard
            data={props.data}
            searchWord={props.searchedWord}
           />
  } else {
    return <SearchResultGenericCard
            data={props.data}
            searchWord={props.searchedWord}
           />
  }

}


export default SearchResultCard;
