import React, {useEffect, useState} from "react";
import { backUrl } from "../../../App/constants";
import Card from '../../../common/Card';
import Plot from 'react-plotly.js';


import styles from './styles.css';

import MachineCard from './MachineCard';
import BuildCard from './BuildCard';
import PlotCard from './PlotCard';
import EventsCard from './EventsCard';

const crumb = ["Home"]
const ultimateColors = [
['rgb(37, 48, 34)', 'rgb(65, 84, 59)', 'rgb(91, 117, 83)', 'rgb(122, 153, 112)', 'rgb(155, 179, 148)'],
['rgb(22, 63, 80)', 'rgb(26, 75, 97)', 'rgb(34, 101, 129)', 'rgb(47, 138, 177)', 'rgb(94, 177, 212)'],
['rgb(34, 101, 129)', 'rgb(47, 138, 177)', 'rgb(94, 177, 212)'],
];




const RenishawBuild = (props) => {

  const [data, setData] = useState({'machine': {}});

  useEffect(() => {
    getData();
    // let names = importedSchema.pages.map(page => page.name);
    // var elements = importedSchema.pages.map(page => page.elements);
  }, []);

  function getData() {
    setData({});
    //var buildId = props.match.params.id;
    fetch(`${backUrl}rc/` + props.buildId, {
      method: "GET",
      headers: {
       "Authorization": 'Bearer ' + localStorage.getItem('token')
      }

    })
    .then(res => res.json())
    .then(json => {
      var data = json;
      setData(data);
    }
  )}

  return (

    <div className="container">
      <div className="infoContainer">
        <MachineCard data={data.machine}/>
        <BuildCard data={data.build}/>
      </div>
      <div className="infoContainer">
        <PlotCard data={data.timeseries} buildId={props.buildId}/>
      </div>
      <div className="infoContainer">
        <EventsCard data={data.events} />
      </div>
    </div>

  );
}

export default RenishawBuild;




