import React from 'react';
import { backUrl } from "../../App/constants";
import MainHeader from "../../common/nav/MainHeader";
import { Route } from "react-router-dom";
import SubNav from "../../common/nav/SubNav";
import '../../assets/css/builds.css';
import SearchBox from '../search/SearchBox';
import '../search/styles/SearchPage.css';
import { fetchProjects } from '../../common/traceapi/TraceAPI';

class Build extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProjectId: null
    };
  }

  createBuild() {
    const { selectedProjectId } = this.state;
    if (!selectedProjectId) {
      alert('must select a project');
      return;
    }

    fetch(`${backUrl}projects/${selectedProjectId}/builds`, {
      method: 'POST',
      headers:  {
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Accept": "application/json"
      }
    })
      .then(r => r.json())
      .then(j => {
        const id = j.build.id;
        this.props.history.push(`/build/${j.build.id}/0`);
      })
      .catch(e => {
	   console.error(e);
	   //this.props.history.push(`/build/undefined/0`);
      });
  }

  selectProject(event) {
    const selectedProjectId = event.target.value;
    this.setState({
      selectedProjectId: selectedProjectId
    });
  }

  render() {

    return (
      <div>
        <div className="searchTopBarWrapper">
          <SubNav section='projects' current='new-build'/>
          <SearchBox />
        </div>
        <div className="main-div-wrapper">
          <div className="maintop">
            <h1>New Build</h1>
          </div>
          <div className="pickerWrapper">
            <h2>Pick a Project to Create a Build on</h2>
            <select onChange={this.selectProject.bind(this)}>
              <option value={null}>Select</option>
            {this.props.projects.map(p =>
                  <option value={p.id}>{p.name}</option>
            )}
            </select>
            <button onClick={this.createBuild.bind(this)}>
              Create Build
            </button>
          </div>
        </div>


      </div>
    );
  }

}

export default fetchProjects(Build)