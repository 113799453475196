import React from "react";
import schema from "../../assets/json/sandvik.json";
import MainHeader from "../../common/nav/MainHeader";
import { backUrl } from '../../App/constants';

const taskList = schema.pages[1];

export default class TaskList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			level: 0,
			project: {},
			token: JSON.parse(localStorage["appState"]).user.auth_token,
		}
	}

	componentWillMount() {
		var projectId = this.props.match.params.id

		fetch(backUrl + 'project/' + projectId, { method: "POST", headers: new Headers({"Authorization": `Bearer ${this.state.token}`}) })
		.then(res => res.json())
		.then(json => {
			this.setState({ level: json.filled_steps });
		})
	}

	setTimestamp(event) {
			var	currentTime = new Date;
			var attributeTime = `${event.target.id}_time`
			var project = this.state.project

			project[attributeTime] = currentTime
			this.setState({
				project: project
			});

	}

	updateProject() {
		var projectId = this.props.match.params.id
		var project = this.state.project
		var formData = new FormData();

		// extract this
		{
			for (let key in project) {
				formData.append(key, project[key]);
			}
		}


		const url = backUrl + 'projectz/' + projectId;
		fetch(url, {
			method: "POST",
			body: formData,
		})
			.then(res => res.json())

	}

	increaseLevel() {
		var nextLevel = this.state.level + 1;
		this.setState( { level: nextLevel } );
	}

	handleAnswer(event) {
		this.increaseLevel();
		this.setTimestamp(event);
		this.updateProject();
	}

	render() {
		return(
			<div>
			<MainHeader header="Project " crumb={["Home"]} />
			{ taskList.elements.map(item =>
					<TaskItem
						level = {this.state.level}
						index = {taskList.elements.indexOf(item)}
						time = {this.state.project[item.name + "_time"]}
						item = {item}
						handleAnswer = {(event) => this.handleAnswer(event)}
					/>
			)}
			</div>
		);
	}
}

const TaskItem = props => {
	var thing;
	if (props.index == props.level ) {
		thing = "current";
	}
	else if ( props.index > props.level ) {
		thing = "none";
	}
	else {
		thing = "past";
	}

	return(
		<div className={`i${thing}`}>
			<div style={{width: "300px", marginRight: "25px"}}>{props.item.title}</div>
			<button style={{float: "left"}}id={props.item.name} onClick={props.handleAnswer}>Task Complete</button>
			<div>{ props.time && props.time.toString()}</div>
		</div>
	);
}
