import React from "react";
import Card from './Card';
import { Link } from "react-router-dom";
import './styles/FileCard.css';


export function SearchResultFileCard(props: any) {

function truncate(str) {
    if (str) {
      return str.length > 150 ? str.substring(0, 150) + "..." : str;
    }
}
  var buildlink = '/build/' + props.data.id + '/0'

  return(
      <Card title='File' buildLink={buildlink} fileLink={props.data.url}>
        <div className="contentTitle">
          <p className="contentLabel">File Name:</p>
          <h3>
  		      <a href={props.data.url} target='_blank'>
             {props.data.filename}
            </a>
          </h3>
        </div>
        <div className="inner-content-wrapper">
          <p className="contentLabel">Build:</p>
          <h4>
  		      <a href={buildlink} target='_blank'>
              {props.data.build_id}
            </a>
          </h4>

          <p className="contentLabel">Found in:</p>
          <h4>{props.data.found_type}</h4>

          <p className="contentLabel">Context:</p>
          <p>{truncate(props.data.context)}</p>
        </div>
    </Card>
  )

  }


export default SearchResultFileCard;
