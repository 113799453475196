import React from 'react';
import useTraceAPI from '../hooks/useTraceAPI';

/**
 * Higher-Order Component (HOC) that provides the fetched builds to the wrapped component.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped.
 * @returns {React.Component} - The enhanced component with builds as a prop.
 */
const fetchBuilds = WrappedComponent => {

  return props => {
    // Get API functions from the useTraceAPI hook
    const { getBuilds } = useTraceAPI();

    // State to hold the fetched builds
    const [builds, setBuilds] = React.useState([]);

    // State to hold error flag & message if fetch fails
    const [buildsError, setBuildsError] = React.useState([]);
    const [buildsErrorMessage, setBuildsErrorMessage] = React.useState([]);

    // Fetch builds once after the component mounts
    React.useEffect(() => {
      getBuilds().then(fetchedBuilds => {
        if (fetchedBuilds.success) {
          setBuilds(fetchedBuilds.data);
          setBuildsError(false);
          setBuildsErrorMessage('');
        } else {
          setBuildsError(true);
          setBuildsErrorMessage(fetchedBuilds.message);
          setBuilds([]);
        }
      });
    }, []); // The empty dependency array ensures this effect runs only once

    // Render the wrapped component with fetched builds as a prop
    return <WrappedComponent {...props} builds={builds} buildsError={buildsError} buildsErrorMessage={buildsErrorMessage} />;
  };
};

export default fetchBuilds;

