import React from "react";
import MainHeader from "../../common/nav/MainHeader";
import { docUrl } from '../../App/constants';
import SubNav from '../../common/nav/SubNav';

class Equipment extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      show: false,
      typeData: props.typeData,
        data:[],
        token: JSON.parse(localStorage["appState"]).user.auth_token
    };
  }
  changeState(){
    this.setState({show:true});
  }

  handleChange(e){
    var cool = this.state.typeData
    var {id,value} = e.target;
    cool[id] = value;
    // set the state of the material object.
    this.setState({typeData: cool});
  }

  handleFile(e){
    var fileIn = e.target.files[0];
    var typeData = this.state.typeData
    typeData["file"] = fileIn
    this.setState({typeData});
  }

  handleSubmit(e){
    e.preventDefault();
    var formData = new FormData();

    var current = this.state.typeData;

    for (let lessFunny in current ) {
      formData.append(lessFunny, current[lessFunny]);
    }



    const url = docUrl + this.props.type;
    fetch(url, {
      method: "POST",
      body: formData,
      headers: new Headers({
        'Authorization': `Bearer ${this.state.token}`
      })
    })
      .then(res => res.json())
      .then( json => this.updateData())
                        .then(() => window.location.reload())
      //.then(this.componentWillMount())
  }

  updateData(){
    var newData = this.state.data;
    var newEquipment = this.state.typeData;
    newData.push(newEquipment);
    this.setState({data:newData})
  }

  componentWillMount(){
    this.getEquipment();
  }

  delete(id){
    var url = `${docUrl}equipment/${id}`;
    var formData = new FormData();
    formData.append("id", id);
    fetch(url, {
      method: "DELETE",
      body: formData,
      headers: new Headers({ "Authorization": `Bearer ${this.state.token}`})
    })
    .then(() => window.location.reload())
  }

  getEquipment(){
    const url = docUrl + this.props.type;
    fetch(url, {
      method: "GET",
      headers: new Headers({
        'Authorization': `Bearer ${this.state.token}`
      })
    })
      .then(res => res.json())
      .then(json => {
        this.setState({
          data: json
        },
      );

      })
  }

  render(){
    return(
      <div>
        <SubNav section='documentation' current='equipment'/>
        <button className="btn" onClick={ () => this.changeState() }>Add New</button>
        { this.state.show && (< New handleSubmit = { (e) => this.handleSubmit(e) }
                                   handleChange = { (e) => this.handleChange(e) }
				typeData = {this.props.typeData}
                                   handleFile = { (e) => this.handleFile(e)}
                                   />) }
        <br/><br/><br/>
        <EquipmentList data = {this.state.data}
          delete = { (id) => this.delete(id) }/>
      </div>
    );
  }
}

// use a map -- how to do styling??
const New = props =>
      <div className = "new-doc">
        <br/>
        <form onSubmit = { props.handleSubmit}>
		{	 Object.keys(props.typeData).map( label =>
		 <label>
		   {label}
		   <input onChange= {props.handleChange}/>
		 </label>
	 ) }
          <br/><br/>
          <div className ="upload-submit-doc">
            <label className = "upload">
              Upload File...
              <input type = "file" className = "inputfile" accept=".pdf" onChange={ props.handleFile } id="file"/>
            </label>
            <input type = "submit" className ="submit-doc" />
          </div>
        </form>
    </div>

const EquipmentList = props =>
      <div>
        <table className="table-basic">
          <thead>
            <td>Vendor</td>
            <td>Machine</td>
            <td>Documentation</td>
            <td></td>
          </thead>
          <tbody>
            {props.data.map(info =>
              <tr>
                <td>
                  {info.vendor}
                </td>
                <td>
                  {info.machine}
                </td>
                <td>
                  <a href = {info.file_url} target="_blank">Click</a>
                </td>
                <td onClick={(id) => props.delete(info.id)}>Delete</td>
              </tr>)}
          </tbody>
        </table>
      </div>

export default Equipment
