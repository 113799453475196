import React from 'react';
import useTraceAPI from '../hooks/useTraceAPI';

/**
 * Higher-Order Component (HOC) that provides the fetched projects to the wrapped component.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped.
 * @returns {React.Component} - The enhanced component with projects as a prop.
 */
const fetchProjects = WrappedComponent => {

  return props => {
    // Get API functions from the useTraceAPI hook
    const { getProjects } = useTraceAPI();


    // State to hold the fetched actions
    const [projects, setProjects] = React.useState([]);

    // State to hold error flag & message if fetch fails
    const [projectsError, setProjectsError] = React.useState([]);
    const [projectsErrorMessage, setProjectsErrorMessage] = React.useState([]);

    // Fetch projects once after the component mounts
    React.useEffect(() => {
      getProjects().then(fetchedProjects => {
        if (fetchedProjects.success) { 
          setProjects(fetchedProjects.data);
          setProjectsError(false);
          setProjectsErrorMessage('');
        } else {
          setProjectsError(true);
          setProjectsErrorMessage(fetchedProjects.message);
          setProjects([]); // return empty array so downstream components don't choke and die
        }
      });
    }, []); // The empty dependency array ensures this effect runs only once

    // Render the wrapped component with fetched projects as a prop
    return <WrappedComponent {...props} projects={projects} projectsError={projectsError} projectsErrorMessage={projectsErrorMessage} />;
  };
};

export default fetchProjects;


