/**
 * Login footer 
 * 
 * Footer banner with copyright
 *
 * Usage:
 * <Footer/>
 *
 * @returns footer div
 */



/************* React imports *****************/

function Footer() {

  // used in footer
	var currentYear = new Date().getFullYear();

  return(
    <div className="home-footer">
      <span> © Copyright {currentYear} 3Degrees </span>
    </div>
  );


}
export default Footer;
