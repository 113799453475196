/**
 * Login landing page
 * 
 * The main page users hit when not logged in
 *
 * Usage:
 * <Login auth={this.authComponent.current} onLogin={() => this.checkLoginStatus()} />
 *
 * @returns login page 
 */



/************* React imports *****************/
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CompatRouter } from 'react-router-dom-v5-compat';



/************* Component-specific imports *****************/
import HomeContent from './HomeContent.jsx';
import TopBar from "../../common/nav/TopBar";
import { useAuth } from '../../common/auth/index.jsx';


import '../../index.css'

function Home () {

  const {checkExistingSession} = useAuth()
  const location = useLocation()

  useEffect(() => {
    let valid = checkExistingSession(); // Wait for checkExistingSession to complete

    if (!valid) { 
      //Logout user and redirect to homepage if session is expired
      console.log("Session has been invalidated")
      logout();
      window.location = homeUrl; 
    } 
  }, [location]) //useEffect with location causes this code to run every time the browser location changes

  return (
    <div className='LoggedInContainer'>
      <TopBar logout={() => this.logOut()} /> 
      <div className="main-page-wrapper">
        <HomeContent />
      </div>
    </div>
  );
}


export default Home;
