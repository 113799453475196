/****************************************
 * Main module for rendering TraceAM 
 *
 *
 *
 *
 * **************************************/

/******* react components **********/
import React from "react";
import { Route, Switch } from "react-router-dom";
import { CompatRoute } from "react-router-dom-v5-compat";

/******* styles **********/
/* not needed? todo: deprecate */
/* import styled from 'styled-components'; */

/******* app components **********/
import XLSX from 'xlsx';
import { backUrl } from "../../App/constants";
import  { fetchBuildsPartsProjectsAndTemplates } from "../../common/traceapi/TraceAPI.jsx";


/******* documentation components **********/
import Materials from "../../modules/documentation/Materials";
import Standards from "../../modules/documentation/Standards";
import OperatingProcedures from "../../modules/documentation/OperatingProcedures";
import Maintenance from "../../modules/documentation/Maintenance";
import Equipment from "../../modules/documentation/Equipment2";
import Documentation from '../../modules/documentation/Documentation';

/******* miscellaneous components **********/
import Quote from '../../common/Quote';
import Pivot from '../../modules/viz/Pivot';
import Search from '../../modules/search/Search';
import TaskList from "../../modules/question_set/TaskList";

/******* materials components **********/
import MaterialsIndex from '../../modules/materials/Index';
import MaterialsNew from '../../modules/materials/New';
import MaterialsShow from '../../modules/materials/Show';

/******* dashboard components **********/
import Dashboard from "../../modules/dashboard";
import Builddata from "../../modules/builddata/Builddata";

/******* chart components **********/
import Charts from "../../modules/Charts";
import ChartBuilder from "../../modules/Charts/Builder";


/******* users and teams **********/
import Teams from '../../common/auth/Teams';
import NewTeam from '../../common/auth/NewTeam';
import NewUser from '../../common/auth/NewUser';


/******* projects **********/
import ProjectsMain from "../../modules/projects/ProjectsMain";
import Create from "../../modules/projects/Create";
import Show from "../../modules/projects/Show";
import ShowProjects from "../../modules/projects/ShowProjects";
import Active from "../../modules/projects/Active";
import Complete from "../../modules/projects/Complete";
import Template from "../../modules/projects/Template";
import TemplateHome from "../../modules/projects/TemplateHome";
import Build from "../../modules/projects/Build";
import Browse from "../../modules/projects/Browse";
import ShowProject from "../../modules/projects/ShowProject";
import RecentBuilds from "../dashboard/RecentBuilds";

import "../../assets/css/Main.css"
import "../../assets/css/cardstyles.css"
import "../../assets/css/builds.css"


class HomeContent extends React.Component {

  constructor(props) {
    super(props);
  }



  generateXLSX = (data, fileName) => {
    var worksheet = XLSX.utils.aoa_to_sheet(data);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  }

  downloadPDF(p, type) {
    var id = p.match.params.id;
    if ( type == 'full' ) {
      window.location = `${backUrl}/api/project/${id}/full`;
    } else {
      window.location = `${backUrl}/api/project/${id}/pdf`;
    }
  }


  render() {
    // get data from composed HOCs
    const { builds, parts, projects, templates } = this.props;

    var b = builds;

    // remap builds for convenience of sorting functionality
    for (let i = 0; i < b.length; i++) {
      if (b[i].project && b[i].project.name) {
        b[i].project_name = b[i].project.name;
      }
    }

    const mappedbuilds = b;
 
    // derived project vars
    const activeProjects = projects.filter(p => p.is_complete !== true);
    const numberActiveProjects = activeProjects.length;
    const completedProjects = mappedbuilds.filter((p) => p.is_complete == true).length

    return(
      <div className="maindiv">
          

        <Switch>

        <Route path="/operating_procedures"
            render= {()=>
              <Documentation
                type='operating_procedures'
                typeData={[
                  {label: 'Number', name: 'op_number'},
                  {label: 'Title', name: 'title' }
                ]}
              />
            }
        />

          <Route path="/maintenance"
            render={() =>
              <Documentation
              type='maintenance'
              typeData={[
                {label: 'Date', name: 'maintenance_date'},
                {label: 'Machine', name: 'machine' }
              ]}
            />
            }
          />

          <Route path="/documentation/training"
            render={()=>
              <Documentation
                type='trainings'
                typeData={[
                  {label: 'Date', name: 'date', type: 'date'},
                  {label: 'Operator', name: 'operator' },
                  {label: 'Records', name: 'records' },
                  {label: 'Type/Machine', name: 'type_machine' },

                ]}
              />
            }
          />

          <Route path="/documentation/materials"
            render={()=>
              <Documentation
                type='materials'
                typeData={[
                  {label: 'Type', name: 'material_type'},
                  {label: 'Vendor', name: 'vendor' },
                  {label: 'Process', name: 'process' }
                ]}
              />
            }
          />

          <Route path="/standards"
            render={()=>
              <Documentation
                type='standards'
                typeData={[
                  {label: 'Number', name: 'standard_number'},
                  {label: 'Title', name: 'title' },
                ]}
              />
            }
          />

          <Route path="/equipment"
            render={()=>
              <Documentation
              type='equipment'
              typeData={[
                {label: 'Vendor', name: 'vendor'},
                {label: 'Machine', name: 'machine' }
              ]}
              />
            }
          />

          {/*The /projects route seems to be unused and broken*/}
          <Route exact path ="/projects" component={ProjectsMain} />

          <Route exact path="/dashboard"
            render={() => <Dashboard activeProjects={activeProjects} projects={projects} complete={completedProjects} active={numberActiveProjects}/>}/>

          <Route exact path="/home"
            render={() => <Dashboard activeProjects={activeProjects} projects={projects} complete={completedProjects} active={numberActiveProjects} builds={mappedbuilds} parts={parts}/>}/>


         {/* this.state.admin &&  <Route exact path='/teams' render={ () => <Teams projects={this.state.allProjects}/> } />  */ }
         {/* this.state.admin &&  <Route path='/teams/new' render= {() =>  <NewTeam projects={this.state.allProjects} user={this.props.user}/>} /> */ }
         {/* this.state.admin &&  <Route path='/users/new' render= { props => <NewUser register={ this.props.register }/>} /> */ }


        <Route exact path='/browse' render={ () => <Browse />} />
        <Route exact path='/charts' render={ () => <Charts />} />
        <Route exact path='/charts/builder' render={ () => <ChartBuilder />} />
	<Route exact path="/builddata" render={() => <Builddata />} />

	<Route exact path="/renishaw" render={() => <RecentBuilds />} />
	<Route exact path="/materials" render={() => <MaterialsIndex />} />
	<Route exact path="/materials/new" render={ (props)=> <MaterialsNew {...props}/>} />
	<Route path="/materials/:id" render={ props => <MaterialsShow {...props}/>} />

          <Route path="/projects/active" render={ () => <Show xlsx={this.generateXLSX} type="active" /> } />
          <Route path="/projects/complete" render={ () => <Show  xlsx={this.generateXLSX} type="complete" /> } />
          <Route path="/projects/all" render={ () => <ShowProjects  xlsx={this.generateXLSX} /> } />
          <Route path="/projects/new" render = { props => <Create {...props} templates={templates}/>} />
          <Route path="/projects/new/tasks" component={ TaskList } />
          <Route path="/build/:id" render={ props => <Active {...props} projects={projects}/> } />
          <Route path="/project/:id" render = { props => <ShowProject {...props} />} />

          <Route path="/quote" component = { Quote } />

          <Route path="/complete_build/:id"
                 render={ (props) =>
                  <Complete {...props}
                    fullPDF = { () => this.downloadPDF(props, 'full')}
                    execPDF = { () => this.downloadPDF(props, 'exec')}
                    projects= { projects }
                    templates = { templates }
                  />}
          />

          <Route path='/templates' render = {() => < TemplateHome/>} />
          <Route path="/template/edit/:id" render = { props => <Template {...props}/> } />
          <Route path='/search' render = { () => <Search />} />
          <Route path='/viz' render = { () => <Pivot />} />
          <Route path='/builds' render={(props) => <Build {...props}/>} />
          { false &&          <Route path='/builds' component={null} /> }
        </Switch>
      </div>
    );
  }
}

export default fetchBuildsPartsProjectsAndTemplates(HomeContent);
