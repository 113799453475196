import React from "react";
import Card from './Card';
import { Link } from "react-router-dom";


export function SearchResultProjectCard(props: any) {
  var buildlink = '/build/' + props.data.id + '/0'
  return(

      <Card title='Project' buildLink={buildlink}>
        <div className="contentTitle">
          <p className="contentLabel">Project Name:</p>
          <h3>
		        <a href={buildlink} target='_blank'>
              {props.data._source.name}
            </a>
          </h3>
          <h4>
          </h4>
        </div>

        <div className="inner-content-wrapper">
          <p className="contentLabel">Lead Investigator:</p>
          <h4>{props.data._source.lead_investigator}</h4>
          <p className="contentLabel">Organization:</p>
          <h4>{props.data._source.lead_investigator_organization}</h4>
          <p className="contentLabel">Partners:</p>
          <h4>{props.data._source.parnters}</h4>
          <p className="contentLabel">Completed:</p>
          <h4>{props.data._source.completion_date}</h4>
        </div>

      </Card>

  )}


export default SearchResultProjectCard;
