/**
 * Button Component
 * 
 * A reusable button component with a click event handler and custom cursor style.
 *
 * Usage:
 * <Button onClick={handleClick}>Click me!</Button>
 *
 * @param {Object} props - The component props
 * @param {function} props.onClick - The function to be called when the button is clicked
 * @param {React.ReactNode} props.children - The content nested inside the button component
 * @returns {JSX.Element} The rendered button component
 */

import React from 'react';

const Button = (props) => {
  // Destructure props for better readability
  const { onClick, children } = props;

  // Event handler for button click
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Render the button component
  return (
    <button onClick={handleClick} style={buttonStyle}>
      {children}
    </button>
  );
};

// Define the style object for the button
const buttonStyle = {
  cursor: 'pointer'
};

export default Button;

