import React from 'react';
import styled from 'styled-components';
import '../../assets/css/Main.css';
import Nav from './Nav';
import { Link } from "react-router-dom";


const TopBar = props =>{
  return(
    <div className='top-header-wrapper'>
      <div className={'top-header'}>
        <Link to="/">
          <h1>TRACE<i>AM</i></h1>
        </Link>
        <Nav/>
      </div>
    </div>
  )
}


export default TopBar;
