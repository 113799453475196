import React from 'react';
import styled from 'styled-components';

const FileIcon = styled.i.attrs({
  className: 'fa fa-file'
})`
  color: lightgrey;
  &:hover {
    cursor: pointer;
    color: blue;
  }
`
const FileIconComponent = () => <FileIcon />

export default FileIconComponent;
