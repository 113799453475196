import React from 'react';
import styled from 'styled-components';
import MainHeader from '../nav/MainHeader';
//import { withRouter } from 'react-router-dom'
//withRouter from react-router-dom no longer exists, I am replacing it with
//a replacement found from stackoverflow and the reactrouter website
//https://stackoverflow.com/questions/66465750/withrouter-is-not-exported-from-react-router-dom
//https://reactrouter.com/en/main/start/faq
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom-v5-compat';
function withRouter(Component) {
	function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
}

var Container = styled.div`
  display: flex;
  margin-left: 20px;
  margin-top: 30px;
`
var NewUserForm = styled.form`
  display: flex;
  flex-direction: column;
  label {
    margin-top: 10px;
  }
`

var UserCreateInput = styled.input`
  width: 300px;
`

class NewUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      email: null,
      password: null,
      password2: null
    };
  }

  register(e) {
    e.preventDefault();
    this.props.register(this.state.userName, this.state.email, this.state.password)
    this.props.history.push('/teams');
  }

  render() {
    return (
    <div>
    <MainHeader header='Create User' crumb={['Home', 'Teams']} />
    <Container>
      <NewUserForm onSubmit={e => this.register(e)}>
        <label> Name </label>
        <UserCreateInput onChange={ e => this.setState({userName: e.target.value })} />
        <label> Email </label>
        <input onChange={ e => this.setState({email: e.target.value })} />
        <label> Password </label>
        <input type='password' onChange={ e => this.setState({password: e.target.value})} /> 
        <label> Repeat Password </label>
        <input type='password' onChange={ e => this.setState({password2: e.target.value})} /> 
        <button style={{width: '100px', marginTop: '20px'}}onClick= {e => this.register(e)} >Create User</button>
      </NewUserForm>
    </Container>
    </div>
    );
  }
}

export default withRouter(NewUser);
