import React from "react";
import Card from '../../common/Card';

const StatsCard = props => {
  return (
    <Card noMargin={true} half={true}>
      <div className="statswrapper">
        <h3>{props.title}</h3>
        <h3 className="stat">{props.stat}</h3>
      </div>
    </Card>
  )
}

export default StatsCard;
