import React from "react";

/**
 * A reusable TextArea component for forms.
 * @param {Object} props - Component props
 * @param {string} props.label - The label for the TextArea.
 * @param {string} props.name - The name attribute for the TextArea.
 * @param {string} props.value - The value of the TextArea.
 * @param {function} props.onChange - The onChange event handler for the TextArea.
 * @returns {JSX.Element} - Rendered TextArea component
 */
const TextArea = (props) => (
  <div className="form-row">
    {/* Label */}
    <label className="form-label">
      {props.label}
    </label>
    <div className="form-field">
      {/* TextArea Input */}
      <textarea
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      ></textarea>
      {/* Tip or Subhead */}
      <small className="hide">tip/subhead</small>
    </div>
  </div>
);

export default TextArea;

