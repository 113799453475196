import React from "react";
import MainHeader from "../../common/nav/MainHeader";
//import MaterialsJSON from "../asset/materials.json";
import upload from "../../assets/images/upload.png";
import { docUrl, backUrl } from '../../App/constants';
import SubNav from '../../common/nav/SubNav';

const crumb = ["Home", "Standards"]
//const materials = MaterialsJSON.materials;

// Materials should be stateful
// Materials array. [m1, m2] server
// iterate over materials array and display on screen.
// ( Materials List component )

class Standards extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			show: false,
			standard:
				{
					standard_number:null,
					title:null,
					file:null
				},
				data:[],
				token: JSON.parse(localStorage["appState"]).user.auth_token
		};
	}
	changeState(){
		this.setState({show:true});
	}

	updateData(){
		var newData = this.state.data;
		var newStandard = this.state.standard;
		newData.push(newStandard);
		this.setState({data:newData})
	}

	handleChange(e){
		var standard = this.state.standard;
		var {id,value} = e.target;
		standard[id] = value;
		// set the state of the material object.
		this.setState({standard});
	}

	handleFile(e){
		var fileIn = e.target.files[0];
		var standard = this.state.standard
		standard["file"] = fileIn
		this.setState({standard});
	}

	handleSubmit(e){
		e.preventDefault();
		var formData = new FormData();

		var currentStandard = this.state.standard;

		for (let lessFunny in currentStandard ) {
			formData.append(lessFunny, currentStandard[lessFunny]);
		}



		const url = docUrl + 'standards'
		fetch(url, {
			method: "POST",
			body: formData,
			headers: new Headers({
				'Authorization': `Bearer ${this.state.token}`
			})
		})
			.then(res => res.json())
			.then(json => this.updateData())
			.then(() => window.location.reload())
	}

	updateData(){
		var newData = this.state.data;
		var newStandard = this.state.standard;
		newData.push(newStandard);
		this.setState({data:newData})
	}

        /*
	componentDidUpdate(){
                console.log('infnite loop');
		this.getStandards();
	}
        */

	componentWillMount(){
		this.getStandards();
	}

	getStandards(){
		const url = backUrl + 'standards'
		fetch(url, {
			method: "GET",
			headers: new Headers({
				'Authorization': `Bearer ${this.state.token}`
			})
		})
			.then(res => res.json())
			.then(json => {
				this.setState({
					data: json
				},
			);

			})
	}

	delete(id){
		var url = `${docUrl}standards/${id}`;
		var formData = new FormData();
		formData.append("id", id);
		fetch(url, {
			method: "DELETE",
			body: formData,
			headers: new Headers({ "Authorization": `Bearer ${this.state.token}`})
		})
		.then(() => window.location.reload())
	}

	render(){
		return(
			<div>
				<SubNav section='documentation' current='standards'/>
				<div>
					<button className="btn" onClick={ () => this.changeState() }>Add New</button>
					{ this.state.show && (< New handleSubmit = { (e) => this.handleSubmit(e) }
					   												 handleChange = { (e) => this.handleChange(e) }
																		 handleFile = { (e) => this.handleFile(e) }/>) }
					<br/><br/><br/>
					<StandardsList data = {this.state.data}
												 delete = { (id) => this.delete(id) } />
				</div>
			</div>
		);
	}
}

const New = props =>
			<div className = "new-doc">
				<br/>
				<form onSubmit = { props.handleSubmit}>
					<label>Number<input onChange={ props.handleChange } id="standard_number"/></label>
					<label>Title<input onChange={ props.handleChange } id="title"/></label>
					<br/><br/>
					<div className ="upload-submit-doc">
						<label className = "upload">
							Upload File...
							<input type = "file" className = "inputfile" accept=".pdf" onChange={ props.handleFile } id="file"/>
						</label>
						<input type = "submit" className ="submit-doc" />
					</div>
				</form>
		</div>

const StandardsList = props =>
			<div>
				<table className="table-basic">
					<thead>
						<td>Number</td>
						<td>Title</td>
						<td>Documentation</td>
						<td></td>
					</thead>
					<tbody>
						{props.data.map(info =>
							<tr>
								<td>
									{info.standard_number}
								</td>
								<td>
									{info.title}
								</td>
								<td>
									<a href = {info.file_url} target="_blank">View</a>
								</td>
								<td onClick={(id) => props.delete(info.id)}>Delete</td>
							</tr>)}
					</tbody>
				</table>
			</div>

export default Standards
