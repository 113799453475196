import React from "react";
import styles from './styles.css';

const MachineCard = (props) => {

  return (
    <div className="machineCard">
       <h2 className="card-title">Made on {props && props.data && props.data.name}</h2>


       <table className="renTable">
         <tr>
           <td className="renCol1"><b>Make</b></td>
           <td className="renCol2">
             {props && props.data && props.data.make}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>Model</b></td>
           <td className="renCol2">
             {props && props.data && props.data.model}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>Last Status</b></td>
           <td className="renCol2">
             {props && props.data && props.data.machineStatus}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>Serial No.</b></td>
           <td className="renCol2">
             {props && props.data && props.data.serialNumber}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>UUID</b></td>
           <td className="renCol2">
             {props && props.data && props.data.id}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>IP Address</b></td>
           <td className="renCol2">
             {props && props.data && props.data.ipAddress || 'unknown'}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>Location ID</b></td>
           <td className="renCol2">
             {props && props.data && props.data.locationId || 'unknown'}
           </td>
         </tr>
       </table>

    </div>


  )
}

export default MachineCard;




