import React from 'react';
import styled from 'styled-components';
import { backUrl } from '../../App/constants';
import MainHeader from '../nav/MainHeader';
import { BasicTable } from './Teams';
//import { withRouter } from 'react-router-dom'
//withRouter from react-router-dom no longer exists, I am replacing it with
//a replacement found from stackoverflow and the reactrouter website
//https://stackoverflow.com/questions/66465750/withrouter-is-not-exported-from-react-router-dom
//https://reactrouter.com/en/main/start/faq
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom-v5-compat';
function withRouter(Component) {
	function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
}

var Label = styled.label`
  min-width: 140px
`

var InputRow = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey
  @media ( max-width: 768px ) {
    flex-direction: column;
    label {
      margin-bottom: 10px;
    }
  }
`

const Add = props =>
<i id={props.id} className='fa fa-square-o' />

const Remove = props =>
<i id={props.id} className='fa fa-check-square-o' />


// if team, grey out next button 
const NameInput = props => 
  <InputRow>
    <Label> Team Name* </Label>
	  <input style={{width: '300px'}}onChange={props.onChange} />
  </InputRow>

const ProjectsInput = props =>
<InputRow>
  <Label>Project Access</Label>
  <BasicTable>
    <thead>
      <td>Process Title</td>
      <td>Access</td>
      <td>Add</td>
    </thead>
    { props.projects.map(p => 
      <tr>
        <td>{p.proc_title}</td>
        <td>{ p.team.length != 0 ? 'permission required' : 'shared' } </td>
        <td style={{textAlign: 'center'}} id={p.id} onClick={props.toggle}>
          { props.projectsToAdd.includes(Number(p.id)) ? <Remove id={p.id}/> : <Add id={p.id}/>}
        </td>
      </tr>
    )}
  </BasicTable>
</InputRow>

class NewTeam extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			teamName: null,
			team: null,
			users: [],
			usersToAdd: [],
      projectsToAdd: []
		}
	}

	componentDidMount() {
		fetch('http://3.17.141.219:8000/api/users', {
			headers: {
				"Authorization": `Bearer ${this.props.user.user.auth_token}`
		}
    })
		.then(a => a.json())
		.then(a => this.setState({users: a}));
	}

	addUsersToTeam() {
		var input = new FormData();
		input.append('users', JSON.stringify(this.state.usersToAdd));

		fetch(backUrl + 'api/team/' + this.state.team.id + '/user', {
			method: 'POST',
			headers: {
				"Authorization": `Bearer ${this.props.user.user.auth_token}`
		},
			body: input
	}).then(a => a.json())
		.then( a => {
      console.log(a) ;
      this.addProjectsToTeam();
    })
	}

  //
  addProjectsToTeam() {
    var input = new FormData();
    input.append('projects', JSON.stringify(this.state.projectsToAdd));

    fetch(`${backUrl}api/team/${this.state.team.id}/project`, {
      method: 'POST',
      headers: { 
				'Authorization': `Bearer ${this.props.user.user.auth_token}`
		  },
			body: input
    }).then(a => this.props.history.push('/teams'));
  } 

	toggleUser(id) {
		var usersToAdd = this.state.usersToAdd;
		usersToAdd.includes(id) ? usersToAdd = usersToAdd.filter(u => u != id) : usersToAdd.push(id)
		this.setState({usersToAdd}, () => console.log(this.state));
	}

	toggleProjects(id) {
    console.log(id.target.id);
    id = id.target.id
		var projectsToAdd = this.state.projectsToAdd;
		projectsToAdd.includes(Number(id)) ? projectsToAdd = projectsToAdd.filter(t => t != id) : projectsToAdd.push(Number(id))
		this.setState({projectsToAdd}, () => console.log(this.state));
	}

	createTeam() {
    if ( !this.state.teamName ) {
      alert('you must give the team a name');
      return null;
    }

    if ( !this.state.usersToAdd.length ) {
      alert('Team must have at least one user');
      return null;
    }

		var argh = new FormData();
		argh.append('name', this.state.teamName);
		argh.append('creator_id', this.props.user.user.id);
		fetch(backUrl + 'api/teams', {
			method: 'POST',
			headers: {
				"Authorization": `Bearer ${this.props.user.user.auth_token}`
			},
			body: argh
		}).then(a => a.json())
		  .then(a => this.setState({team: a}))
      .then(a => this.addUsersToTeam());
	}

	render() {
	return(
<div>
  <MainHeader header='Create Team' crumb={['Home', 'Teams']} />

  <NameInput onChange={(a) => this.setState({teamName: a.target.value})} />
  <InputRow>
      <Label >Add Users*</Label>
			<BasicTable>
				<thead>
					<td>Name</td>
					<td>Email</td>
					<td>Add</td>
				</thead>
				{ this.state.users.map(u => 
					<tr>
						<td style={{minWidth: '200px'}}>{u.name}</td>
						<td>{u.email}</td>
						<td style={{textAlign: 'center'}}onClick={ () => this.toggleUser(u.id) }> {this.state.usersToAdd.includes(u.id) ? <Remove /> : <Add /> }</td>
					</tr>
				)}
			</BasicTable>
</InputRow>
  <ProjectsInput projects={this.props.projects || []}
    toggle = {(e) => this.toggleProjects(e)}
    projectsToAdd = { this.state.projectsToAdd }
  />
  <button style={{float: 'none', marginLeft: '20px', marginTop: '20px'}} onClick={() => this.createTeam()}>Create</button>

</div>); 
}
}

export default withRouter(NewTeam);
