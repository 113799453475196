import React from "react";
import '../assets/css/cardstyles.css'


/**
 * Card component renders a card with optional title and content.
 * It applies different styles based on the props passed.
 * 
 * Props:
 * - noMargin: Boolean (optional) - If true, removes large margins from card (Used in dashboard).
 * - half: Boolean (optional) - If true, styles the card to be small.
 * - full: Boolean (optional) - If true, styles the card to be full sized.
 * - title: String (optional) - The title to be displayed in the card.
 */
const Card = (props) => {
  // Define the initial classNames based on props
  let classNames = props.noMargin ? "dashboard-card" : "card";

  // Add css classes based on selected card size from props
  if (props.half === true) {
    classNames += " sm-card";
  } else if (props.full === true) {
    classNames += " bg-card";
  }

  return (
    <div className={classNames}>
      {/* Render the title if not a 'half' card */}
      {!props.half && <h2 className="card-title">{props.title}</h2>}

      {/* Render the children components */}
      {props.children}
    </div>
  );
};

export default Card;
