import React from "react";
import MainHeader from "../../common/nav/MainHeader";
//import MaterialsJSON from "../asset/materials.json";
import upload from "../../assets/images/upload.png";
import { docUrl} from "../../App/constants";
import SubNav from '../../common/nav/SubNav';

const crumb = ["Home", "Operating Procedures"]
//const materials = MaterialsJSON.materials;

// Materials should be stateful
// Materials array. [m1, m2] server
// iterate over materials array and display on screen.
// ( Materials List component )

class OperatingProcedures extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			show: false,
			operatingProcedures:
				{
					op_number:null,
					title:null,
					file:null
				},
				data:[],
                                idsToDelete: [],
				token: JSON.parse(localStorage["appState"]).user.auth_token,
		};
	}
	changeState(){
		this.setState({show:true});
	}

	handleChange(e){
		var operatingProcedures = this.state.operatingProcedures;
		var {id,value} = e.target;
		operatingProcedures[id] = value;
		// set the state of the material object.
		this.setState({operatingProcedures}, () => console.log(this.state.operatingProcedures));
	}

	handleFile(e){
		var fileIn = e.target.files[0];
		var operatingProcedures = this.state.operatingProcedures
		operatingProcedures["file"] = fileIn
		this.setState({operatingProcedures});
	}

        // cycle through the types and return false if one isn't there.
        validateInput() {
                for (let prop in this.state.operatingProcedures ) {
                        if (!this.state.operatingProcedures[prop]) {
                                return false;
                        }
                }
                return true
        }

	handleSubmit(e){
		e.preventDefault();
                this.validateInput()
		var formData = new FormData();
		// Name to long, but fix later
		var currentOperatingProcedures = this.state.operatingProcedures;

		for (let lessFunny in currentOperatingProcedures ) {
			formData.append(lessFunny, currentOperatingProcedures[lessFunny]);
		}



		const url = docUrl + 'operating_procedures';
		fetch(url, {
			method: "POST",
			body: formData,
			headers: new Headers({
				'Authorization': `Bearer ${this.state.token}`
			})
		})
			.then(res => res.json())
			.then(json => this.updateData())
			.then(() => window.location.reload())
	}

	updateData(){
		var newData = this.state.data;
		var newOperatingProcedure = this.state.operatingProcedures;
		newData.push(newOperatingProcedure);
		this.setState({data:newData})
	}

	componentWillMount(){
		this.getEquipment();
	}

	getEquipment(){
		const url = docUrl + 'operating_procedures';
		fetch(url, {
			method: "GET",
			headers: new Headers({
				'Authorization': `Bearer ${this.state.token}`
			})
		})
			.then(res => res.json())
			.then(json => {
				this.setState({
					data: json
				},
			);

			})
	}

        promptDelete(id) {
                var idsToDelete = this.state.idsToDelete;
                idsToDelete.push(id);
                this.setState({ idsToDelete: idsToDelete });
        }

        removeIdFromDelete(idToRemove) {

                var idsToDelete = this.state.idsToDelete;
                idsToDelete = idsToDelete.filter(id => id !== idToRemove);
                this.setState({ idsToDelete: idsToDelete }, () => console.log(this.state));
        }

	delete(id){
		var url = docUrl + 'operating_procedures/' + id;
		var formData = new FormData();
		formData.append("id", id);
		fetch(url, {
			method: "DELETE",
			body: formData,
			headers: new Headers({ "Authorization": `Bearer ${this.state.token}`})
		})
		.then(() => window.location.reload())
	}


	render(){
		return(
			<div>
			  <SubNav section='documentation' current='operating'/>
				<MainHeader header="Operating Procedures" crumb={ crumb } />
				<button className="submit-doc" onClick={ () => this.changeState() }>Add New</button>
				{ this.state.show && (< New handleSubmit = { (e) => this.handleSubmit(e) }
				   												 handleChange = { (e) => this.handleChange(e) }
																	 handleFile = {(e) => this.handleFile(e)}/>) }
				<br/><br/><br/>
				<ProcedureList data = {this.state.data}
					delete = { (id) => this.delete(id) }
                                        promptDelete = { id => this.promptDelete(id) }
                                        removeIdFromDelete = {id => this.removeIdFromDelete(id)}
                                        idsToDelete = { this.state.idsToDelete } />
			</div>
		);
	}
}

const New = props =>
			<div className = "new-doc">
				<br/>
				<form onSubmit = { props.handleSubmit}>
					<label>Number<input onChange={ props.handleChange } id="op_number"/></label>
					<label>Title<input onChange={ props.handleChange } id="title"/></label>
					<br/><br/>
					<div className ="upload-submit-doc">
						<label className = "submit-doc">
							Upload File...
							<input type = "file" className = "inputfile" accept=".pdf" onChange={ props.handleFile } id="file"/>
						</label>
						<input type = "submit" className ="submit-doc" />
					</div>
				</form>
		</div>

const ProcedureList = props =>
			<div>
				<table className="table-basic">
					<thead>
						<td>Number</td>
						<td>Title</td>
						<td>Documentation</td>
						<td></td>
					</thead>
					<tbody>
                                                { false && <xomment> THE NO UPLOADED THIGNDS SHOULD BE OUTSIDE OF TABLE</xomment>}
                                                { props.data.length == 0 && <tr><td>You do not have any Operating Procedures currently</td></tr>}
                                                { props.data.length > 0 &&
						props.data.map(info =>
							<tr>
								<td>
									{info.op_number}
								</td>
								<td>
									{info.title}
								</td>
								<td>
									<a href = {info.file_url} target="_blank">View</a>
								</td>
                                                                { props.idsToDelete.includes(info.id) && <td><span className="action-link" onClick={id => props.delete(info.id)}>Comfirm deletion</span>  <span onClick={id => props.removeIdFromDelete(info.id)}className="action-link">no</span></td> }
								{ !props.idsToDelete.includes(info.id) && <td className="action-link" onClick={(id) => props.promptDelete(info.id)}>Delete</td> }
							</tr>)}
					</tbody>
				</table>
			</div>

export default OperatingProcedures
