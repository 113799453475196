import React from 'react';
import Card from '../../common/Card';

var imgStyle = {
        height: '100px',
	marginLeft: '20px'
}

class RecentBuilds extends React.Component { 
  constructor(props) { 
    super(props); 
  }

  //todo: can we deprecate this?
  //componentDidMount() { }

  iframeSrc() {
  //return {__html: '<iframe frameborder=0 src="https://afrlrw.traceam.com/ok.html" width="99%" height="450" scrolling="no"></iframe>'};
  return {__html: '<iframe frameborder=0 src="/infiniam_builds-v2.html" width="99%" height="450" scrolling="no"></iframe>'};

  }



  render() {
    return(
      <Card title='Process Build Parameters'>
            <div style={{display: 'flex' }}>
             <div style={{border: 'none'}, {width: '100%'}} dangerouslySetInnerHTML={ this.iframeSrc() } />
            </div>
      </Card>
    )
  }
}



export default RecentBuilds;
