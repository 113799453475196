import React from "react";
import styles from './styles.css';
import Plot from 'react-plotly.js';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const MyChartCard = ({ id, data, layout, deleteChart, duplicateChart }) => {

  const history = useHistory();
  
  const noop = () => {
    toast.error('This feature is not yet implemented');
  }

  const edit = (id) => {
    history.push('/charts/builder?chart_id=' + id);
  };



  const myLayout = { ...layout, autosize: true };



  return (
    <div className="myChartCard">
      <Plot className="myChartPlot" data={data} layout={myLayout} width={'300px'} height={'300px'} />

      <div className="myChartButtonRow">
        <button className="myChartActiveButton" onClick={() => duplicateChart(id)}>Duplicate Chart</button>
        <button className="myChartActiveButton" onClick={() => edit(id)}>Open in Editor</button>
        <button className="myChartActiveButton" onClick={() => deleteChart(id)}>Delete Chart</button>
        <button className="myChartInactiveButton" onClick={noop}>Add to Dashboard</button>
      </div>

      <ToastContainer 
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme={'light'}
      />

    </div>


  )
}

export default MyChartCard;




