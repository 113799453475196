import React, { Component } from 'react';
import { Link } from "react-router-dom";
import '../../assets/css/Main.css';

export default class SubNav extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }


  render(){
    return(
      <div className="subNavWrapper">

          { this.props.section === 'projects' &&
            <div className="subNav">
              <div className={this.props.current === 'new-project' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/projects/new">New Project</Link>
              </div>
              <div className={this.props.current === 'new-build' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/builds">New Build</Link>
              </div>
              <div className={this.props.current === 'all-projects' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/projects/all">All Projects</Link>
              </div>
              <div className={this.props.current === 'active-builds' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/projects/active">Active Builds</Link>
              </div>
              <div className={this.props.current === 'complete-builds' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/projects/complete">Complete Builds</Link>
              </div>
              <div className={this.props.current === 'templates' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/templates">Templates</Link>
              </div>
            </div>

          }

          { this.props.section === 'explorer' &&
            <div className="subNav">
             { /* TODO: uncomment when search page is working again
              <div className={this.props.current === 'search' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/search">Search</Link>
              </div>
	      */ }
              <div className={this.props.current === 'mycharts' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/charts">My Charts</Link>
              </div>
              <div className={this.props.current === 'builder' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/charts/builder">Chart Builder</Link>
              </div>
            </div>
          }

          { this.props.section === 'documentation' &&
            <div className="subNav">
              <div className={this.props.current === 'standards' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/standards">standards</Link>
              </div>
              <div className={this.props.current === 'materials' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/documentation/materials">materials</Link>
              </div>
              <div className={this.props.current === 'trainings' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/documentation/training">training</Link>
              </div>
              <div className={this.props.current === 'operating_procedures' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/operating_procedures">operating procedures</Link>
              </div>
              <div className={this.props.current === 'maintenance' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/maintenance">maintenance</Link>
              </div>
              <div className={this.props.current === 'equipment' ? 'subNavItem activeSubItem' : 'subNavItem'}>
                <Link to="/equipment">equipment</Link>
              </div>
            </div>
          }
      </div>
    )
  }
}
