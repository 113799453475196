import React from 'react';

export default class Quote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showQuote: false,
      machines: [
{
  name: 'Ultimaker 3',
  supportMaterialPercent: 0.3,
  purchaseCost: 3499,
  machineLifetime: 7,
  costOfCapital: 0.05,
  infrastructureCost: 0.05,
  maintenance: 0,
  buildArea: 462,
  buildHeight: 20,
  rate: 29,
  uptime: 0.8,
  packingPolicy: 2,
  packingFraction: 0.9,
  recyclingFraction: 1,
  additionalOperatingCost: 0,
  consumableCostPerBuild: 0.58,
  firstTimeBuildPrep: 1,
  subsequentBuildPrep: 0,
  timePerBuildSetup: 0.5,
  timePerBuildRemoval: 0.5,
  timePerMachineWarmUp: 0.15,
  timePerMachineCoolDown: 0.1,
  supportRemovalTimeLabor: 0.05,
  hoursPerDay: 18,
  daysPerWeek: 5,
  ftePerMachineSupervised: 0.05,
  fteForBuildExchange: 1,
  fteForSupportRemoval: 1,
  fteSalaryEngineer: 70,
  fteSalaryOperator: 40,
  fteSalaryTechnician: 30
},
{
  name: 'Expensive',
  supportMaterialPercent: 0.3,
  purchaseCost: 1758000,
  machineLifetime: 7,
  costOfCapital: 0.05,
  infrastructureCost: .5,
  maintenance: .05,
  buildArea: 1600,
  buildHeight: 40,
  rate: 107,
  uptime: 0.8,
  packingPolicy: 2,
  packingFraction: 0.9,
  recyclingFraction: 0.9,
  additionalOperatingCost: 20,
  consumableCostPerBuild: 25,
  firstTimeBuildPrep: 3,
  subsequentBuildPrep: 0,
  timePerBuildSetup: 0.75,
  timePerBuildRemoval: 0.5,
  timePerMachineWarmUp: 0.5,
  timePerMachineCoolDown: 0.5,
  supportRemovalTimeLabor: 0.11,
  hoursPerDay: 18,
  daysPerWeek: 5,
  ftePerMachineSupervised: 0.15,
  fteForBuildExchange: 1,
  fteForSupportRemoval: 1,
  fteSalaryEngineer: 70,
  fteSalaryOperator: 50,
  fteSalaryTechnician: 30
},
      ],
      materials: [
        {
          name: 'ABS',
          cost: 400,
          density: 4.43
        }
      ],
      machineSelected: null,
      materialSelected: {
        cost: 400,
        density: 4.43
      },
      partsProduced: null,
      partMass: null,
      part: {
        projectedArea: 0,
        height: 0
      },
      partProjectedArea: 0
    }
  }

  componentDidMount() {
    
  }

  handleQuoteClick = () => {
    // Are all of the required Fields Filled in?
    // if no, error
    // if yes, set showQuote to true
    if (this.state.machineSelected) {
      this.setState({showQuote: true})
    }
  }

  // set machine type
  // based on machine type, there are certain available 
  setMachineSelected = (e) => {
		var index = Number(e.target.value);
		var machineSelected = this.state.machines[index];
		this.setState({machineSelected});
	}
  setProductionSize  = e => this.setState({partsProduced: Number(e.target.value)})
  setPartMass = e => this.setState({partMass: Number(e.target.value)})

  partsPerBuild = () => {
    var machine = this.state.machineSelected;
    var abc = ( machine.buildArea / this.state.partProjectedArea ) * machine.packingFraction;
    var def = 1;
    if ( machine.packingPolicy != 2) {
      def = Math.floor( machine.buildHeight / this.state.partHeight ); 
    }

    return Math.floor(abc * def);

  }

  partMaterialVolume = () => {
    return this.state.partMass * 1000 / this.state.materialSelected.density;  
  }

  numberOfBuilds = () => Math.ceil(this.state.partsProduced / this.partsPerBuild()) 
  supportMassPerPart = () => this.state.partMass * this.state.machineSelected.supportMaterialPercent
  totalPartMaterial = () => this.state.partsProduced * this.state.partMass
  totalSupportMaterial = () => this.state.partsProduced * this.supportMassPerPart() 
  totalMaterialInAllBuilds = () => {
    var machine = this.state.machineSelected;
    return this.numberOfBuilds() * machine.buildArea * machine.buildHeight * this.state.materialSelected.density  / 1000;
  }
  recycledMaterial = () => {
    var fullRecycle = this.totalMaterialInAllBuilds() - this.totalSupportMaterial() - this.totalPartMaterial();
    return fullRecycle * this.state.machineSelected.recyclingFraction;

  }
  materialWaste = () => {
    return this.totalMaterialInAllBuilds() - this.recycledMaterial() - this.totalSupportMaterial() - this.totalPartMaterial();
  }
  materialRequired = () => {
    return this.materialWaste() + this.totalSupportMaterial() + this.totalPartMaterial();
  } 

  totalMaterialCost = () => {
    return this.materialRequired() * this.state.materialSelected.cost;
  }

  buildPrepTime = () => {
    var machine = this.state.machineSelected;
    return (this.numberOfBuilds() - 1) * machine.subsequentBuildPrep + machine.firstTimeBuildPrep;
  }

  buildPrepCost = () => {
    return this.buildPrepTime() * this.state.machineSelected.fteSalaryEngineer;
  }

  // machine
  machineUpFrontCost = () => {
    var machine = this.state.machineSelected;
    return machine.purchaseCost * ( 1 + machine.infrastructureCost );
  }

  machineAnnualDepreciation = () => {
    var machine = this.state.machineSelected; 
    var top = machine.costOfCapital * this.machineUpFrontCost();
    var power = (-1 * machine.machineLifetime);
    var bottom = (1 + machine.costOfCapital);
    bottom = Math.pow(bottom, power);
    bottom = 1 - bottom;
        
    return top / bottom;
  }
  
  machineAnnualMaintenance = () => {
    var machine = this.state.machineSelected;    
    return machine.maintenance * machine.purchaseCost;
  }

  machineAnnualCost = () => {
    return this.machineAnnualDepreciation() + this.machineAnnualMaintenance();
  }

  operatingHoursPerYear = () => {
    var machine = this.state.machineSelected;
    return machine.hoursPerDay * machine.daysPerWeek * 52 * machine.uptime;
  }

  machineCostPerHourOccupied = () => {
    return this.machineAnnualCost() / this.operatingHoursPerYear();
  }

  warmUpTime = () => {
    return this.numberOfBuilds() * this.state.machineSelected.timePerMachineWarmUp;
  }
  printTime = () => {
    return this.partMaterialVolume() * this.state.partsProduced / this.state.machineSelected.rate;
  }
  coolDownTime = () => {
    return this.numberOfBuilds() * this.state.machineSelected.timePerMachineCoolDown; 
  }
  buildExchangeTime = () => {
    var machine = this.state.machineSelected;
    return this.numberOfBuilds() * (machine.timePerBuildSetup + machine.timePerBuildRemoval)
  }
  totalMachineTime = () => {
    return this.warmUpTime() + this.printTime() + this.coolDownTime() + this.buildExchangeTime();
  }

  totalMachineUsageCost = () => {
    return this.totalMachineTime() * this.machineCostPerHourOccupied();
  }

  operatingCost = () => {
    return this.state.machineSelected.additionalOperatingCost * (this.warmUpTime() + this.printTime() + this.coolDownTime())
  }

  perBuildConsumables = () => {
    return this.numberOfBuilds() * this.state.machineSelected.consumableCostPerBuild;
  }

  totalConsumablesCost = () => {
    return this.operatingCost() + this.perBuildConsumables();
  }

  laborHoursDuringBuild = () => {
    return (this.warmUpTime() + this.printTime() + this.coolDownTime()) * this.state.machineSelected.ftePerMachineSupervised;
  }

  laborHoursDuringBuildExchange = () => {
    return this.buildExchangeTime() * this.state.machineSelected.fteForBuildExchange;
  }

  totalLaborHours = () => {
    return this.laborHoursDuringBuild() + this.laborHoursDuringBuildExchange();
  }

  totalLaborCost = () => {
    return this.totalLaborHours() * this.state.machineSelected.fteSalaryOperator;
  }

  postProcessingTimePerPart = () => {
    var machine = this.state.machineSelected;
    return machine.supportRemovalTimeLabor * (Math.pow(this.state.partProjectedArea, 0.5));
  }

  totalPostProcessingTime = () => {
    return this.postProcessingTimePerPart() * this.state.partsProduced;
  }

  totalPostProcessingCost = () => {
    return this.totalPostProcessingTime() * this.state.machineSelected.fteSalaryTechnician;
  }

  setNumber(v, e){
    var value = Number(e.target.value);
    this.setState({[v]: value});
  }


  render() {
    var { materialSelected, partsProduced, partMass, machineSelected } = this.state;
    if ( machineSelected) {
			var { supportMaterialPercent } = machineSelected;
			var supportMassPerPart = partMass * supportMaterialPercent;
		} 
    return(
    <div>
      <div>
      <h1>Quote</h1>
      <h4>Machine and Material Information</h4>
			<div style={{display:'flex'}}>
      <label>Select Machine</label>
			<span>Compare Machines</span>
			</div>
      <select style={{width: '8vw'}} onChange={this.setMachineSelected}>
					<option>Choose</option>
        { this.state.machines.map( (machine, index) => 
          <option key={index} value={index}>{machine.name}</option>
        )} 
      </select>
			<div style={{display:'flex'}}>
      <label>Select Materials</label>
			<span>Compare Materials</span>
			</div>
      <select style={{width: '8vw'}}>
        { this.state.materials.map( (material, index) =>
          <option key={index} >{material.name}</option>
        )}
      </select>
      <h4>Part Information</h4>
      <div style={{display: 'flex', flexDirection: 'column'}}>
      <label >Part Mass </label>
      <div style={{display: 'flex' }}>
      <input style={{width: '5vw'}} onChange={this.setPartMass}/ >
      <span>kg</span>
      </div>
      <label>Part height in build orientation (normal to build plate)</label>
      <div>
      <input style={{width: '5vw'}}onChange={(e) => this.setNumber('partHeight', e)}/>
      <span>cm</span>
      </div>
      <label>Part projected area in build orientation</label>
      <input style={{width: '5vw'}}onChange={(e) => this.setNumber('partProjectedArea', e)}/>
      <label>Suport material (as % of part mass) -- Can use value from the software, or take a static value from the machine.</label>
      <input style={{width: '5vw'}}/>
      </div>
      <h4>Production Information</h4>
      <div style={{display: 'flex', flexDirection: 'column'}}>
      <label>Production Run size</label>
      <input style={{width: '8vw'}} onChange={this.setProductionSize}/>
      <button onClick={this.handleQuoteClick} >Generate Quote</button>
      </div>
      </div>
       

      { this.state.showQuote &&
      <div>
      <div>
        <h4>Total Cost for Production Run</h4>

      </div>
      <div>
        <label>Parts per Build </label>
        <input value={this.partsPerBuild()} readOnly/>
        <label>Number of Builds</label>
        <input value={ this.numberOfBuilds()  } readOnly />
      </div>


      <div>
        <h4>Material Cost</h4>
				<label>Total Part Material</label>
        <input value={this.totalPartMaterial() } readOnly/>
				<label>Total Support Material</label>
				<input value={this.totalSupportMaterial() } readOnly />
				<label>Total Material in all builds</label>
				<input value={ this.totalMaterialInAllBuilds() } readOnly />
				<label>Recycled Material</label>
				<input value={ this.recycledMaterial() } readOnly />
				<label>Material Waste (non-recycled)</label>
				<input value={ this.materialWaste() } readOnly />
				<label>Material Required</label>
				<input value={ this.materialRequired()} readOnly />
        <label>Total Material Cost</label>
				<input value={ this.totalMaterialCost()} readOnly />
      </div>

      <div>
        <h4>Build Prep</h4>
        <label>Build Prep Time </label>
        <input value={this.buildPrepTime()} readOnly />
        <label>Total Build Prep Cost </label>
        <input value={this.buildPrepCost()} readOnly />
      </div>

      <div>
        <h4>Machine</h4>
        <label>Total Up-front Cost</label>
        <input value={this.machineUpFrontCost()} readOnly />
        <label>Annual Depreciation</label>
        <input value={this.machineAnnualDepreciation()} readOnly />
        <label>Annual Maintenance</label>
        <input value={this.machineAnnualMaintenance()} readOnly />
        <label>Annual Machine Cost</label>
        <input value={this.machineAnnualCost()} readOnly />
        <label>Operating Hours per Year</label>
        <input value={this.operatingHoursPerYear()} readOnly />
        <label>Machine cost per hour occupied</label>
        <input value={this.machineCostPerHourOccupied()} readOnly />
        <label>Total Warm Up Time</label>
        <input value={this.warmUpTime()} readOnly />
        <label>Total Print Time</label>
        <input value={this.printTime()} readOnly />
        <label>Total cool-down Time</label>
        <input value={this.coolDownTime()} readOnly />
        <label>Total build exchange Time</label>
        <input value={this.buildExchangeTime()} readOnly />
        <label>Total Machine Time required</label>
        <input value={this.totalMachineTime()} readOnly />
        <label>Total Machine Usage Cost</label>
        <input value={this.totalMachineUsageCost()} readOnly />
      </div>

      <div>
        <h4>Consumables</h4>
        <label>Operating cost</label>
        <input value={this.operatingCost()} readOnly />
        <label>Per-build consumables</label>
        <input value={this.perBuildConsumables()} readOnly />
        <label>Total Consumables Cost</label>
        <input value={this.totalConsumablesCost()} readOnly />
      </div>

      <div>
        <h4>Labor</h4>
        <label>Labor Hours During Build</label>
        <input value={this.laborHoursDuringBuild()} readOnly />
        <label>Labor Hours During Build Exchange</label>
        <input value={this.laborHoursDuringBuildExchange()} readOnly />
        <label>Total Labor Hours</label>
        <input value={this.totalLaborHours()} readOnly />
        <label>Total Labor Cost</label>
        <input value={this.totalLaborCost()} readOnly />
      </div>

      <div>
        <h4>Post-Process</h4>
        <label>Post processing time per part</label>
        <input value={this.postProcessingTimePerPart()} readOnly />
        <label>Total post-processing time</label>
        <input value={this.totalPostProcessingTime()} readOnly />
        <label>Total post-processing cost</label>
        <input value={this.totalPostProcessingCost()} readOnly />
      </div>

			<div>
				<h4>Machine Inputs</h4>
					<label>Purchase Cost</label>
					<input value={machineSelected.purchaseCost} readOnly/>
					<label>Machine Lifetime</label>
					<input value={machineSelected.machineLifetime} readOnly/>
					<label>Cost of Capital (interest rate)</label>
					<input value={(machineSelected.costOfCapital * 100) + '%'} readOnly/>
					<label>Infrastructure Cost (as % of Machine cost, up front)</label>
					<input value={machineSelected.infrastructureCost} readOnly/>
					<label>Maintenance (as % of machine cost, per year)</label>
					<input value={machineSelected.maintenance} readOnly/>
					<label>Machine Build Area (LxW)</label>
					<input value={machineSelected.buildArea} readOnly/>
					<label>Machine Build Height (H)</label>
					<input value={machineSelected.buildHeight} readOnly/>
					<label>Machine Build Volume</label>
					<input value={machineSelected.buildArea * machineSelected.buildHeight} readOnly/>
					<label>Machine Build Rate</label>
					<input value={machineSelected.rate} readOnly/>
					<label>Machine Uptime</label>
					<input value={machineSelected.uptime} readOnly/>
			</div>
      </div>
      } 
   </div>

      
      
    );
  }
}

