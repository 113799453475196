import React from "react";
import { backUrl} from "../../App/constants";
import CreatePage from "../question_set/CreatePage";
import { Route } from "react-router-dom";
import Modal from 'react-modal';
import { BasicTable } from '../../common/auth/Teams.jsx';

export default class Create extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      project: {},
      //token: JSON.parse(localStorage["appState"]).user.auth_token,
			users: [],
			teams: [],
	    showTeamModal: false,
      teamsToAdd: [],
      createdId: null,
      templates: this.props.templates,
      templateId: 0
    };
  }

  openTeamModal() {this.setState({showTeamModal:true});}
  closeTeamModal() {this.setState({showTeamModal:false})}

  setPublic(bool) { }

  changeTemplate(id) {
    this.setState({templateId: Number(id)})
  }

  onChange({target}) {
    const {value, name} = target;

    const { project } = this.state;
    project[name] = value;

    this.setState({
      project: project
    });
  }

  saveProject() {
    var project = this.state.project
    project['template_id'] = this.state.templateId;
    const url = backUrl + 'projects';
    fetch(url, {
      method: "POST",
      headers:  {
      "Content-Type": "application/json",
      "Authorization": 'Bearer ' + localStorage.getItem('token'),
      "Accept": "application/json"
    },
      body: JSON.stringify({project: project})
    })
      .then(res => res.json())
      .then(json => {
          this.props.history.push(`/builds`);
      });
}

  render() {

    //TODO: find a better approach to this cheap hack for 
    //      dealing with the case of no template information
    if (!this.props.templates || this.props.templates.length < 1) { 
      return (
        <div><h2>Cannot create project - please define a template first  </h2></div>
      );
    } else {

    //Load fields from the currently selected template
    var template;
    if(this.state.templateId === 0){
      //If the user has not clicked a template, select the first one in the list
      template = JSON.parse(this.props.templates[0].schema)
      this.setState({templateId: this.props.templates[0].id})
    }
    else{

      //Find selected template in all templates
      var matches = this.props.templates.filter((template) => template.id === this.state.templateId)

      //Set template data
      template = JSON.parse(matches[0].schema)
    }

    var pages = template.pages
    let names = pages.map(page => page.name);
    var elements = pages.map(page => page.elements);
    const passedElements = pages[0].elements.filter(e => e.type !== 'file');

    return(
      <div>
          <Route path = {`/projects/new`}
                 render = { () =>

                     <CreatePage  openTeamModal = {() => this.openTeamModal()}
                                  teamsToAdd={this.state.teamsToAdd}
                                  templates = {this.props.templates}
                                  changeTemplate = {(e) => this.changeTemplate(e.target.value)}
                                  makePrivate = {() => this.setPublic(false)}
                                  makePublic = {() => this.setPublic(true)}
												 					users={this.state.users}
																	teams={this.state.teams}
												 					title={pages[0].name}
                                  project = {this.state.project}
                                  data={passedElements}
                                  onChange={(event) => this.onChange(event)}
                                  save={() => this.saveProject()}
                                  titles = { names }
                      />
                 }
          />

      </div>
    );
    }
  }
}
