import React from "react";
import styled from 'styled-components';

var Header = styled.div`
	display: flex;
	background-color: rgb(0, 154, 212);
	margin-bottom: 6px;
`

var HeaderText = styled.h4`
	color: #FFF;
	margin: 0;
  font-size: 14px;
	margin-left: 12px;
	margin-bottom: 4px;
`

var TableContainer = styled.div`
	border-bottom: 1px solid lightgrey;
	padding-left: 15px;
	padding-right: 15px;
	display: flex;
	align-items: center;
	justify-content:space-between;
`

var TableText = styled.h5`
	font-weight: normal;
  margin-left: 15px;
`

const LCard = props => {
  return(
    <div className="lcard" >
      <Header>
        <HeaderText>{props.title}</HeaderText>
      </Header>
      { props.data && props.data.length > 0 &&
        <table style={{borderCollapse: 'collapse'}}>
        <tr>
          <td></td>
          <td style={{textAlign: 'center'}}><TableText >Average</TableText></td>
          <td style={{textAlign: 'left', paddingLeft: '9px'}}><TableText >Max</TableText></td>
          <td style={{textAlign: 'left'}}><TableText >Stdev</TableText></td>
        </tr>
			    { props.data.map( (datum, index) =>
		<tr style={{borderBottom: '1px solid lightgrey'}}>
	     	        <td><TableText>{ datum.type }</TableText></td>
		            <td style={{paddingRight: 12, paddingLeft: 12}}><TableText>{ datum.average.toFixed(2) }</TableText></td>
                <td style={{paddingRight: 12, paddingLeft: 12}}><TableText>{ props.data1[index].average.toFixed(2)}</TableText></td>
                <td><TableText>{ props.data2[index].average.toFixed(2)}</TableText></td>
		</tr>
          )}
</table>
	     }
    </div>
  );
}

export default LCard;
