import React from 'react'
import { FileIcon, RemoveIcon } from './Icons'

function isImage(fileName) {
  const imageFormats = [
    'png',
    'jpg',
    'jpeg',
    'gif'
  ];

  const splitFileName = fileName.split('.');
  const extension = splitFileName[splitFileName.length - 1];

  return imageFormats.includes(extension);
}

const File = props =>{
  const fileIsImage = props.value && props.value.fileName &&
    isImage(props.value.fileName);

  return(
    <div className="form-row">
      <label className="form-label">
        {props.label}
      </label>
      <div>
        { (!props.value || !props.value.fileName) && <div>
                                <input style={{ border: 'none', color: '#EEF5F9', background: 'none' }}
                                       name={props.name}
                                       onChange={props.onChange}
                                       type="file"/>
                          </div> }
        { props.value && props.value.fileName && <div style={{ display: 'flex' }}>
                                <div style = {{ display: 'flex', flexDirection: 'column' }}>
                                  <a href={props.value.url}>
                                      {props.value.fileName}
                                  </a>
                                  { fileIsImage
                                      ? <img style={{ height: '200px' }}src={props.value.url} />
                                      : <FileIcon />
                                  }
                                  

                                </div>
                                <RemoveIcon
                                  onClick= { () => props.removeFile(props.name) }
                                  id = { props.value.id }
                                />
                         </div>}
        <small className="hide">tip/subhead</small>
      </div>
    </div>
  )}

export default File

