import React from 'react';
import Plot from 'react-plotly.js';
import Card from '../../common/Card.jsx';
import {getMaterialsCount, count_duplicate, grabData} from './PlotData';



const PlotTable = props => {
  var all_process_types = getMaterialsCount(props.projects)
  // var processCounts = count_duplicate(all_process_types)
  // var all = grabData(all_process_types)
  var labels = [];
  var trace1Values = [];
  var trace2Values = [];

  var trace1HoverText = all_process_types.map((type) => { return `${type.name} ${type.material_name}: ${type.builds} builds`; });

  var trace2HoverText = all_process_types.map((type) => { return `${type.name} ${type.material_name}: ${type.materials} materials`; });

  all_process_types.forEach((type) => {
    var string = type.name + ' ' + type.material_name
    labels.push(string);
    trace1Values.push(type.builds)
    trace2Values.push(type.materials)
  })
  var keyNames = Object.keys(all_process_types);

  var trace1 = {
    x: labels,
    y: trace1Values,
    name: 'Builds',
    type: 'bar',
    barmode: 'bar',
    mode: 'lines+text',
    text: trace1Values.map(String),
    hovertext: trace1HoverText,
    marker: {color: props.colors[1]},
  }


  var trace2 = {
    x: labels,
    y: trace2Values,
    name: 'Materials',
    type: 'bar',
    mode: 'lines+text',
    text: trace2Values.map(String),
    hovertext: trace2HoverText,
    marker: {color: props.colors[2]},
  }




  return (
    <Card title="Materials and Builds of Process Types" noMargin={true}>
     <Plot

       data={[trace1, trace2]}
       layout={ {
         barmode: 'stack',
         autosize: true,
         showLegend: false,
         width: window.innerWidth == 1366 ? '650' : '900',
         height: 'auto',
         margin: '0',
         paper_bgcolor: 'transparent',
         pad: 15,
         plot_bgcolor: '#C9BCA6',
         font: {
           family: 'monospace',
           color: 'white',
           size: 12
         },
         xaxis: {
           tickangle: '360',
           tickwidth: '50'
         },
         // legend: {
         //   bgcolor: '#C9BCA6',
         //   padding: 10,
         //   font: {
         //     family: 'monospace',
         //     color: 'white',
         //     size: 20
         //   },
         // },
         hoverlabel: {
           font: {
             family: 'monospace',
             color: 'white',
             size: 15
           },
         }
       } }
     />
     </Card>
   );
}

export default PlotTable;
