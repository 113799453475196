/**
 * Auth Component
 * 
 * A reusable auth component with functions for handling login, logout, etc.
 *
 * Usage:
 *   import Auth from 'common/auth'
 *   const { loggedIn, checkExistingSession } = useAuth()
 *   if i(loggedIn()) ...
 *
 * @returns Auth context 
 */
import { backUrl } from "../../App/constants";


import React, { createContext, useContext,  useState, useEffect } from 'react';

const AuthContext = createContext();

const Auth = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [token, setToken] = useState(null);
  const [username, setUsername] = useState(null);
  const [currentCompany, setCurrentCompany] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [addons, setAddons] = useState(null);


  //todo deprecate - is this really necessary? 
  useEffect(() => {
    // Check if the user is already logged in
    checkExistingSession();
  }, []);

  const login = async (username, password) => {
    try {
      const response = await fetch(backUrl + 'login', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": 'Bearer ' + token,
          "Accept": "application/json"
        },
        body: JSON.stringify({ user: { email: username, password: password } })
      });

      const data = await response.json();
      if (!data.error) {
        // Login successful
        
        // save the token and username in localStorage
        localStorage.setItem('token', data.token);
        localStorage.setItem('username', username);

        // set other user profile infos
        // todo: deprecate - do we actually need these?
        //localStorage.setItem('current_company', data.current_company);
        //localStorage.setItem('companies', data.companies);
   
        // set the state vars for Auth here
        setLoggedIn(true);
        setToken(data.token);
        setUsername(username);
     
        // set other infos 
        setCurrentCompany(data.current_company);
        localStorage.setItem('currentCompany', data.current_company);
        localStorage.setItem('companies', data.companies);
        localStorage.setItem('addons', JSON.stringify(data.addons));
        //NOTE; to use addons directly, gotta JSON.parse:
        //console.log(JSON.parse(localStorage.getItem('addons')));
       
/*
        console.log("CURRENT!");
        console.log(data.current_company);
        console.log(data); 
*/
        setCompanies(data.companies);
        setAddons(data.addons);

        return { success: true, message: 'Login successful' };

      } else {
        // Login failed
        
        return { success: false, message: data.error };
      }
    } catch (error) {
      console.error('Error:', error);
      return { success: false, message: error };
    }
  };

  const logout = async () => {
    try {
      const response = await fetch(backUrl + 'logout', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": 'Bearer ' + token,
          "Accept": "application/json"
        }
      });

      const data = await response.json();
      if (!data.error) {
        // Logout successful
        
        // remove the token and username in localStorage
        // TODO should weuse sessionStorage for some of these 
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('currentCompany');
        localStorage.removeItem('companies');
        localStorage.removeItem('addons');

        // set other user profile infos
        // todo: deprecate - do we actually need these?
        // localStorage.removeItem('current_company');
        // localStorage.removeItem('companies');
   
        setLoggedIn(false);

        return { success: true, message: 'Logout successful' };

      } else {
        // Login failed
        console.log("Logout failed.");
        console.log("Error: " + data.error);

        //still delete token, etc. if BE fails 
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        setLoggedIn(false);



        return { success: false, message: data.error };
      }
    } catch (error) {
      console.error('Error:', error);
      return { success: false, message: error };
    }
    
  };


// todo: implement this properly 
/*
  const register = (username, password) => {
    // Send a registration request to the server
    fetch('http://localhost:3000/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.token) {
          // Registration successful, save the token and set the logged in state
          setLoggedIn(true);
          setToken(data.token);
        } else {
          // Registration failed
          console.log('Registration failed');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
*/


  // this method checks the browser store for creds and syncs them to 
  // state vars. Useful if browser session is destroyed but user returns 
  // with still valid creds.
  const checkExistingSession = () => {
    return new Promise((resolve) => {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');

      if (token && username) {
        // If token and username are present in localStorage, consider the user logged in

        //Attempt to make request to BE to make sure session is still valid
        fetch(`${backUrl}/tracetime`, {
          headers: {
            "Authorization": 'Bearer ' + token,
          }
        }).then(response => {
          //Only return true if the request was successful
          if(response.ok){
            console.log("Token works correctly")
            setLoggedIn(true);
            setToken(token);
            setUsername(username);

            resolve();
          }
          else{
            console.log("Token invalid")
            setLoggedIn(false);
            setToken(null);
            setUsername(null);
    
            resolve();
          }
        })
        
      } else {
        setLoggedIn(false);
        setToken(null);
        setUsername(null);

        resolve();
      }
    });
  };

 return (
    <AuthContext.Provider value={{ loggedIn, setLoggedIn, token, username, 
                                   login, logout, checkExistingSession, 
                                   currentCompany, companies }}>
      {children}
    </AuthContext.Provider>
  );

};

const useAuth = () => useContext(AuthContext);

export { Auth, AuthContext, useAuth };

