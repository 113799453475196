import React, { useEffect, useState } from 'react';
import {backUrl} from '../../App/constants';
import Plot from "react-plotly.js";

const CymaniiPowerData = ({build_id}) => {

    const [power_data, setPowerData] = useState(null);
    const [loading, setLoadingState] = useState(null);

    useEffect(()=>{
        const fetchBuild = async () => {
            setLoadingState(true)
            try{
                fetch(`${backUrl}/builds/${build_id}`, {
                    headers: {
                      "Authorization": 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(res => res.json())
                .then(data =>{
                    setPowerData(JSON.parse(data.power_data))
                })
            } catch(error){
                setPowerData(error)
                console.log(error)

            } finally{
                setLoadingState(false)
            }
            
        };

        fetchBuild();
    }, [build_id])

    if(loading) return <p>... Loading Build data</p>
    if(power_data == null) return <span/>

    return(
        <div style={{width:'100%'}}>
            <h2>Uploaded power data:</h2>
            <Plot 
            data={[
              {
                x: power_data.times,
                y: power_data.amps_1a,
                mode: 'lines',
                name: "Amps (Sensor 1A)"
              },
              {
                x: power_data.times,
                y: power_data.amps_1b,
                mode: 'lines',
                name: "Amps (Sensor 1B)"
              },
              {
                x: power_data.times,
                y: power_data.amps_1c,
                mode: 'lines',
                name: "Amps (Sensor 1C)"
              },
            ]}
            layout={{
              title: 'Main Power 3 Phases',
              showlegend: true,
              xaxis: {
                showgrid:true,
                title: "Time (s)"
              },
              yaxis: {
                showgrid: true,
                title: "Amps"
              }
            }}
            style={{ padding: '5px' }}
          />
            <h2>Injection Molding Cylcles: <i>(Calculated based on power spiking above a {power_data.power_threshold} Amp threshold)</i></h2>
            <h2>Phase 1: {power_data.power_spikes[0]}</h2>
            <h2>Phase 2: {power_data.power_spikes[1]}</h2>
            <h2>Phase 3: {power_data.power_spikes[2]}</h2>
          <Plot 
            data={[
              {
                x: power_data.times,
                y: power_data.dc_current,
                mode: 'lines',
                name: "DC Current"
              },
            ]}
            layout={{
              title: 'DC Current / Time',
              showlegend: true,
              xaxis: {
                showgrid:true,
                title: "Time (s)"
              },
              yaxis: {
                showgrid: true,
                title: "Amps"
              }
            }}
            style={{ padding: '5px' }}
          />
          <Plot 
            data={[
              {
                x: power_data.times,
                y: power_data.heater_power,
                mode: 'lines',
                name: "Heater Power"
              },
            ]}
            layout={{
              title: 'Heater Power Average of 3 Phases',
              showlegend: true,
              xaxis: {
                showgrid:true,
                title: "Time (s)"
              },
              yaxis: {
                showgrid: true,
                title: "Power"
              }
            }}
            style={{ padding: '5px' }}
          />
          
        </div>
    );

}

export default CymaniiPowerData;