import React from "react";
import Card from '../../../common/Card';

import styles from './styles.css';

const BuildCard = (props) => {

  return (
    <div className="machineCard">
       <h2 className="card-title">Build Plan {props && props.data && props.data.name}</h2>


       <table className="renTable">
         <tr>
           <td className="renCol1"><b>Build Status</b></td>
           <td className="renCol2">
             {props && props.data && props.data.status || 'unknown'}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>Start Time</b></td>
           <td className="renCol2">
             {props && props.data && props.data.start || 'unknown'}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>End Time</b></td>
           <td className="renCol2">
             {props && props.data && props.data.end || 'unknown'}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>Expected Layers</b></td>
           <td className="renCol2">
             {props && props.data && props.data.expectedLayers || 'unknown'}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>Completed Layers ID</b></td>
           <td className="renCol2">
             {props && props.data && props.data.lastCompletedLayer || 'unknown'}
           </td>
         </tr>
         <tr>
           <td className="renCol1"><b>Job ID</b></td>
           <td className="renCol2">
             {props && props.data && props.data.id || 'unknown'}
           </td>
         </tr>
       </table>
    </div>


  )
}

export default BuildCard;




