import React, {useState, useEffect} from 'react';
import { schema, backUrl } from '../../App/constants';
import SectionPage from '../question_set/SectionPage';
import { Route } from 'react-router-dom';
import Modal from '../../common/basic-elements/Modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom'
//Replaced useHistoty with useNavigate in the update from React 16 to React 18
//import { useNavigate } from 'react-router-dom-v5-compat';


const OptionEditModal = props => {
  if (!props.optionSet.choices) {
    props.optionSet.choices = [];
}

  return(
    <div>
    <p> Question: {props.optionSet.title}</p>
    <button onClick={props.addChoice} >Add Choice</button>
    { (props.optionSet.type == 'dropdown' || props.optionSet.type == 'checkbox') &&
      props.optionSet.choices.map( (choice, i) =>
        <input id={i} onChange={props.changeOption} value={choice.text} />
      )
    }
    <button onClick={props.saveOptions}>Save</button>
    </div>
  );
}

const Template = (props) => {
  // const [grabbedSchema, setGrabbedSchema] = useState(schema)
  const [newSchema, setNewSchema] = useState({'pages': []});
  const [templateName, setTemplateName] = useState(null);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [optionSet, setOptionSet] = useState(null);
  const [optionPage, setOptionPage] = useState(null);
  const [optionElement, setOptionElement] = useState(null);
  const [loading, setLoading] = useState(false)
  //const history = useHistory();
  const history = useHistory();

  useEffect(() => {
    setLoading(true)
    if (props.match.params.id === 'undefined'){
      setNewSchema(schema);
      setLoading(false)
    } else {
      getTemplate();
    }
  }, []);

  function getTemplate(){
    fetch(backUrl + 'templates/' + props.match.params.id, {
      headers:{
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
      }
    })
    .then(templateResponse => templateResponse.json())
    .then(template => {

      setNewSchema(JSON.parse(template.schema))
      setTemplateName(template.name)
      setLoading(false)
    });
  }
	//const history = useHistory();

  function removeQuestion(e, a) {
    var ns = Object.assign({}, newSchema)
    ns.pages[a].elements.splice(e.target.id, 1);
    setNewSchema(ns);
  }

  function handleChange(e, a) {
    var ns = Object.assign({}, newSchema)
    ns.pages[a].elements[e.target.id][e.target.name] = e.target.value;
    setNewSchema(ns);
  }

  function nextCustomField() {
    //Get every field name that starts with "custom_" and ends with a number
    let matchList = JSON.stringify(newSchema).match(/custom_[0-9]*/g )
    var customNumber = 0

    for(let index in matchList){
      //remove the "custom_" from the start of the string
      let matchNumberString = matchList[index].replace("custom_", "")

      //Find the number in the string, and replace the customNumber
      //if the found number is larger
      let matchNum = parseInt(matchNumberString)
      if(matchNum > customNumber){
        customNumber = matchNum
      }
    }

    if ( customNumber > 8 ) {
      return customNumber + 1
    } else
    return "0" + (Number(customNumber + 1));

  }

  function addQuestion(e, a) {
   var ns = Object.assign({}, newSchema)
   ns.pages[a].elements.push({

      title: '',
      type: 'text',
      name: 'custom_' + nextCustomField()
    });

   setNewSchema(ns);
  }

  function changeType(e, a) {
    var ns = Object.assign({}, newSchema)
    ns.pages[a].elements[e.target.id].type= e.target.value;
    setNewSchema(ns);
  }

  function saveTemplate() {
    if (!templateName) {
      alert('you must give this template a name');
      return null;
    }
    var body = new FormData();
    body.append('schema', JSON.stringify(newSchema));
    body.append('name', templateName);

      if (props.match.params.id === 'undefined'){
        fetch(backUrl + 'templates', {
          method: 'POST',
          body: body,
          headers: {
            "Authorization": 'Bearer ' + localStorage.getItem('token'),
          }
        })
        .then(a => a.json())
        .then(a => {
          toast(`Template ${templateName} has been saved`)
          setTimeout(() => {
            //history.push("/templates")
            //Replaced in the upgrade from react 16 to react 18
            history.push("/templates")
          }, 1500);
        });
      } else {
        fetch(backUrl + 'templates/' + props.match.params.id,{
          method: 'PUT',
          body: body,
          headers: {
            "Authorization": 'Bearer ' + localStorage.getItem('token'),
          }
        })
        .then(a => a.json())
        .then(a => {
          toast(`Template ${templateName} has been saved`)
          setTimeout(() => {
            history.push("/templates")
          }, 1500);

        })
      }
    }



  function openOptionsModal(e, i) {
    setShowOptionsModal(true);
    setOptionSet(newSchema.pages[i].elements[e.target.id])
    setOptionPage(i);
    setOptionElement(e.target.id)
  }

  function addChoice() {
    var oldOptions = optionSet;
    oldOptions.choices.push({text: ''});
    setOptionSet(oldOptions);
  }

  function changeOption(e) {
    var oldOptions = optionSet;
    oldOptions.choices[Number(e.target.id)].text = e.target.value;
    if (oldOptions.type == 'checkbox') {
      oldOptions.choices[Number(e.target.id)].value = `custom_${nextCustomField()}`;
    }
    setOptionSet(oldOptions)
  }

  function saveOptions() {
    var oldSchema = newSchema;
    oldSchema.pages[optionPage].elements[optionElement] = optionSet;
    setNewSchema(oldSchema);

  }

  return(
   <div>
      <ToastContainer
        position="top-left"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
      />
      <Modal
        isOpen={showOptionsModal}
        onClose={() => setShowOptionsModal(false)}
      >
        <OptionEditModal
          optionSet = { optionSet }
          addChoice = { () => addChoice() }
          saveOptions = {() => saveOptions}
          changeOption= {e => changeOption(e)}

        />
      </Modal>
     { !loading && newSchema.pages.map((page, i) =>
       <Route path = {`/template/edit/:id/${newSchema.pages.map(p=>p.elements).indexOf(page.elements)}`}
              key={page.name}
              render = { () =>
                <SectionPage
                  templateName={templateName}
                  addQuestion={(e) => addQuestion(e, i)}
                  changeTemplateName={(e) => setTemplateName(e.target.value)}
                  openOptionsModal = {(e) => openOptionsModal(e, i)}
                  onChange = {(e) => handleChange(e, i)}
                  changeType = {e => changeType(e,i)}
                  title={page.name}
                  type='template'
                  removeQuestion = {(e) => removeQuestion(e, i)}
                  save = {() => saveTemplate()}
                  data = {page.elements}
                  titles = { newSchema.pages.map(p => p.name )}
                  index={newSchema.pages.map(page=>page.elements).indexOf(page.elements)}
                  id={props.match.params.id}
                />
              }
            />
     )}
   </div>

 );

}

export default Template;
