import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { backUrl } from '../../App/constants';
import BasicTable from '../../common/basic-elements/BasicTable';
import { RemoveIcon } from '../../common/Icons';
import MainHeader from '../../common/nav/MainHeader';
import SubNav from '../../common/nav/SubNav';
import '../search/styles/SearchPage.css';
import { useHistory } from 'react-router-dom';
//Replaced useHistoty with useNavigate in the update from React 16 to React 18
//import { useNavigate } from 'react-router-dom-v5-compat';

const TemplateHome = (props) => {
  //const history = useHistory();
  const history = useHistory();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    getTemplates();
	}, []);


  function getTemplates() {
    fetch(backUrl + 'templates', {
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
      }
    })
    .then(templateResponse => templateResponse.json())
    .then(templates => {
      setTemplates(templates)
      // templates.length ? setTemplates(templates) : null
    });
  }

  function deleteTemplate(index) {
    var template = templates[index];
    if (template.projects.length > 0 ) {
      alert('You currently have projects that rely on this template. It cannot be deleted.');
      return null;
    }
    var id = template.id;

    fetch(`${backUrl}templates/${id}`, {
      method: 'DELETE',
      headers: {
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
      }
    }).then(a => {
	    //a.json()
    })
      .then(temps => {
	      //var tts = templates
	      //tts.splice(index, 1);
	      //setTemplates(tts)
	      getTemplates()
      });
  }

  function newTemplate(){
    history.push("/template/edit/undefined/0");
  }


    return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
    <div className="searchTopBarWrapper">
      <SubNav section='projects' current='templates'/>
      <div className="action-wrapper">
        <Link className="new-button" to="/template/edit/undefined/0">Add New Template</Link>
      </div>
    </div>

      <BasicTable>
        <thead>
          <td>Template Name</td>
          <td>Projects</td>
          <td></td>
        </thead>
        <tbody>
        { templates && templates.map( (template, index) =>
          <tr>
            <td>
              <Link to={`/template/edit/${template.id}/0`} >{template.name}</Link>
            </td>
            <td>{template.projects.length} projects</td>
            <td><RemoveIcon onClick={() => deleteTemplate(index)}/ ></td>
          </tr>
        )}
        </tbody>
      </BasicTable>
    </div>
  );


}
export default TemplateHome;
//<Link to={`/template/${template.id}/0`} >{template.name}</Link>
