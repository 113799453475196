import React from "react";
import Card from './Card';
import { Link } from "react-router-dom";


export function SearchResultPartCard(props: any) {
  var buildlink = '/build/' + props.data.id + '/0'
  return(
      <Card title='Part' buildLink={buildlink}>
        <div className="contentTitle">
          <p className="contentLabel">Part ID:</p>
          <h3>
		        <a href={buildlink} target='_blank'>
              {props.data._source.part_id}
            </a>
          </h3>
          <h4>
          </h4>
        </div>

        <div className="inner-content-wrapper">
          <p className="contentLabel">X Location:</p>
          <h4>{props.data._source.x_location}</h4>
          <p className="contentLabel">Y Location:</p>
          <h4>{props.data._source.y_location}</h4>
          <p className="contentLabel">Z Location:</p>
          <h4>  {props.data._source.z_location}</h4>
        </div>

      </Card>

  )}


export default SearchResultPartCard;
