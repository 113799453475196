import React from 'react';
import useTraceAPI from '../hooks/useTraceAPI';

/**
 * Higher-Order Component (HOC) that provides the fetched templates to the wrapped component.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped.
 * @returns {React.Component} - The enhanced component with templates as a prop.
 */
const fetchTemplates = WrappedComponent => {

  return props => {
    // Get API functions from the useTraceAPI hook
    const { getTemplates } = useTraceAPI();

    // State to hold the fetched templates
    const [templates, setTemplates] = React.useState([]);

    // State to hold error flag & message if fetch fails
    const [templatesError, setTemplatesError] = React.useState([]);
    const [templatesErrorMessage, setTemplatesErrorMessage] = React.useState([]);

    // Fetch templates once after the component mounts
    React.useEffect(() => {
      getTemplates().then(fetchedTemplates => {
        if (fetchedTemplates.success) {
          setTemplates(fetchedTemplates.data);
          setTemplatesError(false);
          setTemplatesErrorMessage('');
        } else {
          setTemplatesError(true);
          setTemplatesErrorMessage(fetchedTemplates.message);
          setTemplates([]);
        }
      });
    }, []); // The empty dependency array ensures this effect runs only once

    // Render the wrapped component with fetched templates as a prop
    return <WrappedComponent {...props} templates={templates} templatesError={templatesError} templatesErrorMessage={templatesErrorMessage} />;
  };
};

export default fetchTemplates;

