import React from "react";
import { backUrl, projectIdentifier} from "../../App/constants";
import MainHeader from "../../common/nav/MainHeader";
import { Link } from "react-router-dom";
import { RemoveIcon } from '../../common/Icons';
import BasicTable from '../../common/basic-elements/BasicTable';
import SubNav from '../../common/nav/SubNav.jsx';
import SearchBox from '../search/SearchBox';
import '../search/styles/SearchPage.css';

var iconStyle = {
        height: '1em',
        marginLeft: '.4em',
        marginRight: '.3em',
        cursor: 'pointer'
};

var selectedCell = {
	background: 'lightsteelblue'
};

const SortCell = props =>
        <td style={ props.sortStatus.type == props.type ? selectedCell : null} onClick= { props.onClick }>
                <div style={{cursor: 'pointer'}}>
                {props.title}

{props.sortStatus.type != props.type && <i  className = "fa fa-sort"
            style={iconStyle}>
        </i> }
{props.sortStatus.type == [props.type] && props.sortStatus.sort =='a'  && <i className="fa fa-caret-up" style={iconStyle}i></i> }
{props.sortStatus.type == [props.type] && props.sortStatus.sort =='d'  && <i className="fa fa-caret-down" style={iconStyle}i></i> }

{false &&   <i  className = "fa fa-filter"
            style={{height:'1em'}}>
        </i> }
</div>
        </td>


const FilterButton = props =>
        <button style={{width: '80px', marginBottom: '1em'}}onClick={ props.onClick }>
                add filter
        </button>

const FilterTypeSelect = props =>
  <div style={{border: '1px solid red', position: 'absolute', zIndex: 400, height: '200px', width: '600px', background: 'white'}}>
                Property to Filter:
                { props.filters.map(filter =>
                <div>
                        {filter}
                </div>)}
  </div>

const FilterOptionsSelect = props =>
        <div>
                <div id="filter-options">
                </div>
                <div>
                Apply
                </div>
                </div>

const FilterPopUp = props =>
  <div style={{border: '1px solid red', position: 'absolute', zIndex: 400, height: '200px', width: '600px', background: 'white'}}>
                Filterpopup
                {props.show == 'type' && <FilterTypeSelect filters={props.filters}/>}
                {props.show == 'option' && <FilterOptionsSelect options={props.options} /> }

  </div>

export default class Show extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
      active: [],
      //token: JSON.parse(localStorage["appState"]).user.auth_token,
      sortStatus: {type: null, sort: null},
      filterShown: false,
      projects: []
		}
	}

  exportXLSX = () => {
    var data = {
      "Project Title": 'proc_title',
      "Feedstock": 'feedstock',
      'Batch Number': 'batch_num',
      'Machine ID': 'mach_id',
      'Machine Model': 'model' };
    var headers = Object.keys(data);
    var rows = this.state.data.map( project => {
      let values = Object.values(data);
      return values.map( value => project[value]);
    });

    var sheet = [
      headers,
      ...rows
    ];
    var fileName = `trace_${this.props.type}_projects`;

    this.props.xlsx(sheet, fileName);
  }

  launchFilterSelect() {
    this.setState({ filterShown: 'type'});
  }

  toggleSort(z) {
     // check the sortStatus state var && see what is up.
     // else
     var {sortStatus} = this.state;
     if ( sortStatus.type == z ) {
             if ( sortStatus.sort == 'a' ) {
     var sortStatus = {
             type: z,
             sort: 'd'
     };

          var cool = this.state.projects.sort((a,b) => {
          var ok;
                  var okok;
                  a[z] ? ok = '' + a[z] : ok = ''
                  b[z] ? okok = ''+ b[z] : okok = ''
          return okok.localeCompare(ok)
          });
          this.setState({projects: cool, sortStatus: sortStatus});

             } else {
     var sortStatus = {
             type: z,
             sort: 'a'
     };

          var cool = this.state.projects.sort((a,b) => {
          var ok;
                  var okok;
                  a[z] ? ok = ''+a[z] : ok = ''
                  b[z] ? okok = ''+b[z] : okok = ''
          return ok.localeCompare(okok)
          });
          this.setState({projects: cool, sortStatus: sortStatus});

             }
     } else {
     var sortStatus = {
             type: z,
             sort: 'a'
     };

          var cool = this.state.projects.sort((a,b) => {
          var ok;
                  var okok;
                  a[z] ? ok = ''+a[z] : ok = ''
                  b[z] ? okok = ''+b[z] : okok = ''
          return ok.localeCompare(okok)
          });
          this.setState({projects: cool, sortStatus: sortStatus});
     }
  }

	reallyDelete(id) {
    if (window.confirm('Are you sure you want to delete this build')) {
      fetch(backUrl + 'builds/' + id, {
        method: 'DELETE',
      headers: {
       "Authorization": 'Bearer ' + localStorage.getItem('token')
      }
      })
        .then(r => {
          const newProjects = this.state.projects.filter(p =>
            p.id !== id
          );
          this.setState({projects: newProjects});
        })
        .catch(e => console.error(e));
    } else {
      return;
    }
	}


	componentDidMount() {
    this.getProjects();
	}

  getProjects() {

    fetch(backUrl + 'builds', { 
        method: 'GET',
        headers: {
          "Authorization": 'Bearer ' + localStorage.getItem('token')
        }
      })
      .then((a) => a.json())
      .then(b => {
        //add shortcut for nested property name so sortable table cell functions
        for (let i = 0; i < b.length; i++) {
          if (b[i].project && b[i].project.name) {
            b[i].project_name = b[i].project.name;
          }
        }
        if (this.props.type2){
	  var filtered;
	  if (this.props.project){
             filtered = b.filter((build) => build.project_id === this.props.project.id)
	  } else {
	     filtered = b.filter((build) => build.project_id === this.props.id)	  
	  }
          this.setState({projects: filtered})
        } else {
          this.setState({projects: b})

        }
      });
  }

	render() {

		let data = this.state.data;
    var show = this.props.type === 'active' ? this.state.active : this.state.data
		let header = "Complete Builds";
		var link = "/complete_build/";
		if (this.props.type === "active") {
			header = "Active Builds";
			link = "/build/";
		}
		let crumb = ["Home", "Builds", header];

			return(

				<div style={{display: 'flex', flexDirection: 'column', padding: '0 10px'}}>
          {!this.props.type2 &&
            <div className="searchTopBarWrapper">
              <SubNav section='projects' current={this.props.type === 'active' ? 'active-builds' : 'complete-builds'}/>
              <SearchBox />
            </div>
          }
          { false && <FilterButton onClick={() => this.launchFilterSelect()}/> }
          { this.state.filterShown && <FilterPopUp show={this.state.filterShown} filters={['Feedstock', 'Machine Model']} options={[1,2,3]}/> }
						<BasicTable>
						<thead>
          <SortCell title="Trace ID" type={'id'} sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('id')}></SortCell>
          { false && <td>Feedstock</td> }
          <SortCell title="Project" type="project_name" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('project_name')}></SortCell>
          <SortCell title="Build Title" type="build_title" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('build_id')}></SortCell>
          <SortCell title="Material Name" type="material_name" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('feedstock_base_material')}></SortCell>
          <SortCell title="Process Type" type="process_type" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('process_type')}></SortCell>
          <SortCell title="Machine Manufacturer" type="machine_manufacturer" sortStatus = {this.state.sortStatus} onClick={() => this.toggleSort('process_machine_manufacturer')}></SortCell>
					<td></td>
				</thead>
				<tbody>
					{
           this.props.type === 'active' &&
           this.state.projects.map((datum, pos) => !datum.is_complete ?
						<tr>
							<td>
								<Link to={`/build/${datum.id}/0`} >{datum.id}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.project && datum.project.name}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.build_title}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.material_name}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.process_type}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.machine_manufacturer}</Link>
							</td>

              <td style={{paddingRight: '10px'}}>
                <RemoveIcon onClick={ id => this.reallyDelete(datum.id)}/>
              </td>
						</tr>
           : ''

          )}

					{
           this.props.type == 'complete' &&
           this.state.projects.map((datum, pos) => datum.is_complete ?
						<tr>
							<td>
								<Link to={`/build/${datum.id}/0`} >{datum.id}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.project && datum.project.name}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.build_id}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.feedstock_base_material}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.process_type}</Link>
							</td>

							<td>
								<Link to={`/build/${datum.id}/0`} >{datum && datum.process_machine_manufacturer}</Link>
							</td>

              <td style={{paddingRight: '10px'}}>
                <RemoveIcon onClick={ id => this.reallyDelete(datum.id)}/>
              </td>
						</tr>
           : ''
          )}

				</tbody>
					</BasicTable>
				</div>
		);
	}
}
// <MainHeader header={header} crumb={crumb}><button onClick={this.exportXLSX}><i style={{fontSize: '1.4em'}}className='fa fa-file-excel-o' /> export list</button></MainHeader>
