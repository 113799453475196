import React from 'react';
import useTraceAPI from '../hooks/useTraceAPI';

/**
 * Higher-Order Component (HOC) that provides the fetched project to the wrapped component.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped.
 * @returns {React.Component} - The enhanced component with project as a prop.
 */
const fetchProject = (WrappedComponent, projectId) => {

  return props => {
    // Get API functions from the useTraceAPI hook
    const { getProject } = useTraceAPI();


    // State to hold the fetched actions
    const [project, setProject] = React.useState([]);

    // State to hold error flag & message if fetch fails
    const [projectError, setProjectError] = React.useState([]);
    const [projectErrorMessage, setProjectErrorMessage] = React.useState([]);

    // Fetch project once after the component mounts
    React.useEffect(() => {
      getProject(projectId).then(fetchedProject => {
        if (fetchedProject.success) { 
          setProject(fetchedProject.data);
          setProjectError(false);
          setProjectErrorMessage('');
        } else {
          setProjectError(true);
          setProjectErrorMessage(fetchedProject.message);
          setProject([]); // return empty array so downstream components don't choke and die
        }
      });
    }, []); // The empty dependency array ensures this effect runs only once

    // Render the wrapped component with fetched project as a prop
    return <WrappedComponent {...props} project={project} projectError={projectError} projectErrorMessage={projectErrorMessage} />;
  };
};

export default fetchProject;


