import React from 'react';
import useTraceAPI from '../hooks/useTraceAPI';

/**
 * Higher-Order Component (HOC) that provides the fetched template to the wrapped component.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped.
 * @returns {React.Component} - The enhanced component with template as a prop.
 */
const fetchTemplate = (WrappedComponent, templateId) => {

  return props => {
    // Get API functions from the useTraceAPI hook
    const { getTemplate } = useTraceAPI();


    // State to hold the fetched actions
    const [template, setTemplate] = React.useState([]);

    // State to hold error flag & message if fetch fails
    const [templateError, setTemplateError] = React.useState([]);
    const [templateErrorMessage, setTemplateErrorMessage] = React.useState([]);

    // Fetch template once after the component mounts
    React.useEffect(() => {
      getTemplate(templateId).then(fetchedTemplate => {
        if (fetchedTemplate.success) { 
          setTemplate(fetchedTemplate.data);
          setTemplateError(false);
          setTemplateErrorMessage('');
        } else {
          setTemplateError(true);
          setTemplateErrorMessage(fetchedTemplate.message);
          setTemplate([]); // return empty array so downstream components don't choke and die
        }
      });
    }, []); // The empty dependency array ensures this effect runs only once

    // Render the wrapped component with fetched template as a prop
    return <WrappedComponent {...props} template={template} templateError={templateError} templateErrorMessage={templateErrorMessage} />;
  };
};

export default fetchTemplate;


