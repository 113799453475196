// constant location for schema across all deployments
//import schemaa from '../modules/docs/reni.json';//'./docs/current.json';
import schemaa from '../modules/docs/templateSchema.json'
export const schema = schemaa;
import test_info_json from '../assets/json/test_info.json'
export const test_info = test_info_json;
import a from './args.js';


// base url of the VM, add 8k for back, 9k for docs
// -- uncomment for localhost development --
//export const appUrl = 'http://localhost';
//export const apiUrl = 'http://localhost';

export const appUrl = a.appUrl
export const apiUrl = a.apiUrl
export const lastcommit = a.commit
//
// -- uncomment for old VM/EC2 --
//export const appUrl = 'https://18.222.252.208';
//export const apiUrl = 'https://18.222.252.208';
//
// -- uncomment for production --
// export const appUrl = 'https://americamakes.traceam.com';
// export const apiUrl = 'https://americamakes.traceam.com';
//
//  -- uncomment for test on new VM/EC2 --
// export const appUrl = 'https://18.220.254.252';
// export const apiUrl = 'https://18.220.254.252';
//
// --uncomment for test/dev scenarios --
//export const appPort = ':3001';
//export const apiPort = ':3000';
//
// --uncomment for production --
export const appPort = a.appPort;
export const apiPort = a.apiPort;

export const backUrl = apiUrl + apiPort + '/';
export const docUrl  = appUrl + appPort + '/';
export const homeUrl = appUrl + appPort + '/';

// different deployments have different fields that are used to id a project
export const projectIdentifier = "proc_title";
export const sentryDSN = "";
